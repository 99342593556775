:root {
  --color-primary: #f96332;
  --color-secondary: #708295;
  --color-secondary-dark: #333333;
  --color-danger: #f2545b;
  --color-warning: #ffb545;
  --color-success-rgb: 137, 181, 26;
  --color-success: #89b51a;
  --color-info: #00639f;
  --color-orange: #f96332;
  --color-yellow: #ffb545;
  --color-warning: #ffb545;
  --color-green: #89b51a;
  --color-green-dark: #517820;
  --color-blue: #00639f;
  --color-blue-light: #4891ff;
  --color-gray-light: #ecf2f8;
  --color-gray-lighter: #f9fbfe;
  --color-gray-dark: #708295;
  --color-gray: #d0dae4;
  --color-red: #f2545b;
  --color-danger: #f2545b;
  --color-white: #fff;
}
