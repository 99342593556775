@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import "font-awesome/scss/font-awesome.scss";
@import "../node_modules/placeholder-loading/src/scss/placeholder-loading";

html {
  min-height: 100%;
  margin: 0;
}
body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  /* background: linear-gradient(180deg, #fefefe 0%, #ebedf0 100%); */
  background: #f4f5f6;
  box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.15);
  word-break: break-word;
}
* {
  font-family: "Montserrat", sans-serif;
}
.window-width {
  display: none;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

*:focus {
  outline: 0 !important;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:hover {
  /* -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out; */
}

.form-group input:focus {
  background: #f1f1f1;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
a:focus {
  outline: 0;
  text-decoration: none;
}
a:hover {
  color: #f96332 !important;
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
table a {
  color: #333;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  margin: 0 !important;
}
.lock-scroll {
  /* position: fixed;
  width: 100%;
  overflow-y: scroll; */
  overflow: hidden;
  position: relative;
  height: 100%;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-12 {
  font-size: 12px;
}

.fl-r {
  float: right;
}
.fl-l {
  float: left;
}
.ta-r {
  text-align: right !important;
}
.ta-l {
  text-align: left !important;
}

.l-h-1-5 {
  line-height: 1.5;
}
.p-0 {
  padding: 0 !important;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-24 {
  margin-top: 24px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-10-0 {
  margin: 10px 0px;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-b--30 {
  margin-bottom: -30px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-l-10 {
  margin-left: 10px;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-20 {
  padding: 20px !important;
}
.px-50 {
  padding: 0 50px !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-t-5 {
  margin-top: 5px;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-45 {
  margin-right: 45px;
}
.block-span {
  display: block;
}
.inline-block {
  display: inline-block;
}
.block-span-dashboard {
  display: block;
  line-height: 1.4;
  position: relative;
  top: 15px;
}
.l-h-no {
  line-height: unset !important;
}
.no-bord {
  border: none !important;
}
.clear {
  clear: both;
}
.cookie-consent-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 15px;
  text-align: center;
  background: #f96332;
}
.cookie-consent-text {
  color: #fff;
  font-weight: 500;
}
.cookie-consent-text span {
  cursor: pointer;
  text-decoration: underline;
}
.cookie-consent-text span:hover {
  color: #333;
}
.cookie-consent-btn {
  background: #f4f5f6;
  color: #333;
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none !important;
  margin: 0 10px;
}
.cookie-consent-btn:hover {
  background: #dedede;
}
.hide-helper {
  display: none;
}
.hide-elem {
  visibility: hidden;
}
.not-founnd-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}
.not-founnd-content {
  font-size: 16px;
}
.not-found-btn .btn {
  margin-right: 20px;
}
.not-found-btn {
  margin: 15px 0;
}
.row-btn-m-t {
  margin: 30px 0 0 0;
}
.red {
  color: red;
}

.gray {
  color: #708295;
}
.pointer {
  cursor: pointer;
}
.static-page-wrapper section {
  margin-bottom: 75px !important;
}
.static-page-wrapper a {
  color: #f96332;
}
.static-page-wrapper a:hover {
  text-decoration: underline;
}
.static-page-article {
  font-size: 15px;
  line-height: 1.8;
}
/* .static-page-article:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
} */
.static-page-article .medium-title {
  margin-bottom: 25px;
}
.eu-funds-logo img {
  width: 130px;
}
.hp-slider-section,
.eu-funds-logo,
.eu-funds-disclaimer {
  margin-bottom: 50px !important;
}
.eu-funds-disclaimer {
  border-top: 2px solid #eaeaea;
  padding-top: 20px;
}
.dashed-border-top {
  border-top: 1px dashed #eaeaea;
  padding-top: 15px;
}
.gray-pointer {
  color: #708295;
  cursor: pointer;
}
.gray-pointer a {
  color: #708295;
}
.light-gray-bg {
  background: #cacaca;
}
.super-light-green-bg {
  background: #e3e7c7;
}
.pointer-orange-hover {
  cursor: pointer;
}
.pointer-orange-hover:hover {
  color: #f96332 !important;
}
.gray-sm {
  color: #708295 !important;
  font-size: 12px;
}
.dark-gray {
  color: #6d6c6a !important;
}
.dark-red {
  color: #f2545b !important;
}
.yellow-bg-gradient {
  background: rgb(253, 204, 93) !important;
  background: linear-gradient(
    50deg,
    rgba(253, 204, 93, 1) 0%,
    rgba(252, 178, 83, 1) 80%
  ) !important;
}
.orange-bg-gradient {
  background: rgb(252, 139, 86) !important;
  background: linear-gradient(
    50deg,
    rgba(252, 139, 86, 1) 0%,
    rgba(250, 106, 56, 1) 80%
  ) !important;
}
.white {
  color: #fff !important;
}
.dark-green {
  color: #517820 !important;
}

.light-green {
  color: #89b51b !important;
}

.yellow {
  color: #fab001 !important;
}

.center-helper {
  text-align: center;
}
.center-m {
  margin: 0 auto;
}
.no-pointer-events {
  pointer-events: none;
}
.orange-bg {
  border: 1px solid #f96332 !important;
  background: #f96332 !important;
  color: #fff !important;
  font-weight: 500;
}
.red-bg {
  background: #f2545b;
  color: #fff !important;
  font-weight: 500;
}
.white-bg-border {
  box-shadow: inset 0px 0px 0px 1px #f96332;
  color: #f96332 !important;
  font-weight: 500;
  background: #fff !important;
}
.white-bg-border:hover {
  background: #f96332 !important;
  color: #fff !important;
  box-shadow: inset 0px 0px 0px 1px #f96332;
}
._orange-bg {
  background: #f96332 !important;
  color: #fff !important;
  font-weight: 500;
}
.orange-bg:hover {
  color: #f96332 !important;
  background: #ffffff !important;
  border: 1px solid #f96332 !important;
}
.dark-orange-bg {
  background: #db5527;
  color: #fff !important;
  font-weight: 500;
}
.orange {
  color: #f96332 !important;
}
.dark-orange {
  color: #db5527 !important;
}
.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.btn {
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  line-height: 1.5;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.btn-100 {
  width: 100% !important;
}
.btn-small {
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
}
.btn-big {
  text-align: center;
  padding: 14px 28px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
button.btn-big {
  border: none;
  box-shadow: none;
}
.transparent-bg {
  background: #fff;
  color: #708295;
  font-weight: 500;
}
.transparent-bg:hover {
  color: #f96332;
  text-decoration: underline;
}
.border-btn {
  color: #fff;
  border: 1px solid #fff;
  padding: 9px 19px;
}
.border-btn-black {
  border: 1px solid #333;
  padding: 5px 9px;
}
.global-mobility-border-btn {
  color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  padding: 9px 19px;
}
.homepage-wrapper .border-btn a {
  color: #fff;
}
.border-btn:hover {
  background: #f96332;
  border: 1px solid #f96332;
}
.global-mobility-border-btn:hover {
  background: #f96332;
  border: 1px solid #f96332;
}
.doc-created-at {
  font-size: 10px;
}

.clear-btn {
  padding: 12px;
}
.link-btn {
  color: #708295;
  text-decoration: underline;
  background: no-repeat;
  border: none;
  cursor: pointer;
  display: inline-block;
}
.link-btn:hover {
  color: #f96332;
}

.light-green-bg {
  background: #89b51a !important;
  color: #fff;
  font-weight: 500;
}

.light-green {
  color: #89b51a;
}

.dark-green-bg {
  background: #517820 !important;
  color: #fff;
  font-weight: 500;
}
.yellow-bg {
  background: #d0dae4;
  color: #fff;
  font-weight: 500;
}
.gray-bg {
  background: #708295 !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.gray-bg:hover {
  background: #7d7d7d;
}
.transparent-bg {
  background: #fff;
  color: #708295;
  font-weight: 500;
}
.white-bg {
  background: #fff;
  color: #333;
  font-weight: 500;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.transparent-bg-border {
  background: transparent;
  color: #333;
  font-weight: 500;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #f96332;
}
.white-bg:hover {
  background: #dedede;
}
.transparent-bg-border:hover {
  background: #f96332;
  color: #fff;
}
.fw-500 {
  font-weight: 500;
}

.p-t-0 {
  padding-top: 0 !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.hp-provider-logo img {
  width: 170px;
  margin-bottom: 30px;
  margin-top: 10px;
  cursor: pointer;
  height: 140px;
  object-fit: contain;
}
.hp-provider-logo img:hover {
  transform: scale(1.15);
}
.eu-logo {
  width: 208px !important;
  margin-left: 15px;
}
.auth .eu-logo {
  width: 75px !important;
}
.hp-provider-logo {
  background: #fff;
  padding: 50px 0;
}
.no-rate {
  color: #f96332;
  margin: 0 12px;
}

.rate-action {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.rate-action .selectable {
  margin: 10px 0px;
}
.white-box {
  background: #fff;
  color: #333;
  padding: 25px 30px;
  display: inline-block;
  width: 100%;
  position: relative;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.white-box .big-p {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
.action-link-orange {
  color: #f96332;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-orange-sm {
  font-size: 12px;
  color: #f96332;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-orange:hover,
.action-link-orange-sm:hover {
  color: #db5527;
  text-decoration: underline;
}
.action-link-gray {
  color: #708295;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-white {
  color: #fff;
  text-decoration: underline;
}
.action-link-white:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-gray:hover {
  color: #f96332;
  text-decoration: underline;
}
.action-link-gray:hover {
  color: #f96332;
  text-decoration: underline;
}
.medium-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}
.small-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}
.inner-label {
  color: #708295;
  margin-right: 5px;
}

.fake-cards-section {
  margin: 0 -15px;
}
.fake-card-box {
  background: #fff;
  height: 350px;
  margin: 0 0 30px 0;
  border-radius: 4px;
}

.fake-card-wrapper {
  float: left;
  padding: 0 15px;
  width: 370px;
}

.fake-box-wrapper {
  background: #fff;
  min-height: 400px;
  height: 100%;
  z-index: 99;
}

.wrapper-content {
  min-height: calc(100vh - 550px);
}
.loading-mask:before {
  content: "";
  position: absolute;
  background: #fff;
  width: 100%;
  min-height: 400px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}
.order-details-box-left-body div {
  line-height: 30px;
}
.editable-input {
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 10px;
}
.info-row-editable .editable-input {
  padding: 5px;
  text-align: center;
  height: 30px;
}
.editable-input:disabled {
  background: #fff;
  color: #708295;
}
.edit-no-input {
  width: 50px;
}
.edit-calendar-input {
  width: 90px;
}
.editable-input-action i {
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #708295;
  margin-left: 10px;
}
.editable-input-action i:hover {
  color: #f96332;
}

.mat-button-wrapper svg {
  width: 18px !important;
  color: #708295;
}
.editable-group {
  position: relative;
}
.editable-group .editable-input-action {
  position: absolute;
  top: 0;
  right: 15px;
  border-left: 1px dashed #708295;
  height: 100%;
}
.editable-group .editable-input-action i {
  position: absolute;
  top: 50%;
  transform: translate(5px, -50%);
}
.no-padding {
  padding: 0 !important;
}
.no-padding .white-box-inner-header {
  padding: 20px 30px;
}
.no-padding .white-box-inner-body {
  padding: 15px 30px;
}
.editable-group-row {
  margin-bottom: 10px;
}
.editable-group .editable-group-row:last-child {
  margin-bottom: 0;
}
.last-elem {
  margin-bottom: 0 !important;
}
.advanced-search-input input {
  background: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  height: 40px;
  line-height: 45px;
  transition: all 0.3s ease-out;
}
.gray-search-bg .advanced-search-input input {
  background: #f0f0f0;
  box-shadow: none;
}
.advanced-search-input .icon-inside-input {
  margin-right: 15px;
}
.advance-search-row-blog .icon-inside-input {
  width: 100%;
}
.advance-search-row-blog .icon-inside-input .icon-search-blog {
  right: 0;
  left: unset;
  cursor: pointer;
  background: #f96332;
  color: #fff;
  height: 40px;
  top: 0;
  line-height: 45px;
  font-size: 24px;
  padding: 0 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.advance-search-row-blog .icon-inside-input .icon-search-blog-cancel {
  position: relative;
  top: unset;
  left: 0;
  color: #708295;
}
.advance-search-row-blog .icon-inside-input .icon-search-blog:hover {
  background: #db5527;
}
.advance-search-row-blog .icon-search-blog-cancel {
  color: #f96332;
}
.advance-search-row-blog .advanced-search-input input {
  padding: 10px 75px 10px 15px;
  width: 100%;
}
.advance-search-row-blog .clear-search {
  background: transparent;
  right: 55px;
  width: auto;
}
.icon-inside-input {
  display: inline-block;
  position: relative;
}
.icon-inside-input i {
  position: absolute;
  top: 10px;
  font-size: 18px;
  color: #708295;
  right: 10px;
}
.advanced-search-wrapper {
  position: relative;
}
.advance-search-row {
  display: inline-table;
  margin: 0 15px;
  min-width: 175px;
  padding: 15px 0 0;
}
.calendar-clear-inline {
  line-height: 45px;
  height: 45px;
}
.advance-search-row-blog {
  margin-bottom: 15px;
}

.advance-search-row .calendar-input-control {
  background: #f0f0f0 !important;
}
.advance-search-row label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.elem-page-wrapper {
  margin-left: 15px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background: #708295;
}
.select-coutry-banner {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
  font-size: 16px;
}
.country-banner-selector ng-select.location-select {
  margin: 15px auto 0;
  text-align: left;
}
.country-banner-selector ng-select.location-select:before {
  top: 13px;
  left: 7px;
  z-index: 1;
}
.marketplace-rfq-card {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin: 0 auto 15px;
  font-size: 16px;
}

.filter-content-wrapper ng-select.location-select {
  width: auto;
  text-align: left;
}

.filter-content-wrapper ng-select.location-select:before {
  top: 15px;
  left: 11px;
  z-index: 1;
}
.iframe-category-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
  min-height: 1000px;
}
.iframe-category-wrapper {
  background: #fff;
  padding: 20px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  position: relative;
}

.iframe-category-wrapper .subcategory {
  width: calc(25% - 50px);
  padding: 0;
  border: 1px solid #708295;
  border-radius: 4px;
  float: left;
  margin: 25px;
  text-align: center;
  overflow: hidden;
  color: #333;
}

.subcategory-link-item span {
  z-index: 2;
}

.iframe-category-wrapper .subcategory:hover .subcategory-link-item {
  color: #fff;
}

.iframe-category-wrapper .subcategory:hover .subcategory-link-item:after {
  background: #f96332;
  color: #fff;
  content: "";
  position: absolute;
  height: 999px;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.subcategory-img {
  width: 100%;
  padding: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 28px;
  border-bottom: 1px dashed #708295;
}

.subcategory-link-item {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  min-height: 65px;
  padding: 15px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .subcategory-link-item:hover {
  color: #f96332;
} */

.subcategory-link-item i.icon-record-outline:before {
  font-size: 22px;
  transform: translate(0, -50%);
}
.subcategory-link-item i.icon-immigration:before {
  font-size: 20px;
}
.subcategory-link-item i.icon-area-orientation:before {
  font-size: 22px;
}
.subcategory-link-item i.icon-house-search:before {
  font-size: 21px;
}
.subcategory-link-item i.icon-school-search:before {
  font-size: 20px;
}
.subcategory-link-item i.icon-settling-in:before {
  font-size: 22px;
}
.subcategory-link-item i.icon-credit-card:before {
  font-size: 22px;
}
.subcategory-link-item i.icon-moving-services:before {
  font-size: 23px;
}
.subcategory-link-item i.icon-eu-forms:before {
  font-size: 18px;
}
.subcategory-link-item i.icon-moving:before {
  font-size: 18px;
}
.subcategory-link-item i:before {
  position: absolute;
  width: 30px;
  font-size: 24px;
  line-height: 18px;
  margin: 0;
  top: 50%;
  -webkit-transform: translate(0, -60%);
  transform: translate(0, -60%);
}
.search-suggestions-wrapper {
  background: #fff;
  position: absolute;
  width: calc(100% - 4px);
  top: 40px;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}
.search-suggestions-title {
  color: #708295;
  font-weight: 500;
  line-height: 50px;
}
.search-suggestions-row {
  border-top: 1px dashed #eaeaea;
  cursor: pointer;
  padding: 10px 15px;
  cursor: pointer;
}
.search-suggestions-row:hover {
  background: #f4f5f6;
}

.search-suggestions-text b {
  color: #f96332;
  font-weight: 500;
}
.search-suggestions-categ {
  color: #708295;
}
.search-suggestions-no-item {
  padding: 0 15px 15px;
  color: #f96332;
  font-weight: 500;
}
.search-suggestions-header {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: 0 15px;
}
.close-search-suggestions {
  padding: 5px 10px;
  border-radius: 4px;
  margin: 12px 0;
  cursor: pointer;
}

/* Ads */
.ad-height-helper {
  min-height: 1px;
  background: transparent;
}
.ad-wrapper {
  position: relative;
  cursor: pointer;
}
.ad-wrapper a {
  display: block;
}
.ad-top-provider img {
  height: 300px;
  width: 1460px;
  object-fit: cover;
  transform-origin: top left;
}
.ad-main-category img {
  height: 240px;
  width: 1140px;
  object-fit: cover;
  transform-origin: top left;
}
.ad-offers-category img {
  height: 200px;
  width: 1140px;
  object-fit: cover;
  transform-origin: top left;
}
.ad-offers-filter img {
  height: 600px;
  width: 250px;
  object-fit: cover;
  transform-origin: top left;
}
.ad-offer-page img {
  width: 320px;
  height: 300px;
  object-fit: cover;
  transform-origin: top left;
}
.ad-offers-filter .ad-btn,
.ad-offer-page .ad-btn {
  left: 0;
  right: 0;
  margin: 0 30px;
}
.btn a {
  color: #fff;
}
.ad-btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.ad-banner-slider {
  top: unset !important;
  bottom: 50px !important;
  right: 30px !important;
  left: unset !important;
  width: auto !important;
  transform: unset !important;
}
.ad-margin {
  margin: 30px 0;
}
/* end Ads */

/* custom tree list */
.lvl-1,
.lvl-2,
.lvl-3 {
  padding-left: 30px;
}
.lvl-0 > .tree-list-name {
  font-size: 16px;
  font-weight: 500;
}
.tree-list-row-unique > *,
.editable-input-wrapper > * {
  display: inline-block;
}
.tree-list-row-unique .tree-list-checkbox label {
  margin: 0;
  padding-right: 10px;
}
.tree-list-row-unique {
  padding: 20px 0;
}
.tree-list-row.lvl-0 {
  border-bottom: 2px solid #f0f0f0;
}
.tree-list-wrapper {
  margin-bottom: 40px;
}

.tree-row-details {
  float: left;
}
.tree-row-wrapper {
  padding-left: 20px;
  display: inline-block;
  width: 100%;
  position: relative;
  font-size: 0;
}

.sub-is-open ~ .toggle-tree {
  display: block;
}
.toggle-tree {
  display: none;
}
.toggle-row-action.sub-is-open i:before {
  content: "\e819" !important;
}
.tree-link:hover {
  text-decoration: underline;
}
/* #tree-row-extrawrapper > .tree-row-wrapper:first-child {
  border-top: none;
} */
.tree-row-wrapper .mat-checkbox-layout {
  margin-bottom: 0;
}
.toggle-row-action {
  position: absolute;
  left: -5px;
  top: 12px;
  cursor: pointer;
  font-size: 20px;
}
.toggle-row-action:hover {
  color: #f96332;
}
.tree-row {
  border-bottom: 1px solid #f0f0f0;
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  /* margin-left: 15px; */
}

mat-checkbox {
  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: #f96332 !important;
      border-color: #f96332 !important;
    }
  }

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    background-color: #f96332 !important;
    border-color: #f96332 !important;
  }
}

.treeRow-lvl1 .tree-check {
  display: none;
}
.tree-label {
  margin-left: 15px;
}

.float-none {
  float: none;
}

.tree-label span {
  margin-right: 5px;
  font-weight: 500;
}
.tree-row-details .edit-input {
  width: 40px;
  height: 40px;
  line-height: 24px;
  border: none;
  text-align: center;
  color: #f96332;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 7px;
}
.tree-row-details .edit-input:focus {
  background: #f0f0f0;
}
.tree-row-details .toggle-calendar-icon {
  height: auto;
  line-height: 56px;
  font-size: 16px;
  width: auto;
  margin-left: 5px;
}
.tree-row-date {
  color: #708295;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.tree-check .mat-checkbox-inner-container {
  width: 20px;
  height: 20px;
}
.tree-row .tree-label {
  width: calc(100% - 35px);
}
/* end custom tree list */

/*categories tree  */
/* .tree-check{
  font-size: 20px;
} */
.tree-check .mat-checkbox-layout {
  align-items: baseline !important;
  vertical-align: middle !important;
  display: inline-flex !important;
  white-space: nowrap !important;
  height: auto !important;
}
.category-tree-group {
  padding-left: 20px;
  display: inline-block;
  width: 100%;
  position: relative;
  font-size: 0;
}
.rfq-bid-modal .category-tree-group .toggle-row-action {
  top: 12px;
}
.category-tree-row {
  border-bottom: 1px solid #f0f0f0;
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  margin-left: 15px;
}
.tree-inner-btn {
  color: #f96332;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
  display: block;
  text-decoration: underline;
  margin-right: 10px;
  margin-bottom: 5px;
}
.tree-inner-btn:hover {
  color: #db5527;
}
.category-tree-row span {
  display: inline-block;
  margin: 5px 20px 5px 0;
}
.category-tree-group .toggle-row-action {
  position: absolute;
  left: -5px;
  top: 15px;
  cursor: pointer;
  font-size: 20px;
}
.tree-commissions-row {
  display: flex;
  width: 100%;
  border-top: 1px dashed #f0f0f0;
  padding: 10px 0;
}
.tree-commissions-action {
  margin: 0 10px;
}
.tree-commissions-row-wrapper .tree-commissions-row:first-child {
  border-top: none;
}
.tree-commissions-row div {
  margin-right: 10px;
}
.tree-commissions-action span {
  margin: 0px 15px 0 0;
  cursor: pointer;
  color: #708295;
}
.tree-commissions-action span:hover {
  color: #f96332;
}
/* .category-tree-group .toggle-row-action i:before {
  margin: 0;
  width: auto;
} */
.category-tree-row-periods {
  margin: 0 5px;
  /* border-bottom: 1px solid #F0F0F0; */
  line-height: 35px;
  /* margin-bottom: 10px; */
}

/* #readonly-periods{
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 10px;
} */

.category-tree-row-periods span {
  margin-right: 3px;
}

.category-tree-row-periods ul {
  list-style: none;
}
/*end categories tree  */

/* erc landing pages */
.erc-wrapper {
  margin-top: -5px;
}
.erc-header {
  background: #fff;
  padding: 10px 0;
  width: 100%;
  display: inline-block;
}

.erc-header .header-top-btn {
  margin-left: 15px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.banner-section-erc-provider {
  background: url("assets/images/erc/header1.jpg");
  background-size: cover;
  background-position: center 70%;
  /* height: 380px; */
  height: 450px;
}

.banner-section-erc-client {
  background: url("assets/images/erc/header2.jpg");
  background-size: cover;
  background-position: center 70%;
  /* height: 380px; */
  height: 450px;
}

.banner-section-erc-provider,
.banner-section-erc-client {
  position: relative;
}

.erc-banner-title {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 30px;
}

.erc-banner-description {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.erc-wrapper section {
  margin-bottom: 60px;
  clear: both;
  display: inline-block;
  width: 100%;
}
.erc-inner-img img {
  width: 100%;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}
.erc-wrapper .list-style {
  list-style: none;
  padding: 0;
  margin: 0;
}
.erc-wrapper .list-style li {
  position: relative;
  padding-left: 32px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 26px;
}
.erc-wrapper .list-style li:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #f96332;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0;
}
.erc-wrapper .list-style span {
  font-weight: 600;
}
.erc-cta-banner {
  background: url("assets/images/erc/banner.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  position: relative;
}
.erc-cta-banner-content {
  height: 400px;
  position: relative;
}
.erc-banner-description-absolute-left {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 50%;
  text-align: left;
}
.erc-wrapper form label {
  font-size: 16px;
}
.logo-footer {
  max-width: 290px;
}
.modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  mat-icon {
    margin-right: 5px;
    width: 20px;
    ::ng-deep path {
      stroke: #f96332;
    }
  }
}
.footer-subtabs {
  padding-left: 20px;
}
.footer-button {
  border-radius: 15px 15px 15px 15px;
  max-width: 90px;
  padding: 10px;
  background: #f96332;
  color: #fff;
  font-weight: 500;
  padding: 15px !important;
}
.footer-button-text {
  padding: 25px;
}
.footer-button:hover {
  background: green;
}
.footer-icons i {
  font-size: 20px;
  color: white;
  padding-left: 10px;
}
.footer-icons i:hover {
  color: #f96332;
}
.footer-icons {
  padding-top: 0px !important;
}
.footer-first-list {
  margin-left: 2%;
}
.footer-last-list {
  margin-left: 9%;
}
@media (width: 768px) {
  .footer-list {
    margin-right: 20px;
  }
}
@media (max-width: 768px) {
  .footer-list {
    margin-left: 20px;
  }
  .footer-bottom-title {
    padding-top: 10px;
  }
}
@media (min-width: 768px) {
  .footer-list {
    width: 20%;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .footer-list {
    width: 15%;
  }
}
@media (min-width: 990px) and (max-width: 1300px) {
  .footer-list {
    width: 18%;
  }
}
/* end erc landing pages */

/* RFQ */
.rfq-column-background {
  padding: 20px 0 20px 0 !important;
  background: gainsboro;
  display: flex !important;
  justify-content: center;
}

.rfq-column-title {
  /* color: #f96332; */
  font-weight: 700;
  font-size: 22px;
}

.rfq-offer-footer {
  margin-top: 15px;
}

.rfq-qty-column {
  width: 100px !important;
}

.rfq-qty-column input {
  width: 80px !important;
  margin-bottom: 0px;
}

.table-wrapper .rfq-table tbody tr td {
  border-bottom: 1px solid #f0f0f0;
  padding: 13px !important;
}

/* .criteria-label{
  margin-bottom: 25px !important;
} */

.service-search-txt {
  text-align: center;
  font-size: 16px;
  font-style: italic;
  top: 50%;
  align-items: center;
  justify-content: center;
}
.rfq-edit-actions-btn {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
}

.rfq-edit-actions-btn .btn-small,
.rfq-edit-actions-btn .btn {
  margin-left: 10px;
}

.rfq-edit-actions {
  display: flex;
  flex-direction: column;
}
.rfq-edit-actions .form-group.timepicker {
  margin: 0 0 10px 0;
  top: 0;
  position: relative;
  display: inline-block;
}
.rfq-edit-actions .m-t-calendar-icon {
  margin-top: 30px !important;
}
.ngb-tp-input-container .chevron.bottom::before {
  top: -15px !important;
}
.rfq-edit-actions div {
  margin: 0 !important;
}
/* .rfq-small-box-details {
  padding: 20px;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  flex-direction: column;
}
.rfq-small-box-details div {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
} */

#rfqDetailsSelect div {
  margin-bottom: 0 !important;
}

.rfq-edit-actions .form-group.timepicker .ngb-tp-hour,
.rfq-edit-actions .form-group.timepicker .ngb-tp-minute {
  margin: 0;
}

/* .rfq-details-subtitle{
  margin-bottom: 10px;
} */

.rfq-invite-modal {
  overflow-y: scroll;
  position: relative;
  height: 27rem;
}

.medium-title-rfq {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
}

.rfq-modal {
  height: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.table-wrapper .rfq-table table thead tr th {
  padding: 10px 4px;
}
.table-wrapper .rfq-table table thead th {
  font-size: 13px;
}

.rfq-details-row {
  display: inline-block;
  width: 100%;
  border-top: 1px dashed #f0f0f0;
  padding: 15px 0;
}
.rfq-details-row span {
  white-space: pre-line;
}

.rfq-details-row .table-wrapper {
  margin-bottom: 15px;
}
.rfq-details-row:first-child {
  padding: 0;
  border: none;
}
.rfq-details-row:last-child {
  padding-bottom: 0;
}
.rfq-qty {
  margin: 5px 0;
}

.rfq-row-subtitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

/* .rfq-create{
  margin-bottom: 0px;
  padding: 10px 0px;
  margin-top: 15px;
} */

/* .rfq-create-btn{
  padding: 10px 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
} */

.quotation-group {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 15px;
}

.quotation-row {
  display: flex;
  flex-direction: column;
  height: auto;
  line-height: 40px;
  padding-bottom: 15px;
}

.quotation-toggle-row {
  position: absolute;
  left: -20px;
  top: -3px;
  cursor: pointer;
}

.quotation-toggle-row i {
  font-size: 20px;
}

.quotation-toggle-row.sub-is-open i:before {
  content: "\e819" !important;
}

.quotation-toggle-row:hover {
  color: #f96332;
}

.quotation-name {
  padding: 0px 10px;
}

.quotation-table {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.quotation-actions {
  font-size: 22px;
  margin: 0px 5px;
}

.quotation-bulk .bulk-action-wrapper {
  padding: 8px 30px;
}

/* .rfq-modal-create{
  overflow-y: scroll;
  height: 30rem;
  overflow-x: hidden;
} */

.set-commission-btn {
  font-size: 13px;
}

.set-commission-btn .btn {
  margin: 0 15px;
  color: #f96332;
  font-weight: 500;
  cursor: pointer;
  font-size: 13px;
  padding: 0px;
}

.rfq-range-qty {
  background: #f0f0f0;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 35px !important;
  float: left;
  font-size: 14px;
  border-radius: 4px;
}

.rfq-range button {
  line-height: 35px !important;
}

label.mat-radio-label {
  display: flex !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f96332 !important;
}
.mat-radio-container {
  width: 16px !important;
  height: 16px !important;
}
.mat-radio-outer-circle {
  width: 16px !important;
  height: 16px !important;
  border-color: #708295 !important;
  border: 1px solid #708295 !important;
  border-radius: 3px !important;
}
.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(1) !important;
  border-radius: 3px !important;
}
.mat-radio-inner-circle {
  width: 16px !important;
  height: 16px !important;
  background: #f96332 !important;
}
.mat-checkbox-checked .mat-checkbox-checkmark {
  opacity: 0 !important;
}
.mat-checkbox-checked .mat-checkbox-background:before {
  content: "\e80d";
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  position: absolute;
  z-index: 1;
  color: #fff;
  width: 16px;
  height: 14px;
  line-height: 16px;
  text-align: center;
}
.mat-radio-checked .mat-radio-inner-circle:before {
  content: "\e80d";
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  position: absolute;
  z-index: 1;
  color: #fff;
  width: 16px;
  height: 14px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
}
.left-filter-radio .mat-radio-button {
  display: block;
  margin-bottom: 15px;
}
.left-filter-radio .mat-radio-button:last-child {
  margin-bottom: 0;
}

.left-filter-radio label {
  margin-bottom: 0;
}
.commission-type {
  margin-left: 12px;
  margin-right: 12px;
}

.quotation-total {
  font-size: 20px;
  font-weight: bold;
}

.quotation-total div:last-child {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.rfq-service-header {
  /* display: inline-block;
  width: 100%; */
}

.rfq-expats-qty {
  margin: 5px 0;
  display: inline-block;
}

.rfq-expats-qty .cart-item-details-range {
  margin: 0 15px;
}
.rfq-service-desc textarea {
  width: 123%;
  height: 6rem;
  line-height: 1.5;
}

.rfq-service-desc-readonly {
  margin-bottom: 15px;
  white-space: pre-line;
}

/* .extension-container{
  white-space: normal !important;
  display: flex;
  flex-direction: column;
}

.extension-container span{
  margin-bottom: 10px;
}

.extension-container span:first-child{
  color: #517820;
  font-weight: bold;
} */

.faded-checkbox {
  opacity: 0.6;
}

.rfq-calendar-wrapp .range-calendar-wrapper {
  left: 11rem;
}

/* .rgfq-underline:hover{
  text-decoration: underline;
} */

/* start cases */

.case-label-pending {
  background: #d0dae4;
  color: #333;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: auto;
}

.case-label-processing {
  background: #ffb545;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
}

.case-label-in_progress {
  background: #ffb545;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: auto;
}

.case-label-done {
  background: #89b51a;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: auto;
}
.case-label-completed {
  background: #89b51a;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: auto;
}
.case-label-inprogress {
  background: #ffb545;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: auto;
}
.case-label-overdue {
  background: #f2545b;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: auto;
}
.case-label-cancelled {
  background: #708295;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-transform: capitalize;
  width: auto;
}
.case-label {
  background: #ecf2f8;
  color: #70777d;
  border: 1px solid #d0dae4;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 400 !important;
  width: auto;
}
/* end cases */

/* custom checkbox */
input.checkbox-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 18px;
  background-color: #efefef;
  border: 2px solid #efefef;
  border-radius: 50px;
  cursor: pointer;
}
.spacer {
  flex: 1 1 auto;
}
input.checkbox-switch:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -3px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  background: #f96332;
}
input.checkbox-switch.disabled:after {
  background: #c1c4c9;
}
input.checkbox-switch:hover::after {
  background: #db5527;
}
input.checkbox-switch:checked {
  box-shadow: inset 20px 0 0 0 #fcb198;
  border-color: #fcb198;
  background: #fcb198;
}

input.checkbox-switch:checked:after {
  left: 26px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}
.switch-two-rpw-elem {
  display: inline-block;
  margin-right: 15px;
}
.switch-two-rpw-elem label {
  margin: 0;
  font-weight: 500;
  display: inline-block !important;
}
.switch-two-row {
  border-bottom: 1px dashed #efefef;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
/* end custom checkbox */

/* end RFQ */

.helper-description {
  position: absolute;
  right: 0;
  background: #fff;
  top: 20px;
  z-index: 99;
  padding: 15px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  min-width: 140px;
  display: none;
}

.helper-rfq {
  right: auto;
  top: 35px;
  min-width: 230px;
}
/*
.characteristic-left .helper-description {
  top: 40px;
} */
.characteristic-wrapper {
  display: block;
  padding-bottom: 25px;
}
.visible-helper .helper-description {
  display: block;
}
.input-helper {
  /* float: right; */
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}
.input-helper i:before,
.characteristic-helper i:before {
  padding: 0;
  margin: 0;
  color: #708295;
  font-size: 16px;
  line-height: 16px;
}
.input-helper:hover i:before,
.characteristic-helper:hover i:before {
  color: #f96332;
}
.characteristic-helper {
  cursor: pointer;
  float: right;
  position: relative;
  top: 11px;
}
.characteristic-left {
  float: left;
  width: 70%;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.characteristic-right {
  float: right;
  height: 65px;
  width: 30%;
}
.characteristic-right .checkbox-switch,
.characteristic-right .form-control {
  float: right;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.characteristic-right .form-control {
  height: 35px;
  line-height: 35px;
}
.characteristic-row {
  height: 65px;
}
.characteristic-row .white-box-body {
  padding: 0;
}
.form-group-row label {
  margin-bottom: 0;
  font-weight: 500;
  position: relative;
}

/* time picker */
.timepicker .btn {
  padding: 1px 20px;
  color: #f96332;
}

.timepicker .form-control {
  margin-bottom: 0px;
  padding: 5px 10px;
  padding: 5px 18px;
  text-align: left;
  text-indent: 4px;
}

.timepicker .form-control {
  margin-bottom: 0px;
}

.form-group.timepicker {
  display: inline-block;
  position: relative;
  top: -30px;
  margin: 0 15px;
}
.form-group.timepicker fieldset {
  padding: 0;
  margin: 0;
  width: auto;
}
.form-group.timepicker fieldset .form-control {
  position: relative;
  left: 7px;
}
.ngb-tp-input-container.ngb-tp-minute {
  margin-bottom: 0px;
}

.timepicker .btn-outline-primary {
  border-color: #f96332;
  border: 1px solid;
}
.rfq-edit-actions .form-group.timepicker .btn-outline-primary {
  margin-top: -10px;
}

.timepicker .btn-outline-primary:hover {
  color: #fff !important;
  background-color: #f96332;
  border-color: #f96332;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #f96332;
  border-color: #f96332;
}

/* end time picker */

/* header */
.admin-notif {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  height: 60px;
  margin-top: -10px;
}

.admin-return {
  margin-top: 5px;
}

.admin-return a {
  padding: 5px 13px;
  border-radius: 13px;
  color: white !important;
}

.header-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  background: #fff;
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.09);
  font-size: 0;
}
.header-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #efefef;
  top: 71px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.header-wrapper-no-bg {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 0;
}
.header-wrapper-no-bg .header-top {
  padding: 15px 45px;
  border-bottom: 1px solid #efefef;
}

.notification-title {
  font-size: 14px;
  font-weight: 800;
}

.notification-body {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.messaging-body {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-empty {
  padding: 15px;
  font-weight: 500;
  background: #fff;
}
.notification-count {
  position: absolute;
  top: -7px;
  right: 15px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-size: 11px;
  font-weight: 500;
}
.notification-item {
  position: relative;
  padding: 0 20px;
  cursor: pointer;
}
.notification-item i {
  color: #708295;
  font-size: 24px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.notification-item i:hover {
  color: #f96332;
}
.header-top {
  width: 100%;
  display: inline-block;
  /* border-bottom: 1px solid #EFEFEF; */
  padding: 10px 0;
  font-size: 14px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.logo,
.header-top-action-wrapper,
.header-top-user,
.header-top-action-wrapper-no-logged,
.eu-funds-img {
  height: 51px;
  display: flex;
  align-items: center;
}
.header-top-notifications,
.header-top-user-info,
.header-top-action-no-logged {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.logo img {
  width: 245px;
  position: relative;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  cursor: pointer;
  height: 65px;
  object-fit: contain;
}
.eu-funds-img img {
  object-fit: contain;
  width: 62px;
  position: relative;
  top: 50%;
  height: 55px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.15s ease-out;
  margin: 0 10px;
}
.header-top-user-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.header-top-user-info {
  text-align: right;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.header-top-user-img {
  margin-left: 15px;
  position: relative;
}
.header-top-menu {
  position: relative;
  color: #708295;
  font-size: 18px;
  cursor: pointer;
  margin-left: 5px;
}
.header-top-menu i:before {
  width: auto;
}
.header-top-menu:hover {
  color: #f96332;
}
.search-box,
.location-select {
  position: relative;
}
.clear-search {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, calc(-50% + 5px));
  cursor: pointer;
  color: #708295;
  width: 25px;
  background: #f0f0f0;
}
.clear-search i {
  font-size: 22px;
}
.clear-search:hover {
  color: #f96332;
}
.search-box input {
  padding: 0 15px 0 45px;
  color: #333;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  line-height: 44px;
  margin-bottom: -10px;
  height: 44px;
  width: 100%;
  height: 35px;
}
.search-box:before {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #708295;
  content: "\e813";
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  /* font-size: 120%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  z-index: 99;
}
ng-select.location-select:before {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #708295;
  content: "\e822";
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  /* font-size: 120%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  z-index: 2 !important;
}
.location-select.ng-select.ng-select-opened .ng-select-container {
  z-index: 1;
}
.header-bottom {
  display: inline-block;
  width: 100%;
  padding: 20px 0;
  background: #f96332;
  font-size: 14px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.header-bottom-action-item {
  margin-left: 15px;
}
.header-bottom-action-item .select-language-wrapper {
  display: none;
}
.main-nav-wrapper .breadcrumbs {
  height: 35px;
  line-height: 35px;
}
.main-nav-wrapper .breadcrumbs ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.main-nav-wrapper .breadcrumbs ul li {
  display: inline;
  color: #cacaca;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}
.main-nav-wrapper .breadcrumbs ul li:hover {
  color: #333;
}
.breadcrumbs-item {
  color: #708295;
  font-weight: 500;
  padding-right: 10px;
  text-transform: capitalize;
  word-break: break-word;
}
.breadcrumbs-item:hover {
  color: #f96332;
}
.breadcrumbs-separator {
  color: #708295;
  padding-right: 10px;
}
.breadcrumbs-item-wrapper {
  margin-bottom: 15px;
}
.main-nav-wrapper .breadcrumbs ul li:hover::after {
  color: #cacaca;
}
.main-nav-wrapper .breadcrumbs ul li.active-breadcrumbs {
  color: #333;
}
.main-nav-wrapper .breadcrumbs ul li:after {
  content: "/";
  padding: 15px;
}
.main-nav-wrapper .breadcrumbs ul li.active-breadcrumbs::after {
  display: none;
}
.main-nav-wrapper .breadcrumbs ul li.active-breadcrumbs:before {
  content: "";
  height: 5px;
  width: 100%;
  background: #f96332;
  position: absolute;
  bottom: -29px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.cart-steps-header ul li a.active {
  color: #333 !important;
  position: relative;
}
.cart-steps-header ul li a.active:before {
  content: "";
  height: 5px;
  width: 110%;
  background: #f96332;
  position: absolute;
  bottom: -20px;
  left: -8px;
}

.cart-step-box {
  padding: 15px;
  margin-bottom: 0;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  font-size: 0;
  margin-bottom: 15px;
}
.cart-step-box-wrapper {
  padding: 20px;
}
.cart-step-accept-terms {
  padding: 0 20px;
  font-size: 13px;
  margin-bottom: 15px;
}
.cart-step-accept-terms .mat-checkbox-layout {
  margin-bottom: 0;
  margin-right: 5px;
}
.cart-step-accept-terms .mat-checkbox-inner-container {
  height: 16px !important;
  width: 16px !important;
}
app-cart-billing .cart-step-box-wrapper:first-child {
  padding: 0;
}
.cart-step-box-header {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
}
.cart-box-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}
.cart-box-address p {
  font-size: 14px;
  margin-bottom: 5px;
}
.cart-box-address-add {
  color: #f96332;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin: 15px 0;
  cursor: pointer;
}
.cart-box-address-add:hover {
  color: #db5527;
  text-decoration: underline;
}
.cart-box-address {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
}
.cart-box-right-total span {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-right: 10px;
}
.cart-box-right-total {
  font-size: 22px;
  color: #f96332;
  font-weight: 700;
  line-height: 22px;
}
.cart-step-box .cart-box-item-wrapper:last-child {
  border: none;
}
.header-top-action-no-logged a {
  margin-left: 30px;
}
a:not([href]):not([tabindex]) {
  text-decoration: none;
  cursor: pointer;
}
.header-top-action-no-logged a:not([href]):not([tabindex]) {
  color: #708295;
}
.header-top-action-no-logged a:not([href]):not([tabindex]):hover {
  color: #f96332;
  text-decoration: underline;
}
.header-top-lang {
  margin-left: 15px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header-top-lang {
  display: none;
}
#header-wrapper-portal {
  position: fixed;
  background: #f4f5f6;
  right: 0;
  top: 0;
  width: calc(100% - 250px);
  z-index: 98;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
#header-wrapper-market {
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.header-breadcrumbs {
  float: left;
  height: 50px;
  line-height: 50px;
}
.header-bottom-action {
  width: calc(100% - 182px);
}
.search-box-header-wrapper {
  width: calc(100% - 405px);
  position: relative;
}
.service-categ.btn {
  color: #fff;
  font-size: 18px;
  line-height: 76px;
  padding: 0;
  width: 182px;
  margin: -20px 0;
}
.small-fixed-header-portal .header-top {
  padding: 0 45px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  height: 51px;
}
.small-fixed-header-market .header-top {
  padding: 0;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.small-fixed-header-market.header-wrapper:before {
  top: 51px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.small-fixed-header-portal .header-top-user-img img,
.small-fixed-header-market .header-top-user-img img {
  width: 40px;
  height: 40px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.small-fixed-header-portal .notification-item i,
.small-fixed-header-market .notification-item i {
  font-size: 20px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.small-fixed-header-portal .notification-count,
.small-fixed-header-market .notification-count {
  width: 20px;
  height: 20px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.small-fixed-header-market .logo img {
  width: 170px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  padding: 3px;
}
.small-fixed-header-market .eu-funds-img img {
  width: 45px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.15s ease-out;
  margin: 0 10px;
}
.small-fixed-header-market .eu-logo {
  width: 150px !important;
}
.small-fixed-header-market .header-bottom {
  z-index: 1000;
  position: relative;
  padding: 10px 0;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.small-fixed-header-market .service-categ.btn {
  padding: 0;
  line-height: 56px;
  margin: -10px 0;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.header-wrapper.small-fixed-header-market {
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.2);
}
.small-fixed-header-market
  .main-nav-wrapper
  .breadcrumbs
  ul
  li.active-breadcrumbs:before {
  bottom: -19px;
  -ms-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* end header */

/* fixed elem */
.details-navigation {
  height: 41px;
  margin-bottom: 15px;
}
.small-dashboard-body-top-0ElemUp {
  top: 51px !important;
}
.small-dashboard-body-top-1ElemUp {
  top: 92px !important;
}
.small-dashboard-body-top-2ElemUp {
  top: 133px !important;
}
.small-app-nav {
  position: fixed;
  top: 51px;
  z-index: 999;
  width: calc(100% - 340px) !important;
  background: #f4f5f6;
  padding: 5px 0;
  margin-bottom: 0;
  height: 41px;
}
.small-app-nav .big-title {
  font-size: 20px;
  line-height: 30px;
}
.small-app-nav .details-navigation .mat-tab-group.mat-primary .mat-ink-bar,
.small-app-nav .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 2px;
}
.small-app-nav .mat-ink-bar {
  bottom: 5px;
}
.details-navigation .big-title {
  transition: all 0.3s ease-out;
}
.small-dashboard-body-top {
  position: fixed;
  top: 51px;
  z-index: 999;
  width: calc(100% - 340px) !important;
  background: #f4f5f6;
  padding: 5px 0;
  margin-bottom: 0 !important;
  height: 41px;
}
.small-dashboard-body-top .big-title {
  font-size: 20px;
  line-height: 30px;
}
.small-dashboard-body-top .btn {
  padding: 5px 15px;
}
.small-dashboard-body-action {
  background: #f4f5f6;
  width: calc(100% - 340px) !important;
  margin-bottom: 0 !important;
  top: 92px;
  padding: 5px 0;
  z-index: 999;
  border-top: 1px solid #efefef;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.07);
  height: 45px;
}
.small-dashboard-body-action .btn {
  padding: 5px 15px;
}
.small-dashboard-body-action .ng-select .ng-select-container {
  min-height: 31px !important;
}
.small-dashboard-body-action .advanced-search-input input {
  padding: 7px 25px 7px 40px;
  line-height: 31px;
  height: 31px;
}
.small-dashboard-body-action .icon-inside-input i {
  top: 5px;
}
.small-table-bulk {
  position: fixed;
  top: 150px;
  z-index: 999;
  width: calc(100% - 340px) !important;
  background: #f4f5f6;
  padding: 7px;
  margin-bottom: 0 !important;
}
.small-table-bulk .bulk-action-wrapper {
  padding: 5px 15px;
  min-height: 41px;
  line-height: 31px;
}
/* end fixed elem */

/* scroll to top */
#scrollToTop {
  position: fixed;
  bottom: 65px;
  z-index: 9999999999;
  border: none;
  outline: none;
  background-color: rgba(153, 168, 61, 0.5);
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  visibility: hidden;
}
#scrollToTop i:before {
  font-size: 18px;
  margin: 0;
  padding: 0;
}
#scrollToTop:hover {
  background-color: #89b51a;
}
.fixed-header-on #scrollToTop {
  visibility: visible;
}
/* end scroll to top */

/* auth */
.registration-step-item {
  color: #708295;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
}
.registration-steps {
  margin-bottom: 20px;
}
.registration-steps-dots {
  text-align: center;
  margin: 20px 0 0 0;
}
.registration-step-dots-item {
  height: 12px;
  width: 12px;
  background: rgba(249, 99, 50, 0.5);
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.active-setp-dot {
  background: rgba(249, 99, 50, 1);
}

body.auth {
  background: #fff;
}
.auth-right-wrapper {
  /* background: url('/assets/images/web-devices.png'); */
  background-color: rgb(255, 255, 255);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 55%;
  right: 0;
  top: 0;
  background-position: center center;
}
.auth-right-wrapper img {
  width: 400px;
  position: relative;
  left: 25%;
}
@media only screen and (max-width: 1024px) {
  .auth-right-wrapper img {
    display: none;
  }
}
@media only screen and (max-width: 1440px) {
  .auth-right-wrapper img {
    width: 250px;
  }
}
.password-container {
  position: relative;
}

.show-pass {
  position: absolute;
  right: 10px;
  bottom: 18px;
  cursor: pointer;
  font-size: 20px;
}

.auth-right-title {
  font-size: 26px;
  color: rgb(0, 0, 0);
  font-weight: 700;
}
.auth-right-desc {
  font-size: 18px;
  color: rgb(0, 0, 0);
}

.auth-right-inner ul {
  padding-left: 18px;
  margin-bottom: 15px;
}
.auth-right-line {
  width: 35px;
  height: 4px;
  background: #fff;
  border-radius: 1px;
  margin: 30px 0;
}
.auth-right-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}
.auth-form-title {
  font-size: 34px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.auth .btn {
  width: 100%;
}
.auth-left-wrapper {
  width: 45%;
  float: left;
  position: relative;
  height: 100vh;
}
.form-login {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
}
.auth-form-wrapper {
  /* position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  right: 0;
  padding: 0 100px; */
  padding: 0 75px;
  margin: 35px 0;
}
.auth-form-wrapper form {
  overflow: hidden;
}
.auth-footer {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 100px 100px 100px;
  font-size: 12px; */

  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 100px -150px 100px;
  font-size: 12px;
  padding-bottom: 50px; */
}

.login-footer {
  margin: 0 75px 30px 75px !important;
  /* bottom: 80px; */
}

.auth-form-wrapper .btn {
  margin-top: 10px;
}
.under-form {
  font-size: 12px;
  margin: 20px 0;
}
.auth-left-inner .logo {
  /* width: 250px; */
  margin: 70px 75px 0 75px;
}
.auth-left-inner .logo,
.auth-left-inner .eu-funds-img {
  height: auto;
}

.auth-left-inner .logo img,
.auth-left-inner .eu-funds-img img {
  top: auto;
  transform: unset;
  margin-bottom: 15px;
}
/* .auth-left-inner .logo img {
  width: 100%;
} */
.auth-lang-selector {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: none;
}
.forgot-pass {
  padding-bottom: 10px;
}
/* end auth */

/* dashboard statistics */
.chart-wrapper {
  width: 100%;
  height: 300px;
  min-height: 300px;
  position: relative;
}
.chart-wrapper-auto-width {
  width: auto !important;
}
.label-inside-doughnut-chart {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.label-inside-doughnut-chart span {
  display: block;
  font-size: 20px;
  font-weight: 600;
}
.dashboard-statistics-box {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  font-size: 0;
  position: relative;
  height: 300px;
  z-index: 2;
  /* overflow: hidden; */
}
.dashboard-statistics-box-title {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  float: left;
}
.dashboard-statistics-box-header-action {
  float: right;
  min-width: 150px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.dashboard-statistics-box-right-legend {
  float: left;
  margin-left: 30px;
  position: relative;
  height: 300px;
}
.dashboard-statistics-box-right-legend-content {
  float: left;
  margin-left: 30px;
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}
.dashboard-statistics-box-right-legend-vertical-title {
  float: left;
  font-size: 16px;
  font-weight: 600;
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}
.dashboard-statistics-box-right-legend-content p {
  margin-bottom: 7px;
  font-weight: 500;
  position: relative;
}
span.dashboard-statistics-box-right-legend-point {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0 !important;
  top: 1px;
}
.dashboard-statistics-box-right-legend-content p span {
  margin-left: 21px;
}
.dashboard-statistics-box-header {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #efefef;
  height: 50px;
  line-height: 50px;
  position: absolute;
  left: 0;
  padding: 0 15px;
  background: #fff;
  z-index: 2;
}
.date-filter-select {
  margin-bottom: 0 !important;
}
.dashboard-statistics-box-subtitle {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 14px;
}
.dashboard-statistics-box-body {
  height: 300px;
  overflow-y: auto;
  padding: 50px 15px 0;
  overflow-x: hidden;
  font-size: 0;
}
.dashboard-statistics-box-body:last-child {
  margin-bottom: 0;
}
.dashboard-statistics-box-empty {
  padding: 15px 0;
  font-weight: 500;
}
.dashboard-statistics-box-body-list-item span {
  font-weight: 500;
}
.dashboard-statistics-box-body-list-item {
  margin-bottom: 7px;
}
.sts-table-wrapper {
  height: 250px;
  overflow: auto;
  padding-top: 50px;
}
.statistics-table {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  height: 300px;
}
.statistics-table table {
  width: 100%;
  border-collapse: collapse;
}
.statistics-table table thead tr th {
  border-bottom: 1px solid #f6f6f6;
  padding: 15px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}
.statistics-table table tbody tr td {
  border-bottom: 1px solid #f6f6f6;
  padding: 15px;
}
.dashboard-statistics-total-box {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.dashboard-statistics-box
  ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input,
.table-header-action-wrapper
  ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 20px 0 15px !important;
  height: 35px;
  line-height: 35px;
}
.statistics-inner-box-wrapper,
.statistics-box-row {
  clear: both;
  display: inline-block;
  width: 100%;
  font-size: 0;
}
.statistics-box-row span {
  font-size: 14px;
}
.statistics-box-row {
  height: 44px;
  line-height: 44px;
  border-bottom: 1px dashed #efefef;
}
.statistics-inner-box-wrapper {
  border-bottom: 1px solid #efefef;
}
.statistics-compare-label {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  color: #708295;
  background: #fff;
  padding: 1px 5px;
  white-space: nowrap;
}
.statistics-compare-label-wrapper {
  position: relative;
  height: 0;
  width: 100%;
}
.statistics-compare-label-wrapper.sts-last-child {
  display: none;
}
.statistics-inner-box-wrapper.sts-last-child {
  border: none;
  margin: 0;
  padding: 0;
}
.statistics-inner-box-list-wrapper .sts-last-child {
  border: none;
}
.statistics-inner-box-list-wrapper .statistics-box-row:last-child {
  border: none;
}
.dashboard-statistics-chart-wrapper {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
}
.table-header-action-wrapper {
  position: relative;
}
.statistics-tab-wrapper {
  font-size: 14px;
  position: relative;
}
.statistics-tab-wrapper .tab-item {
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  padding: 0 5px;
  position: relative;
  color: #708295;
}
.statistics-tab-wrapper .tab-item:hover {
  color: #f96332;
}
.active-sts-tab:before {
  content: "";
  height: 2px;
  background: #f96332;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.active-sts-tab {
  color: #333 !important;
}
.tab-r-t-l .tab-item {
  margin-left: 10px;
}
.tab-l-t-r .tab-item {
  margin-right: 10px;
}
.statistics-box-mask {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  border-radius: 4px;
}
.sts-box-h-50 {
  height: 145px;
}
.sts-box-body-h-50 {
  height: 145px;
}
.sts-title-m-b {
  margin-bottom: 30px;
}
.sts-content-row-m-b {
  margin-bottom: 30px;
}
.dashboard-statistics-wrapper .big-title {
  line-height: 24px;
}
.sts-section {
  margin-bottom: 45px;
}
span.hardcoded {
  background: #89b51a;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
  text-transform: lowercase;
}
.dashboard-statistics-box-header-action-multi {
  float: right;
  height: 50px;
}
.dashboard-statistics-box-header-action-multi .statistics-tab-wrapper {
  margin-right: 15px;
}
.sts-calendar-wrapper {
  font-size: 14px;
  z-index: 999;
}
.sts-calendar-wrapper .calendar-input-control {
  height: 35px;
  line-height: 35px;
  padding: 0;
  font-size: 11px;
  color: #708295;
  font-weight: 500;
  width: 140px;
}
.sts-calendar-wrapper .calendar-input-icon {
  display: none;
}
.sts-calendar-wrapper .toggle-calendar-icon {
  line-height: 50px !important;
  height: 45px;
  width: 20px;
}
.sts-calendar-wrapper .editable-input-calendar {
  float: right;
}
.sts-calendar-wrapper .toggle-calendar-icon {
  display: none;
}

.calendar-white-icon .toggle-calendar-icon {
  background: transparent;
  color: #fff;
}
.statistics-inner-box-wrapper-center-elem.ng-star-inserted {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.sts-empty-box {
  font-size: 14px;
  font-weight: 500;
  color: #708295 !important;
  padding: 15px 0;
}
/* end dashboard statistics */

/* blog */
.blog-right-box {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 30px;
}
.active-blog-item {
  color: #f96332;
  font-weight: 600;
}
.blog-right-side-btn {
  margin-bottom: 15px;
}
.blog-right-box-title {
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
}
.no-found-blog {
  margin: 30px 0;
  font-size: 16px;
}
.blog-right-box-item-wrapper {
  padding: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #708295;
}
.blog-right-box-item {
  padding-bottom: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  border-bottom: 1px dashed #efefef;
}
.sub-blog-right-box-item {
  margin-top: 10px;
  cursor: pointer;
  padding-left: 10px;
}
.sub-blog-right-box-item:hover {
  color: #f96332;
}
.sub-blog-right-item-wrapper {
  display: none;
}
.sub-is-open ~ .sub-blog-right-item-wrapper {
  display: block;
}
.main-blog-right-box-item.sub-is-open i:before {
  content: "\f107";
}
.blog-right-box-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.main-blog-right-box-item-wrapper {
  padding-bottom: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  border-bottom: 1px dashed #efefef;
}
.main-blog-right-box-item:hover {
  color: #f96332;
}
.blog-right-box-item:hover {
  color: #f96332;
}
.blog-list-wrapper {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 30px;
}
.blog-list-img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
}
.blog-list-item {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #efefef;
}
.blog-list-article-date {
  position: absolute;
  top: 10px;
  left: -10px;
  background: #f96332;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
}
.blog-list-title {
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  height: 65px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-list-title p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-list-title:hover {
  color: #f96332;
}
.blog-list-content {
  padding: 20px;
  text-align: justify;
  line-height: 1.8;
}
.search-blog-list-content {
  padding: 20px;
  line-height: 1.8;
  text-align: justify;
  height: 65px;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
}
.search-blog-list-content:before {
  content: "...";
  position: absolute;
  bottom: -5px;
  right: 10px;
}
.blog-list-title:before {
  content: "";
  width: 75px;
  height: 2px;
  background: #f96332;
  position: absolute;
  bottom: 0;
}
.blog-wrapper .advance-search-row {
  width: 100%;
  margin: 0;
}
.blog-list-content:after {
  content: "...";
  bottom: 0;
  right: 0;
}
.blog-read-more {
  float: right;
  margin-right: 20px;
}
.blog-article-details-wrapper {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
}
.blog-details-img img {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
}
.blog-details-article-date {
  position: absolute;
  top: 10px;
  left: -10px;
  background: #f96332;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
}
.blog-details-title {
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.blog-details-content {
  padding: 20px;
  text-align: justify;
  line-height: 1.8;
}
.blog-details-title:before {
  content: "";
  width: 75px;
  height: 2px;
  background: #f96332;
  position: absolute;
  bottom: 0;
}
.blog-header {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  width: 100%;
  display: inline-block;
}
.blog-wrapper {
  margin: 50px 0;
}
.blog-header-btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* end blog */

/* overlay header */
.header-overlay-wrapper .mat-menu-content {
  padding: 0 !important;
}
/* calendar */
.toggle-calendar-icon {
  height: 45px;
  line-height: 45px;
  background: #fff;
  width: 45px;
  text-align: center;
  font-size: 20px;
  color: #708295;
  cursor: pointer;
  border-radius: 4px;
  float: left;
}
.toggle-calendar-icon:hover {
  color: #f96332;
}
.editable-input-calendar {
  float: left;
}
.dashboard-body-action .calendar-input-control {
  height: 45px;
  line-height: 45px;
  background: #fff;
  border-radius: 4px;
  padding: 10px 5px;
  border: none;
  font-size: 13px;
  text-align: center;
  color: #333;
}
/* .editable-input-calendar:before {
  font-family: 'icons';
  content: '\e81d';
  color: #708295;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 3px;
} */
.editable-input-calendar {
  position: relative;
}
.toggle-is-open + .range-calendar-wrapper {
  display: block;
}
.calendar-input-target ~ .range-calendar-wrapper {
  display: block;
}

.editable-input-calendar-wrapper {
  position: relative;
  float: left;
}
/* .rfq-bid-modal .editable-input-calendar-wrapper {
  display: initial;
} */
.range-calendar-wrapper {
  position: absolute;
  top: 48px;
  z-index: 99;
  background: #fff;
  right: 0;
  display: none;
}
.range-calendar-wrapper ngb-datepicker {
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #efefef;
}
.range-calendar-wrapper .ngb-dp-header {
  background: #fff !important;
  border-radius: 0;
  padding: 5px;
}
.range-calendar-wrapper .ngb-dp-arrow span {
  color: #f96332 !important;
}
.range-calendar-wrapper .custom-select {
  color: #708295;
  font-weight: 500;
  border: 1px solid #efefef;
  margin: 0 5px;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  width: auto;
}
.range-calendar-wrapper .custom-select option {
  background: #fff;
}
.range-calendar-wrapper .ngb-dp-weekday {
  background: #fff;
  color: #f96332;
  font-weight: 500 !important;
  font-size: 12px;
}
.range-calendar-wrapper .ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  color: #708295;
}
.range-calendar-wrapper .ngb-dp-weekdays {
  border-bottom: 1px solid #efefef !important;
  background: #fff !important;
}
.range-calendar-wrapper .ngb-dp-navigation-select {
  flex: none;
}
.calendar-input-control {
  border: none;
  /* border-bottom: 1px solid #efefef; */
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
  background: #f0f0f0;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #333;
}
.custom-modal-body .calendar-input-wrapper {
  margin-bottom: 15px;
}
.range-calendar-wrapper .ngb-dp-month-name {
  background: #fff !important;
  color: #333;
}
.show-datepicker {
  display: block !important;
}
.calendar-range-picker {
  position: absolute;
  z-index: 999;
  display: none;
  top: 75px;
  right: 0;
  -webkit-box-shadow: 0 8px 16px 0 rgba(74, 144, 226, 0.15);
  box-shadow: 0 8px 16px 0 rgba(74, 144, 226, 0.15);
}
.date-range-picker-wrapper {
  position: relative;
  display: inline-block;
}
.date-range-open {
  border: 1px solid #d7dee7;
  background: #fff;
  line-height: 40px;
  display: inline-block;
  padding: 0 35px 0 20px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  color: #8f96a6;
}
.date-range-open:after {
  content: "\e800";
  margin-left: 10px;
  display: inline-block;
  font-family: "icons";
  font-size: 13px;
  position: absolute;
  top: 0;
  right: 10px;
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.date-range-selected-wrapper {
  background: #fff;
  text-align: center;
  color: #8f96a6;
  border: 1px solid #d7dee7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 80px;
  line-height: 80px;
}
.date-range-selected-box {
  border: 1px solid #d7dee7;
  background: #fff;
  line-height: 40px;
  display: inline-block;
  padding: 0 15px;
  border-radius: 4px;
  margin: 0 10px;
}
.date-range-action {
  background: #fff;
  line-height: 40px;
  display: inline-block;
  padding: 0;
  margin: 0 10px;
}
.date-range-action-btn {
  display: inline-block;
  border: 1px solid #d7dee7;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
  cursor: pointer;
}
.ngb-datepicker-custom {
  background: #fff;
  padding: 20px;
  font-size: 14px;
  border: 1px solid #d7dee7 !important;
  border-top: none !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  display: block !important;
}
.ngb-dp-weekday,
.ngb-dp-week-number {
  text-align: center;
  font-style: normal !important;
  font-weight: 500 !important;
}
.ngb-dp-month-name {
  font-size: 15px !important;
  text-align: center !important;
  font-weight: 500 !important;
}
.ngb-dp-weekdays {
  border-bottom: 1px solid #d7dee7 !important;
  border-radius: 0 !important;
  margin-top: 15px !important;
}
.custom-day {
  text-align: center;
  display: inline-block;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-day.focused {
  background-color: #ccbfbf;
}

.custom-day.focused-single {
  background-color: #f96332;
  color: white;
  border-radius: 4px;
}
.custom-day.range,
.custom-day:hover {
  background-color: #f96332;
  color: #fff;
  border-radius: 4px;
}
.custom-day.faded-opacity {
  background-color: #efefef;
  color: inherit;
  border-radius: 0;
  opacity: 0.6;
}

.custom-day.faded {
  background-color: #efefef;
  color: inherit;
  border-radius: 0;
}
.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.mat-button,
.mat-fab,
.mat-icon-button,
.mat-mini-fab {
  color: #4a90e2 !important;
}
.mat-calendar-header .mat-button,
.mat-calendar-header .mat-icon-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.mat-button-focus-overlay {
  background: rgba(188, 188, 236, 0.2) !important;
}
.mat-button-ripple.mat-ripple {
  display: none;
}
.mat-calendar-body-selected {
  background-color: #4a90e2;
  color: #fff !important;
}
.mat-datepicker-toggle-active {
  color: #4a90e2;
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none !important;
}
.editable-input-calendar-wrapper label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.modal-calendar-wrapper .toggle-calendar-icon {
  margin-top: 30px;
}
.modal-calendar-wrapper {
  position: absolute;
}
.calendar-input-wrapper {
  position: relative;
}
.calendar-input-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  color: #708295;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.modal-calendar-wrapper .range-calendar-wrapper {
  top: 80px;
}
.fix-position-calendar-modal {
  height: 75px;
}
.m-t-calendar-icon {
  margin-top: 30px;
}
/* end calendar */

/* overlay header */
.header-overlay-wrapper .mat-menu-content {
  padding: 0 !important;
}
.no-item-overlay {
  color: #708295;
}
.header-overlay-wrapper {
  border-radius: 4px !important;
  width: 340px !important;
  max-width: 340px !important;
}
.header-overlay-top {
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
}
.header-overlay-img {
  float: left;
}
.header-overlay-item-body {
  float: left;
  margin-left: 10px;
  width: calc(100% - 70px);
}
.header-overlay-item-body .cart-item-details-range-wrapper {
  float: right;
  width: 104px;
}
.header-overlay-img img {
  width: 60px;
  object-fit: cover;
  height: 60px;
  border-radius: 4px;
}

.messaging-header-overlay-img img {
  width: 50px;
  object-fit: cover;
  height: 50px;
  border-radius: 25px;
}

.messaging-header-overlay-item {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  width: 100%;
}

.header-overlay-item {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  display: inline-block;
  width: 100%;
}
.header-overlay-bigtxt {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 16px;
  cursor: pointer;
}
.header-overlay-bigtxt:hover {
  color: #f96332;
}
.header-overlay-bottom {
  padding: 15px;
  /* position: fixed; */
  background: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}
.header-overlay-body {
  font-size: 0;
  max-height: 370px;
  overflow: auto;
  display: block;
}
.header-overlay-unit-price {
  font-weight: 500;
  font-size: 13px;
  color: #708295;
}

.header-overlay-price {
  font-size: 15px;
  font-weight: 600;
}
.header-overlay-old-price {
  font-size: 12px;
  font-weight: 500;
  color: #f96332;
  float: left;
  /* margin-left: 7px; */
}
.line-through {
  text-decoration: line-through;
}

.header-overlay-price-wrapper {
  float: left;
  width: calc(100% - 104px);
}
.header-overlay-total-cart-items {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.header-overlay-total-cart-price {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}
.header-overlay-total-cart {
  margin-bottom: 7px;
  border-bottom: 1px dashed #eaeaea;
  padding-bottom: 7px;
}
.header-overlay-checkout {
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.header-overlay-checkout-cart {
  padding: 10px;
  margin-top: 15px;
}
/* end overlay header */
/* cart */
.cart-left-side {
  width: 320px;
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  float: left;
}
.cart-right-side {
  width: calc(100% - 360px);
  float: left;
  margin-left: 40px;
}
.cart-item-title {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #eaeaea;
  padding: 20px;
  line-height: 18px;
}
.cart-item-footer {
  display: inline-block;
  width: 100%;
  padding: 20px;
}
.cart-item-body {
  display: inline-block;
  width: 100%;
  font-size: 0;
  border-bottom: 1px solid #eaeaea;
  padding: 20px;
}
.cart-item-body p span:last-child {
  font-weight: 700;
}
.cart-item-footer p span {
  font-size: 16px;
  font-weight: 700;
}
.cart-item-footer p span:last-child {
  font-size: 22px;
}
.cart-item-footer p {
  line-height: 24px;
}
.cart-right-steps {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  font-size: 0;
  border-radius: 4px;
}
.cart-steps-header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cart-steps-header ul li {
  display: inline;
}
.cart-steps-header ul li a {
  color: #cacaca !important;
  font-weight: 700;
  font-size: 16px;
  margin-right: 40px;
  line-height: 16px;
  cursor: default !important;
}
.cart-steps-header {
  padding: 20px;
  border-bottom: 1px solid #eaeaea;
}
.cart-item-details-img img {
  width: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100px;
  border-radius: 4px;
}
.cart-item-details-img {
  float: left;
}
.cart-item-details {
  border-bottom: 1px solid #eaeaea;
  /* padding: 20px; */
  display: inline-block;
  width: 100%;
}
.cart-right-side .cart-item-details {
  padding: 20px;
}
.cart-item-details .cart-item-details-range-wrapper {
  margin-bottom: 10px;
}
.cart-item-details-left {
  float: left;
  margin-left: 15px;
  width: calc(100% - 320px);
}
.cart-item-details-right {
  float: right;
}
.cart-items-to-add {
  float: right;
  min-width: 62px;
}
.cart-item-details-title {
  font-size: 16px;
  /* line-height: 16px; */
  font-weight: 600;
  margin-bottom: 5px;
  cursor: pointer;
}
.cart-item-details-title:hover {
  color: #f96332;
}
.cart-item-details-desc p {
  color: #708295;
  margin-bottom: 5px;
  font-size: 14px;
}
.cart-item-details-range button {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #cacaca;
  cursor: pointer;
}

.update-qty {
  padding: 2px 6px;
  font-size: 12px;
}

.cart-item-details-range button:hover,
.cart-item-details-delete:hover,
.fav-item-details-delete:hover {
  color: #f96332;
}
.cart-item-details-range button i {
  font-size: 16px;
}
.cart-item-details-range {
  float: left;
}
.cart-item-details-range button:first-child {
  float: left;
  line-height: 25px;
  margin: 0;
  height: 25px;
  padding: 0 2px;
}
.cart-range-qty {
  background: #f0f0f0;
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 25px !important;
  float: left;
  font-size: 14px;
  border-radius: 4px;
}
.input-cart-range-qty {
  background: #f0f0f0;
  text-align: center;
  width: 45px;
  height: 30px;
  line-height: 25px !important;
  float: left;
  font-size: 14px;
  border-radius: 4px;
  border: none;
}
.input-cart-range-qty::-webkit-outer-spin-button,
.input-cart-range-qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-cart-range-qty[type="number"] {
  -moz-appearance: textfield;
}
.add-to-cart-modal .cart-item-details-range button:first-child {
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  float: left;
  margin: 0;
}
.add-to-cart-modal .cart-item-details-range button:last-child {
  float: left;
  line-height: 30px;
  margin: 0;
  height: 30px;
  padding: 0 5px;
}
.inner-notification {
  color: #f96332;
  font-size: 12px;
  text-align: right;
}
.inner-notification-msg {
  background: #89b51a !important;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
}

.cart-item-details-range button:last-child {
  float: left;
  line-height: 25px;
  margin: 0;
  height: 25px;
  padding: 0 2px;
}
.add-to-cart-modal .cart-item-details-range-wrapper {
  min-height: 30px;
  float: right;
}
.cart-item-details-range-wrapper {
  min-height: 35px;
}
.cart-item-details-delete {
  cursor: pointer;
  float: left;
  color: #cacaca;
  font-size: 16px;
  line-height: 25px;
}
.fav-item-details-delete {
  cursor: pointer;
  float: right;
  color: #cacaca;
  font-size: 16px;
  line-height: 25px;
}
.cart-step-price-old {
  font-size: 12px;
  text-decoration: line-through;
  font-weight: 500;
  color: #f96332;
}
.cart-step-price-new {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
.cart-step-price-unit {
  font-size: 12px;
  font-weight: 500;
  color: #708295;
}
.cart-steps-price {
  text-align: right;
}
.cart-steps-price div {
  margin-bottom: 5px;
}
.cart-item-body p {
  /* margin-bottom: 15px; */
  display: inline-block;
  width: 100%;
  font-size: 16px;
}
.cart-item-body p:last-child {
  margin-bottom: 0;
}
.cart-item-details-foote-top,
.cart-item-details-foote-bottom {
  display: inline-block;
  width: 100%;
}
.cart-item-details-foote-top p span {
  font-size: 18px;
  font-weight: 700;
}
.cart-item-details-foote-top {
  border-bottom: 1px solid #eaeaea;
  padding: 30px 40px;
}
.footer-bottom-txt {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart-item-details-foote-bottom span:first-child {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 7px;
}
.cart-item-details-foote-bottom button {
  margin-top: 10px;
}
.footer-bottom-txt {
  font-size: 18px !important;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 34px !important;
  vertical-align: middle;
  margin-right: 25px;
}
.cart-item-details-foote-bottom {
  padding: 20px;
}
.cart-item-details-foote-bottom .header-overlay-total-cart {
  text-align: right;
}
.cart-box-title-action {
  color: #f96332;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.cart-box-title-action span {
  margin-left: 10px;
}
.cart-box-title-action .action-link-orange {
  font-size: 14px;
  font-weight: 500;
}
.cart-box-title-action span:hover {
  color: #db5527;
  text-decoration: underline;
}
/* .cart-step-footer .transparent-bg {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
} */
/* .cart-step-footer .btn {
  padding: 14px 20px;
} */
.cart-step-footer {
  display: inline-block;
  width: 100%;
}
.cart-step-footer-elem div:first-child {
  margin-right: 15px;
}
.cart-box-item-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.cart-box-item-desc {
  font-size: 14px;
  color: #708295;
}
.cart-box-item-desc p {
  margin-bottom: 5px;
}
.cart-box-item-qty {
  font-size: 18px;
  color: #708295;
  text-align: right;
  /* margin-bottom: 10px; */
}
.cart-box-item-wrapper {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  /* border-bottom: 1px solid #EAEAEA; */
}
.cart-box-item-footer {
  display: inline-block;
  width: 100%;
  padding: 15px 0 0 20px;
  line-height: 20px;
}
.cart-box-item-footer p {
  font-size: 16px;
  font-weight: 600;
}
.cart-box-item-footer p span:last-child {
  font-size: 20px;
  font-weight: 700;
}
.cart-step-gdpr {
  font-size: 14px;
  color: #708295;
}
/* end cart */

/* offers details page */
.card-box .info-card {
  margin-top: 15px;
}
.card-box-action-btn .btn-small {
  width: 100%;
}
.card-box-action-btn {
  margin-top: 20px;
}

.custom-tab-wrapper {
  width: 100%;
  float: left;
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 30px;
}

.custom-tab-wrapper .mat-tab-body-content,
.custom-tab-wrapper .mat-tab-body,
.custom-tab-wrapper .mat-tab-body.mat-tab-body-active,
.custom-tab-wrapper .mat-tab-body-wrapper {
  overflow: unset !important;
  transform: translate3d(0px, 0px, 0px) !important;
}
.info-card-tab {
  margin-top: 20px;
}
.custom-tab-wrapper .mat-tab-label {
  opacity: 1 !important;
  min-width: 140px !important;
  height: auto !important;
  padding: 0 15px 15px !important;
}
.custom-tab-wrapper .mat-tab-label-content {
  color: #cacaca;
  font-size: 16px;
  font-weight: 700;
}
.custom-tab-wrapper .mat-tab-label:hover .mat-tab-label-content {
  color: #333;
}
.custom-tab-wrapper .mat-tab-header {
  border-bottom: 1px solid #f0f0f0;
}
.mat-tab-label-active .mat-tab-label-content {
  color: #333;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f96332;
  height: 4px;
}
.custom-tab-wrapper .star {
  position: relative;
  display: inline-block;
  font-size: 25px;
  color: #d8d8d8;
  line-height: 25px;
  width: 25px;
  height: 25px;
}
.custom-tab-wrapper .full {
  color: #f96332;
}
.custom-tab-wrapper .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}
.custom-tab-wrapper app-rating {
  display: inline-block;
  margin-bottom: 15px;
}

.provider-rating {
  margin-bottom: 7px;
  padding: 20px;
  box-shadow: none;
  border: 1px solid #cacaca;
  display: flex;
  align-items: center;
}

.provider-rating app-rating {
  margin-bottom: 0;
  font-size: 28px;
  margin-right: 10px;
}

.provider-rating .star {
  font-size: 30px;
}

.provider-modal-title {
  padding-bottom: 25px;
  display: inline-block;
  width: 100%;
}

.criteria-name {
  font-size: 17px;
  /* padding-right: 30px; */
  font-weight: bold;
}

.provider-rating-container {
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}

.tab-review-overall,
.tab-card-list-wrapper {
  padding: 30px 0;
}
.tab-card-list-wrapper .card-wrapper {
  width: 330px;
}
.tab-card-list-wrapper .card-box {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.25);
}
.tab-card-list-wrapper app-rating {
  margin-bottom: 0;
}
.tab-details {
  padding: 30px 0;
  line-height: 26px;
}
.tab-offer-description {
  /* color: #708295; */
  line-height: initial;
  margin-bottom: 10px;
  white-space: pre-wrap;
}
.tab-details p {
  margin-bottom: 10px;
}

.tab-details p span {
  font-weight: 600;
  margin-right: 5px;
  display: block;
  line-height: 11px;
}
.tab-review-overall .big-p {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 15px;
}
.tab-review-overall .big-p span {
  font-size: 14px;
  font-weight: 500;
  color: #cacaca;
}
.stars-bar-left,
.stars-bar-middle,
.stars-bar-right {
  float: left;
}
.stars-bar-left {
  font-weight: 600;
  width: 50px;
}
.stars-bar-left span {
  color: #f96332;
}
.stars-bar-right {
  color: #cacaca;
  font-weight: 500;
}
.tab-review-overall .bar-container {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 4px;
}
.stars-bar-middle {
  width: 70%;
  margin: 3px 5px 7px 15px;
}
.star-bar-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
.tab-review-overall .bar {
  height: 15px;
  border-radius: 4px;
}
.review-box-left img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 15px;
}
.review-box-left {
  width: 25%;
  float: left;
  font-size: 14px;
}
.review-box-right {
  width: 75%;
  float: left;
  font-size: 14px;
}
.review-box-write-label {
  font-weight: 700;
  font-size: 14px;
}
.review-box-write-label {
  font-weight: 700;
  margin-bottom: 5px;
}
.review-box-wrapper {
  display: inline-block;
  width: 100%;
  padding: 25px 0;
  border-top: 1px solid #eaeaea;
  font-size: 0;
}
.review-date {
  font-size: 12px;
  margin-top: 7px;
}
.review-box-wrapper .big-p {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 5px;
}
.review-body {
  color: #6d6c6a;
  margin-bottom: 15px;
}
.reply-btn {
  margin-bottom: 15px;
}
.view-more {
  font-size: 12px;
  cursor: pointer;
  color: #f96332;
  font-weight: 500;
}
.view-more:hover {
  text-decoration: underline;
}
.children-comments-wrapper {
  display: inline-block;
  width: 100%;
}
.review-lvl0 .children-comments-wrapper {
  padding-left: 20px;
}
.review-lvl1-plus .children-comments-wrapper {
  padding-left: 0;
}
.children-comments-wrapper .review-box-wrapper {
  border-top: 0;
  padding: 25px 0 0;
}
.no-reviews {
  font-size: 15px;
  padding-top: 20px;
  border-top: 1px solid #f0f0f0;
  font-weight: 500;
}
/* end offers details page */

/* table */
/* .table-responsive{
  overflow: visible;
} */

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.overlap-action-table {
  overflow: visible;
}
.table-wrapper {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.inner-table-wrapper {
  border: 1px solid #f4f5f6;
}
.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  overflow: visible;
  word-break: normal;
}
.table-action-wrapper {
  position: relative;
  display: inline-block;
}
.table-action ul {
  padding: 0;
  list-style: none;
}
.table-action ul li:hover {
  color: #f96332;
  text-decoration: underline;
}
.table-action ul li a:hover {
  color: #f96332;
  text-decoration: underline;
}
.table-action ul li {
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 13px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.table-action ul li:last-child {
  margin-bottom: 0;
}
.table-wrapper table thead th {
  font-size: 16px;
  font-weight: 500;
}
.table-wrapper table thead tr th {
  border-bottom: 1px solid #ecf2f8;
  padding: 12px;
}
.table-wrapper table tbody tr td {
  border-bottom: 1px solid #ecf2f8;
  padding: 12px;
}

.table-wrapper .star {
  position: relative;
  display: inline-block;
  font-size: 20px !important;
  color: #d8d8d8;
  line-height: 16px;
  width: 16px;
  height: 16px;
}
.table-wrapper .full {
  color: #f96332;
}
.table-wrapper .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}
.table-action-cart i {
  font-size: 24px;
}
.table-action-cart .btn {
  padding: 0px 20px;
}
.table-action-cart .sm-link {
  font-size: 12px;
}
td.table-action-cart button {
  margin-right: 10px;
}
[aria-sort="ascending"] .mat-sort-header-arrow:before {
  content: "\F106";
  font-family: icons;
  color: #f96332;
}
[aria-sort="descending"] .mat-sort-header-arrow:before {
  content: "\F107";
  font-family: icons;
  color: #f96332;
}
.mat-sort-header-arrow:before {
  content: "\F105";
  font-family: icons;
  color: #708295 !important;
}
.mat-sort-header-container:hover .mat-sort-header-arrow::before {
  color: #f96332;
}
.mat-sort-header-arrow {
  opacity: 1 !important;
  transform: unset !important;
  height: 100% !important;
}
.mat-sort-header-arrow > div {
  display: none;
}

.table-action {
  position: absolute;
  top: -15px;
  right: 25px;
  border: 1px solid #efefef;
  padding: 15px;
  background: #fff;
  display: none;
  z-index: 1;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.toggle-is-open .table-action {
  display: block;
}
.table-action ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.table-action ul li:hover {
  color: #f96332;
  text-decoration: underline;
}
.table-action ul li {
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  cursor: pointer;
  text-align: left;
}
.table-action ul li:last-child {
  margin-bottom: 0;
}
.table-action-wrapper {
  position: relative;
  display: inline-block;
}
.table-action-wrapper:hover i:before,
.add-subcategory i:hover {
  color: #f96332;
}
.table-wrapper .mat-checkbox-layout {
  margin-bottom: 0;
}
.table-wrapper table thead th .mat-checkbox-frame {
  border: 1px solid #333 !important;
}
.table-action-wrapper i:before {
  transform: rotate(90deg);
  color: #708295;
  font-size: 18px;
  cursor: pointer;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.toggle-is-open.table-action-wrapper i:before {
  transform: rotate(0);
}
.table-action-btn {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  /* padding: 0 10px 0 0; */
}
.table-action-btn i:before {
  color: #708295;
  font-size: 18px;
  cursor: pointer;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.table-action-btn i:hover::before {
  color: #f96332;
}
/* end table */

/* form */

/* .form-control {
  background: #F0F0F0;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%;
  font-size: 13px;
  line-height: 45px;
  margin-bottom: 15px;
  display: block;
  height: 45px;
} */
.form-control {
  background: transparent !important;
  border: none;
  border-radius: 4px;
  padding: 10px 31px;
  font-size: 16px;
  line-height: 55px;
  margin-bottom: 5px;
  display: block;
  height: 55px;
  width: 123%;
  transform: scale(0.81);
  transform-origin: left top;
  border: 1px solid #d0dae4 !important;
}
.form-control:focus {
  color: #333;
  background: #f1f1f1;
  border: none;
  outline: 0;
  box-shadow: none;
}
.form-group-radio-btn {
  margin-bottom: 5px;
  display: inline-block;
}
.form-group-radio-btn label {
  padding: 0 !important;
  margin: 0 !important;
}
.form-group-radio-btn .mat-radio-label-content {
  font-weight: 400;
}
.form-group-radio-btn .mat-radio-button {
  margin-right: 15px;
}
.form-group {
  margin-bottom: 0;
}
.form-group label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.auth .form-group label {
  display: table-cell;
  height: 40px;
  vertical-align: middle;
}
.auth .form-group .textarea-control {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.textarea-control {
  resize: vertical;
  background: #f0f0f0;
  width: 100%;
  border: none;
  border-radius: 4px;
  min-height: 93px;
  padding: 10px 25px;
  font-size: 16px;
  margin-bottom: 5px;
  width: 123%;
  transform: scale(0.81);
  transform-origin: left top;
}
.textarea-control-chat {
  resize: none;
  background: #f0f0f0;
  width: 100%;
  border: none;
  border-radius: 4px;
  height: 40px;
  padding: 10px 25px;
  font-size: 13px;
  line-height: 20px;
}
.msg-input-wrapper {
  padding: 0 20px 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
}
.msg-input-wrapper textarea {
  float: left;
  width: calc(100% - 78px) !important;
}
.msg-input-wrapper .btn {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px #f1f1f1 inset;
}

/* end form */

/* nav */
.nav-item {
  color: #333 !important;
  margin: 0;
  height: auto !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 18px !important;
  padding: 30px 25px 30px 15px !important;
  border-bottom: 1px solid #f4f4f4 !important;
  cursor: pointer !important;
}
.nav-item-wrapper .mat-menu-content:not(:empty) {
  padding: 0 !important;
}
.nav-item-wrapper {
  /* padding: 10px 0; */
  background: #fff;
  /* box-shadow: 5px 5px 9px 0 rgba(0, 0, 0, 0.15) !important; */
  height: 634px !important;
  border-radius: 0 !important;
}
.nav-item-wrapper i.icon-record-outline:before {
  font-size: 22px;
  transform: translate(0, -50%);
}
.nav-item-wrapper i.icon-immigration:before {
  font-size: 20px;
}
.nav-item-wrapper i.icon-area-orientation:before {
  font-size: 22px;
}
.nav-item-wrapper i.icon-house-search:before {
  font-size: 21px;
}
.nav-item-wrapper i.icon-school-search:before {
  font-size: 20px;
}
.nav-item-wrapper i.icon-settling-in:before {
  font-size: 22px;
}
.nav-item-wrapper i.icon-credit-card:before {
  font-size: 22px;
}
.nav-item-wrapper i.icon-moving-services:before {
  font-size: 23px;
}
.nav-item-wrapper i.icon-eu-forms:before {
  font-size: 18px;
}
.nav-item-wrapper i.icon-moving:before {
  font-size: 18px;
}
.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
  background: transparent;
  text-decoration: none !important;
  color: #f96332 !important;
  transition: unset;
}
.custom-mat-menu-item:hover:not([disabled]) {
  color: #708295 !important;
}
.mat-elevation-z2.nav-item-wrapper {
  background: #f2f2f2;
}
.mat-elevation-z3.nav-item-wrapper {
  background: #f2f2f2;
}
.mat-elevation-z4.nav-item-wrapper {
  background: #fff;
}

.mat-elevation-z5.nav-item-wrapper {
  background: #f6f6f6;
}
.mat-elevation-z6.nav-item-wrapper {
  background: #f2f2f2;
}
.mat-elevation-z7.nav-item-wrapper {
  background: #efefef;
}

.nav-item.mat-menu-item-submenu-trigger::after {
  right: 12px !important;
  color: #333 !important;
}
/* end nav */

/* material overlay */
.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.custom-mat-menu-item {
  padding: 10px 15px !important;
  height: auto !important;
  line-height: normal !important;
  color: #708295 !important;
  background: #fff !important;
}
.mat-menu-item i:before {
  position: absolute;
  width: 30px;
  font-size: 24px;
  line-height: 18px;
  margin: 0;
  top: 50%;
  transform: translate(0, -60%);
}
span.nav-item-label {
  padding-left: 35px;
}
.mat-menu-panel {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2) !important;
  min-width: 215px !important;
}
.custom-mat-menu-item.cdk-keyboard-focused:not([disabled]),
.custom-mat-menu-item.cdk-program-focused:not([disabled]),
.custom-mat-menu-item:hover:not([disabled]) {
  background-color: #f0f0f0 !important;
}
.mat-menu-item {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.mat-radio-button .mat-radio-ripple {
  z-index: 1;
  pointer-events: none;
  display: none;
}
/* endmaterial overlay */

/* custom select */
.field-message {
  font-size: 9px;
  padding-left: 4px;
  color: red;
}
.customiz-select.ng-select-opened {
  z-index: 1000;
}
.material-modal-dropdown-fix .customiz-select.ng-select-opened {
  position: absolute;
  min-width: 355px;
}
.material-modal-dropdown-fix .form-group {
  height: 90px;
}
.ng-select .ng-select-container {
  min-height: 35px !important;
  transition: all 0.3s ease-out;
}
ng-select .ng-select-container .ng-value-container .ng-input {
  position: absolute;
  left: 0;
  width: 100%;
}
ng-select .ng-arrow-wrapper,
.ng-select .ng-clear-wrapper {
  z-index: 2;
}
ng-select.chips-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  position: relative !important;
  top: 0 !important;
  padding: 0 !important;
  display: block;
}
ng-select.chips-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  display: none;
  z-index: 999;
}
/* ng-select.chips-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    position: absolute;
    top: 25px;
} */
ng-select.chips-select.ng-select-multiple .ng-select-container {
  overflow: visible;
}
.customa-chips {
  background: #f96332;
  color: #fff;
  font-weight: 500;
  padding: 10px 42px 10px 10px;
  border-radius: 4px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}
.remove-chips {
  display: inline-block;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.chips-select.ng-select-opened .hide-placeholder {
  display: none !important;
}
.customiz-select.white-select {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.location-select .ng-select-container .ng-value-container,
.location-select .ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 30px !important;
  padding-top: 0 !important;
}
ng-select.location-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
ng-select.location-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 15px 0 30px !important;
}
ng-select.location-select {
  width: 190px;
}
ng-select.lang-select {
  text-transform: uppercase;
  margin-bottom: 0;
}
ng-select.lang-select .ng-select-container .ng-value-container,
ng-select.lang-select.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  padding-left: 15px;
  padding-top: 0;
}
ng-select.lang-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
ng-select.lang-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  height: 35px;
  line-height: 35px;
  padding: 0 15px 0 15px;
}
ng-select.no-clearable-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 20px 0 15px !important;
  height: 45px;
  line-height: 45px;
}
ng-select.no-clearable-select .ng-select-container .ng-value-container,
ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 0;
}
/* end custom select */

/* placeholder */
::-webkit-input-placeholder {
  color: #333;
}
::-moz-placeholder {
  color: #333;
}
:-ms-input-placeholder {
  color: #333;
}
:-moz-placeholder {
  color: #333;
}
/* end placeholder */
.footer-top {
  /* background: url('assets/images/erc/banner.jpg'); */
  background-size: cover;
  background-position: center;
  /* height: 380px; */
  height: 254px;
}
.footer-wrapper {
  background: #33312e;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.footer-top {
  position: relative;
}
.footer-top:after {
  /* background: linear-gradient(213.46deg, #51342a 0%, #272221 150%); */
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}
.footer-top-title {
  font-size: 28px;
  color: #fff;
  position: relative;
  z-index: 1;
  font-weight: 700;
  margin-bottom: 20px;
}
.footer-top-desc {
  font-size: 16px;
  color: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.footer-top .btn {
  position: relative;
  z-index: 1;
}
.homepage .footer-wrapper .footer-top {
  display: none;
}
.footer-top-content {
  /* height: 380px; */
  height: 254px;
  position: relative;
}
.footer-wrapper
  .footer-top
  .footer-top-content
  .banner-description-absolute-left {
  left: 0;
}
.footer-bottom,
.footer-bottom a {
  color: #fff;
}
.footer-bottom a:hover {
  color: #f96332 !important;
}
#footer-bottom-gold a:hover {
  color: #fab011 !important;
}
#gold-title a:hover {
  color: #fab011 !important;
}
#footer-bottom-green a:hover {
  color: #89b51a !important;
}
#green-title a:hover {
  color: #89b51a !important;
}
.router-link-active {
  color: #f96332 !important;
}
.upload-files input {
  display: none;
}
label.upload-files {
  display: inline-block;
  margin: 0;
}

.capitalize-text {
  text-transform: capitalize;
}
.w-30 {
  width: 30%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-20 {
  width: 20%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-25 {
  width: 25%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-50 {
  width: 50%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-40 {
  width: 40%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-60 {
  width: 60%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-full {
  width: 100%;
}
.w-100 {
  width: 100%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-33 {
  width: 33.3%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-70 {
  width: 70%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  /* display: inline-block;
  width: 100%; */
}
.footer-bottom ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-bottom-title {
  font-size: 1em;
  font-weight: 800;
  margin-bottom: 20px;
}
.footer-bottom ul li {
  margin-bottom: 10px;
}
.footer-bottom-list ul li a {
  display: inline-block;
  font-size: 1em;
}
.footer-bottom ul li i {
  margin-right: 5px;
  display: inline-block;
  width: 18px;
}
.footer-control-form {
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 5px 20px;
  font-size: 13px;
  line-height: 40px;
  height: 40px;
}
.footer-control-form::-webkit-input-placeholder {
  color: #fff;
}
.footer-control-form::-moz-placeholder {
  color: #fff;
}
.footer-control-form:-ms-input-placeholder {
  color: #fff;
}
.footer-control-form:-moz-placeholder {
  color: #fff;
}
.footer-bottom-action > * {
  display: inline-block;
  margin-bottom: 15px;
}
.footer-bottom {
  padding: 75px 0;
}
@media only screen and (max-width: 1024px) {
  .footer-last-list {
    margin-left: 10px !important;
  }
}
@media only screen and (max-width: 1440px) {
  .footer-bottom {
    padding: 10px !important;
  }
}
@media only screen and (max-width: 600px) {
  .footer-bottom-title {
    margin-top: 10px;
  }
}
.footer-copy-right {
  font-size: 12px;
  color: rgb(255, 255, 255) !important;
  /* opacity: 0.5; */
  padding: 10px 20px 0px 20px;
  display: inline-block;
  width: 100%;
}
.footer-copy-right a {
  color: #fff !important;
}
.footer-copy-right a:hover {
  color: #f96332 !important;
}
.footer-bottom ul li:last-child {
  margin-bottom: 0;
}
.footer-bottom-action {
  margin: 30px 0;
  display: flex;
  align-items: center;
}
.footer-bottom-action button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}
.footer-bottom-action input {
  /* background-color: #ffffff;
  color: #030303 !important;
  font-weight: 500;
  font-size: 1em;
  line-height: 1em; */
  font-family: "Lato", Sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-transform: none;
  line-height: 1em;
  letter-spacing: 0px;
  color: #ff521d;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #dddce2;
  border-radius: 10px 10px 10px 10px;
  padding: 25px;
}
.footer-bottom-action input::placeholder {
  color: lightslategray;
  font-size: 1em;
  font-weight: 500;
}
.footer-bottom-list .c-a-i {
  display: inline-block;
  vertical-align: top;
}
.left-side {
  float: left;
  width: 250px;
}
.filter-box {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}
.disable-filter-box {
  background: rgb(255 255 255 / 0.5);
  pointer-events: none;
}
.filter-title {
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  display: flex;
}
.filter-title-action {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 35px 15px 15px;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  cursor: pointer;
}
.filter-title-link {
  cursor: pointer;
}
.filter-title-link:hover i {
  color: #f96332;
}
.filter-title-action i {
  position: absolute;
  right: 5px;
  font-size: 24px;
  top: 50%;
  transform: translate(0, -50%);
}
.filter-title-action.hide i:before {
  content: "\f107";
}
.filter-action {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
}

.range-filters-unique {
  padding: 15px;
  font-size: 14px;
  background: #eaeaea;
}

.filter-content-row:hover span.filter-content-row-label {
  color: #db5527;
}
.rating-filter-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
/* .filter-content-row:last-child {
  margin-bottom: 0;
} */
.filter-content-row label {
  margin-bottom: 0;
}
.filter-content-row .mat-checkbox-layout .mat-checkbox-label {
  line-height: 16px;
}
.filter-action:hover,
.filter-title-action:hover i {
  color: #f96332;
}
.filter-box-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
}
.filter-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.extra-wrapper-li {
  position: relative;
}
/* .filter-list ul .extra-wrapper-li:last-child li {
  padding-bottom: 0;
} */
.filter-list ul li,
.filter-list ul a {
  padding: 0 0 15px 0;
  display: flex;
  color: #708295;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-list ul li:hover {
  color: #f96332;
}
.filter-list ul li.active-filter-list,
.filter-list ul a.active-filter-list {
  color: #333;
  font-weight: 500;
  display: block;
}
.filter-list ul li.active-filter-list:before,
.filter-list ul a.active-filter-list:before {
  content: "";
  width: 4px;
  height: 30px;
  background: #f96332;
  display: block;
  position: absolute;
  top: -3px;
  left: -15px;
}
.filter-content-wrapper {
  padding: 15px;
  color: #708295;
  position: relative;
  max-height: 380px;
  overflow-x: hidden;
  overflow-y: auto;
}
.radio-clear {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  font-size: 16px;
}
.radio-clear:hover {
  color: #f96332;
}
.filter-count {
  position: absolute;
  margin-left: 5px;
}
span.filter-content-row-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 172px;
  float: left;
  margin-right: 5px;
}
/* .filter-box .search-wrapper input {
  background: #F0F0F0;
  border: none;
  padding: 8px 16px;
  width: 100%;
  border-radius: 3px;
  color: #708295;
} */
.filter-box .search-box {
  margin-bottom: 15px;
}
.filter-box .datepicker {
  display: flex;
  margin: 10px 0px;
  color: #708295;
  background: #f0f0f0;
  border: none;
  width: 100%;
  border-radius: 3px;
}
.filter-box .datepicker input {
  background: #f0f0f0;
  border: none;
  padding: 8px 16px;
  width: 100%;
  border-radius: 3px;
  color: #708295;
}
.filter-box .datepicker-toggle {
  width: 25%;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #708295;
}
.filter-title-action.hide + .filter-content-wrapper {
  display: none;
}
.filter-list-children {
  padding: 0;
  margin: 0;
  list-style: none;
}
.filter-list-children li,
.filter-list-children a {
  display: block;
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: unset !important;
  padding: 10px 0 0 15px !important;
}
.filter-list-children li:hover {
  color: #f96332;
}
.wrapper-fix {
  padding: 50px 0;
  display: inline-block;
  width: 100%;
  margin-top: 145px;
  min-height: 30vh;
}
.inner-section {
  margin: 45px 0;
}
.counter {
  font-size: 14px;
  color: #708295;
  font-weight: 400;
  white-space: nowrap;
}
.inner-counter {
  color: #708295;
  font-weight: 500;
  font-size: 16px;
  height: 45px;
  line-height: 45px;
}
.info-card {
  background: #f96332;
  color: #ffffff;
  padding: 15px 20px;
  border-radius: 4px;
  /* box-shadow: 0 0 8px 0 rgba(249, 99, 50, 0.32),
    1px 1px 12px 0 rgba(0, 0, 0, 0.03); */
}
.info-card-over-absoulte {
  position: relative;
  z-index: 99;
}
.lor-card-details {
  margin-bottom: 13px;
  margin-top: -5px;
  text-align: center;
}

.lor-card {
  margin-bottom: 20px;
}
.info-card span {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}
.content-top-filters {
  float: right;
}
.content-top-wrapper {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
.content-top-filters .select-wrapper {
  float: left;
  margin-left: 15px;
}
.card-box-img img {
  width: 100%;
}
.right-side {
  float: left;
  padding-left: 40px;
  width: calc(100% - 250px);
}
.select-wrapper select {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 15px;
  border: none;
  border-radius: 4px;
}
.main-title {
  font-size: 28px;
  font-weight: 700;
  float: left;
  line-height: 28px;
  margin-bottom: 15px;
}
.cards-section {
  margin: 0 -15px;
}
.card-wrapper {
  float: left;
  padding: 0 15px;
  width: 370px;
}

.card-box {
  margin: 0 0 30px 0;
  position: relative;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
}
.card-wrapper-sg .card-box {
  margin: 0;
}
.card-img img {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 214px;
  object-fit: cover;
}
.favorite-icon {
  position: absolute;
  right: 20px;
  top: 15px;
  background: transparent;
  border: none;
  mat-icon {
    width: 32px;
    height: 32px;
  }
}
.heart-border {
  color: #f96332;
  cursor: pointer;
  position: absolute;
  font-size: 34px;
}
.full {
  color: #f96332;
  font-size: 36px;
}
.empty {
  color: transparent;
  font-size: 36px;
}
.card-content {
  padding: 15px;
  color: #708295;
}
.card-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  line-height: 21px;
  display: block;
}
.content-left-side .card-wrapper .card-title {
  height: auto;
}
.full-title {
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
  line-height: initial;
}
.card-content .star {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: #d8d8d8;
  width: 20px;
  height: 18px;
  line-height: 18px;
}
.card-content .full {
  color: #f96332;
}
.card-content .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}
.card-rating app-rating {
  display: inline-block;
}
.card-rating .review-nr {
  margin: 0 5px;
}
.card-rating {
  margin-bottom: 15px;
}
.card-content .material-icons {
  font-size: 14px;
  line-height: inherit;
}
.card-details-row i:before {
  padding: 0;
  margin: 0;
  font-size: 14px;
  width: 20px;
  margin-right: 5px;
}
.card-details-row {
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-details-locations {
  margin-left: 5px;
}
.card-details-locations span {
  display: block;
  margin-bottom: 10px;
}
.provider-card-details-row {
  line-height: 14px;
  margin-bottom: 15px;
  display: flex;
  align-items: normal;
}
.card-details-row-enum {
  margin-bottom: 15px;
  /* line-height: 14px; */
}
.card-details-row-enum span {
  display: block;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-details .card-details-row-enum .icon-location {
  width: 14px !important;
  display: block;
  float: left;
}
/* .card-details-row:last-child {
  margin-bottom: 0;
} */
.card-price-wrapper {
  border-top: 2px solid #eaeaea;
  margin: 15px -15px 0;
  padding: 15px 15px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-price {
  color: #333;
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.old-price {
  color: #f96332;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 10px;
}
.discount {
  color: #f96332;
  font-weight: 500;
  margin-right: 10px;
}
.card-price {
  float: left;
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.card-old-price {
  font-size: 13px;
}
.card-cart {
  float: right;
}
.card-cart .btn {
  padding: 0 20px;
}
.card-cart i {
  font-size: 24px;
}
.card-rfq {
  font-size: 12px;
}

.billing-trash {
  margin-right: -30px;
  margin-left: 10px;
}

.load-more {
  padding: 0 15px;
  margin: 10px 0;
  text-align: center;
}
.h50 {
  height: 50%;
}

.h75 {
  height: 75%;
}

.h100 {
  height: 100%;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 40%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w100 {
  width: 100%;
}

.fx-space {
  display: flex;
  justify-content: space-between;
  padding-right: 25px !important;
  padding-left: 25px !important;
  margin-bottom: 20px;
  margin-top: 20px;
}
.fx-space-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fx-space-center .btn {
  padding: 5px 10px;
}

.fx {
  display: flex;
}

.dashboard-wrapper {
  height: 100%;
  padding: 0 0 0 250px;
}
.dashboard-body {
  padding: 30px 45px;
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 81px;
}
.dashboard-body-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  height: 41px;
}
.dashboard-body-top .btn {
  margin-left: 15px;
  transition: all 0.3s ease-out;
}
.dashboard-body-top .big-title {
  transition: all 0.3s ease-out;
}
.dashboard-body-action {
  display: inline-block;
  width: 100%;
  height: auto;
}
.dashboard-body-action .btn {
  margin-left: 15px;
  transition: all 0.3s ease-out;
}
.big-title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  width: 35%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 49px;
}
.big-title i {
  font-size: 20px;
  vertical-align: middle;
  cursor: pointer;
}
.big-title i:hover {
  color: #f96332;
}
.select-group-block label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.select-group-inline label {
  font-weight: 500;
  margin: 0 10px;
  display: inline-block;
}
/* loader */
.loader-wrapper {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader-wrapper img {
  width: 70px;
  height: auto;
}
.extra-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10005;
  top: 0;
  left: 0;
}
/* end loader */

/* notification banner */
.notification-banner {
  padding: 15px 15px 15px 50px;
  background: #f96332;
  margin-bottom: 15px;
  color: #fff;
  border-radius: 4px;
  position: relative;
  font-size: 16px;
}

/* notification banner */
.notification-banner-not-approved {
  padding: 15px 15px 15px 50px;
  background: #fab011;
  margin-bottom: 15px;
  color: #fff;
  border-radius: 4px;
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.notification-banner-not-approved mat-icon {
  margin-right: 10px;
}

.notification-banner:before {
  content: "\e854";
  font-family: icons;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(15px, -50%);
  font-size: 24px;
}
.notification-banner a {
  color: #fff;
  text-decoration: underline;
}
/* end notification banner */

/* main navigation */
.main-navigation-wrapper {
  font-size: 16px;
}
.main-navigation-lvl2 {
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 12px;
  z-index: 10;
  color: #333;
  display: flex;
}
.main-navigation-lvl3 {
  color: #717171;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
  padding-left: 15px;
  display: flex;
  color: #333;
}
.main-navigation-group {
  margin-bottom: 50px;
  padding: 0 45px;
}

.main-navigation-lvl1 {
  cursor: pointer;
  position: relative;
  /* padding: 25px 0; */
  border-bottom: 2px solid #f4f4f4;
  border-right: 2px solid #f4f4f4;
  height: 110px;
  color: #333;
  display: flex;
}
.main-navigation-left .main-navigation-lvl1:last-child {
  /* border: none; */
}
.activeElem::before {
  content: "\e80c";
  color: #f96332;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-family: "icons";
  font-size: 20px;
}
.main-navigation-lvl1:last-child {
  border-bottom-left-radius: 6px;
}
.activeElem .lvl1-label,
.activeElem i,
.main-navigation-lvl2:hover,
.main-navigation-lvl3:hover {
  color: #f96332;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.main-navigation-left {
  width: 290px;
  background: #fff;
  float: left;
  border-bottom-left-radius: 6px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.09);
}
.main-navigation-right {
  width: calc(100% - 290px);
  float: left;
  background: #fafafa;
  column-count: 3;
  column-gap: 0;
  column-fill: auto;
  height: 440px;
  padding: 25px 0;
  border-bottom-right-radius: 6px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.09);
}
.main-navigation-wrapper {
  display: inline-block;
  width: 1370px;
  height: 440px;
  position: absolute;
  top: 150px;
  z-index: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  word-break: normal !important;
}
.small-fixed-header-market .main-navigation-wrapper {
  top: 60px;
}
span.lvl1-label {
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 110px;
  padding: 0 80px;
  /* margin-bottom: 15px; */
}
span.lvl1-count {
  font-size: 16px;
  font-weight: 600;
  display: block;
  padding: 0 80px;
}
.main-navigation-lvl1 .menu-main-categ-icon:before {
  position: absolute;
  width: 28px;
  font-size: 28px;
  line-height: 28px;
  margin: 0;
  top: 50%;
  left: 25px;
  transform: translate(0, -50%);
}
.main-navigation-lvl1 i.icon-immigration:before {
  font-size: 24px;
}
.main-navigation-lvl1 i.icon-moving-services:before {
  font-size: 26px;
}
/* end main navigation */

/* left navigation */
.left-navigation-wrapper {
  width: 250px;
  background: #fff;
  position: fixed;
  height: 100%;
  left: 0;
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.left-navigation-wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.active-nav,
.left-navigation-wrapper ul li:hover {
  background: #f6f6f6;
  position: relative;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.active-nav i,
.left-navigation-wrapper ul li:hover i {
  color: #f96332;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.active-nav:before,
.left-navigation-wrapper ul li:hover::before {
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f96332;
  content: "";
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.left-navigation-wrapper ul li {
  padding: 15px 0;
  cursor: pointer;
  padding-left: 40px;
  font-weight: 500;
}
.left-navigation-wrapper ul li a {
  position: relative;
  padding-left: 35px;
  display: block;
  color: #333;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.left-navigation-wrapper ul li a i,
.left-navigation-wrapper ul li a mat-icon {
  padding: 0 10px 0 0;
  font-size: 18px;
  width: 34px;
  position: absolute;
  top: -2px;
  left: 0px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.btn-wrapper {
  text-align: center;
}
.btn-wrapper a {
  display: inline-block;
}
.left-navigation-wrapper .btn-wrapper {
  margin-bottom: 35px;
}
.has-sub-nav a:after {
  content: "\F105";
  font-family: icons;
  position: absolute;
  right: 15px;
  top: -1px;
  font-weight: 700;
  font-size: 16px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.left-navigation-wrapper .logo {
  width: 152px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 16px;
  padding-bottom: 16px;
}
.left-navigation-wrapper .logo img {
  width: 100%;
}
/* .has-sub-nav.active-nav a:after {
    color: #F96332;
    content: '\F107';
} */
.sub-is-open a:after {
  color: #f96332;
  content: "\F107";
}
.has-sub-nav:hover a::after {
  color: #f96332;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.sub-navigation {
  background: #f6f6f6;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1) -0.1s;

  overflow: hidden;
}
.sub-navigation .active-sub-nav {
  color: #f96332 !important;
}
.sub-is-open + .open-sub-navigation {
  max-height: 1500px;
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
  transition-delay: 0s;
}

.sub-navigation ul li a {
  color: rgba(59, 57, 53, 0.3);
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.sub-navigation ul li .active-sub-nav,
.sub-navigation ul li a {
  color: #333;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

/* end left navigation */

/* portal */
.portal-details-navigation {
  display: inline-block;
  width: 100%;
  height: 41px;
  /* padding-bottom: 30px; */
}
.portal-details-wrapper .mat-tab-nav-bar {
  border-bottom: none;
}
a.mat-tab-link.mat-tab-label-active {
  color: #f96332;
}
.portal-details-wrapper .mat-tab-link {
  height: auto;
  padding: 10px;
  width: auto;
  min-width: auto;
  opacity: 1;
  margin-left: 25px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.portal-details-wrapper .mat-tab-link:hover {
  color: #f96332;
}
.portal-2c-left {
  float: left;
  width: 320px;
}
.portal-2c-right {
  float: left;
  width: calc(100% - 340px);
  margin-left: 20px;
}
.white-box-img img {
  width: 100%;
  padding: 15px;
}
.white-box-img {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
}
.box-big-title {
  font-size: 22px;
  font-weight: 700;
}
.white-box-img-header {
  margin-bottom: 15px;
  padding: 0 30px;
}
.box-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.box-list ul li {
  border-top: 1px solid #f0f0f0;
  padding: 15px 30px;
  cursor: pointer;
}
.box-list ul li:hover,
.white-box-header-action:hover,
.remove-box-item:hover,
.back-arrow:hover {
  color: #f96332;
}
.box-list ul li i {
  margin-right: 10px;
  font-size: 16px;
  line-height: 16px;
}
.white-box-img .status-label {
  position: absolute;
  top: 15px;
  left: 20px;
  text-transform: capitalize;
}
.white-box-header-action {
  font-weight: 500;
  cursor: pointer;
  line-height: 18px;
}
.white-box-inner-header {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  position: relative;
}
.white-box-inner-header .status-label {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-10px, -50%);
  text-transform: capitalize;
}
.white-box-innter-action span {
  margin-left: 10px;
}
.inserted-white-box {
  display: inline-block;
  width: 100%;
  border-bottom: 1px dashed #efefef;
  padding-bottom: 15px;
}
.white-box-inner-body {
  padding: 15px 0;
}
.white-box-inner-body p {
  margin-bottom: 10px;
}
/* .back-arrow {
  cursor: pointer;
  color: #f96332;
} */
.order-details-box-top {
  position: relative;
}

.order-details-box-left img {
  width: 120px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
}
.portal-order-details-box {
  border-bottom: 1px solid #eaeaea;
  padding: 15px 0;
}
.portal-order-details-box:first-child {
  padding-top: 0;
}
.order-details-box-left img {
  float: left;
  margin-right: 15px;
}
.order-details-box-left {
  float: left;
}
.order-details-box-left-header {
  margin-bottom: 15px;
}
.o-d-b-l-h-link {
  margin-top: -10px;
  margin-bottom: 10px;
}

.order-details-box-left-header a {
  text-decoration: none;
  color: #333;
}
/* .order-details-box-left-body {
  margin-bottom: 15px;
} */

.details-2c {
  display: grid;
  align-items: flex-start;
  gap: 24px;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 768px) {
  .details-2c {
    grid-template-columns: min(25%, 340px) auto;
  }
}
.small-box {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
}
.small-box-top-action {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background: #fff;
  margin-bottom: -5px;
  border-bottom: 2px solid #f0f0f0;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.small-box-header-img {
  padding: 15px 15px 0;
}
.small-box-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 20px;
}
.small-box img {
  width: 100%;
  border-radius: 4px;
  height: 214px;
  -o-object-fit: cover;
  object-fit: cover;
}
.small-box-details {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.small-box-details div {
  /* white-space: nowrap;
  text-overflow: ellipsis; */
  margin: 10px 0;
}
.small-box-details div:last-child {
  margin-bottom: 0;
}
.small-box-details div:first-child {
  margin-top: 0;
}
.small-box-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.small-box-list ul li {
  border-bottom: 1px solid #f0f0f0;
  padding: 15px 20px;
  /* cursor: pointer; */
  font-weight: 500;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.small-box-list ul li:hover {
  color: #f96332;
}
.small-box-status {
  /* position: absolute;
  top: 20px;
  left: 20px; */
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px 0;
}

.small-box-star .star {
  position: relative;
  display: inline-block;
  font-size: 20px !important;
  color: #d8d8d8;
  line-height: 16px;
  width: 16px;
  height: 16px;
}
.small-box-star .full {
  color: #f96332;
}
.expat-overview-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.small-box-star .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}
.small-box-status-rigth {
  /* position: absolute;
  top: 15px;
  right: 10px;
  */
  font-size: 12px;
  margin: 15px;
}
.small-box-subtitle {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px !important;
  color: #708295 !important;
}
.white-box-wrapper {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 0;
  padding: 25px;
}
.service-offer-footer .btn {
  margin-left: 15px;
}
.white-box-body {
  font-size: 14px;
  padding: 25px 0;
}
/* .white-box-wrapper .white-box-row:last-child .white-box-body {
  padding-bottom: 0;
} */
.white-box-body p {
  margin-bottom: 10px;
}
.white-box-body p span {
  font-weight: 600;
  margin-right: 5px;
  display: block;
}

.white-box-body p a {
  color: #333;
}

.white-box-body p a:hover {
  color: #db5527;
  text-decoration: underline;
}

.white-box-header-action {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.white-box-header-action:hover {
  color: #f96332;
}
.white-box-header {
  border-bottom: 1px solid #efefef;
  display: inline-block;
  width: 100%;
  padding: 0 0 20px 0;
}
.white-box-header.dark-border {
  border-color: #708295;
}
.details-navigation,
.details-body-wrapper,
.portal-2c {
  display: inline-block;
  width: 100%;
}
.order-details-box-link ul {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
}
.order-details-box-link ul li {
  display: block;
  cursor: pointer;
  font-size: 14px;
  color: #f96332;
  cursor: pointer;
  margin-bottom: 5px;
}
.order-details-box-link ul li:hover {
  text-decoration: underline;
}
.order-details-box-wrapper {
  /* border-bottom: 1px solid #eaeaea; */
  font-size: 14px;
  margin-bottom: 20px;
  /* padding-bottom: 20px; */
}

.order-details-box-left img {
  float: left;
  margin-right: 15px;
  border-radius: 4px;
}
.order-details-box-right-wrapper {
  float: right;
  /* margin-top: 35px; */
}
.order-details-left-content {
  width: calc(100% - 135px);
}
.order-details-box-wrapper .order-details-box-left {
  width: 70%;
}
.order-details-box-wrapper .order-details-box-right-wrapper {
  width: 30%;
}
.order-details-box-left {
  float: left;
}
.order-details-box-bottom {
  padding: 0;
  font-size: 0;
}

.box-bottom-assign-expat {
  margin-top: 10px;
}

.order-details-box-right {
  text-align: right;
}

.expat-row {
  display: inline-block;
  width: 100%;
  border-top: 1px dashed #eaeaea;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}
.expat-row-info-icon {
  line-height: 30px;
}
.expat-row i {
  width: 11px;
  display: inline-block;
}
.expat-buttons-item i {
  width: auto;
}
.expat-row-info .calendar-input-control {
  padding: 5px;
  height: 30px;
  line-height: 30px;
}
.expat-row-info app-date-range-calendar {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.expat-row-info .toggle-calendar-icon {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  width: 30px;
}
.expat-row-info {
  padding-left: 15px;
  margin-top: 5px;
  line-height: 30px;
  height: 30px;
}
.expat-row-title {
  color: #f96332;
  margin-top: 10px;
  font-weight: 500;
  padding-bottom: 5px;
  font-size: 14px;
}
.expat-buttons-item {
  cursor: pointer;
  float: left;
  color: #cacaca;
  font-size: 18px;
  margin-left: 15px;
}
.expat-buttons-item:hover {
  color: #f96332;
}

.small-box-header-img:hover {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-trigger {
  position: absolute;
  font-size: 18px;
  font-weight: 800;
  visibility: hidden;
  cursor: pointer;
}

.small-box-header-img:hover .profile-pic {
  opacity: 0.5;
  cursor: pointer;
}

.small-box-header-img:hover .edit-trigger {
  visibility: visible;
}

.orange-border {
  border: 1px solid #f96332;
  padding: 5px 9px;
}
.white-box-footer {
  display: inline-block;
  width: 100%;
  /* padding: 25px 0 0; */
  border-top: 1px dashed #f0f0f0;
  padding-top: 15px;
}
.order-details-box-top {
  position: relative;
  padding: 0;
}
.remove-box-item {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #708295;
  font-size: 18px;
}
/* end details page */

/* upload photos*/

.uplaod-photo-box {
  height: 130px;
  width: 130px;
  border: 1px dashed #708295;
  border-radius: 4px;
  float: left;
}
.uplaod-photo-box img {
  object-fit: cover;
  width: 130px;
  height: 130px;
  padding: 10px;
}
.upload-photo-wrapper .upload-files {
  height: 130px;
  margin: 0;
  line-height: 130px;
  float: left;
  margin-left: 15px;
}
/* end upload photos*/

/* details navigation */
.details-navigation .mat-tab-nav-bar {
  border-bottom: 0;
}
.details-navigation .mat-tab-link {
  height: auto;
  padding: 10px;
  width: auto;
  min-width: auto;
  opacity: 1;
  margin-left: 20px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  color: #333;
}
.details-navigation .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f96332;
  height: 4px;
}
.details-navigation .mat-tab-link:hover {
  color: #f96332;
}
/* end portal */

/* modal */
.modal-title {
  font-size: 26px;
  font-weight: 700;
  float: left;
  text-transform: capitalize;
}
.modal-step-wrapper {
  float: right;
  margin-top: 20px;
}
.modal-step-wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.modal-step-wrapper ul li {
  display: inline-block;
}
.modal-step-wrapper ul li.active-step {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  margin-left: 25px;
  position: relative;
  color: #333;
}
.modal-step-wrapper ul li {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  margin-left: 25px;
  position: relative;
  color: #e1e1e1;
}
.modal-step-wrapper ul li.active-step:before {
  content: "";
  background: #f96332;
  height: 5px;
  position: absolute;
  width: 100%;
  bottom: -17px;
}
.modal-title-wrapper {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.form-control-modal {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e5eaee;
  width: 100%;
  font-size: 15px;
  height: 40px;
  padding: 10px 25px;
}
.custom-modal-title-wrapper {
  border-bottom: 1px solid #e5eaee;
  padding-bottom: 30px;
  display: inline-block;
  width: 100%;
}
.custom-modal-title {
  color: #00579f;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}
.modal-footer {
  /* display: inline-block;
    width: 100%; */
  display: flex;
  justify-content: flex-end !important;
  padding: 0;
  border: none;
}
.modal-body-desc {
  color: #708295;
  font-size: 14px;
  margin: 0 0 30px 0;
}

.modal-footer-l {
  float: left;
}
.modal-footer-r button {
  width: 130px;
  margin-left: 20px;
}
.modal-footer-r {
  float: right;
}
.modal-footer button {
  width: 130px;
  margin-left: 20px !important;
}
.modal-wrapper {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}
body.dialog-open {
  overflow: hidden;
}
body.dialog-open ngx-smart-modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  /* pointer-events: none; */
}

.nsm-dialog-btn-close {
  display: none;
  border: 0;
  background: none;
  color: #2d2d2d;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 0.5s;
  background-color: transparent;
  z-index: 999 !important;
}
.overlay.nsm-overlay-open {
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay.nsm-overlay-close,
.overlay.transparent {
  background-color: transparent;
}

.nsm-dialog {
  position: absolute;
}
.nsm-dialog:not(.nsm-dialog-open),
.nsm-dialog:not(.nsm-dialog-close) {
  opacity: 0;
  min-height: 0;
  width: 0;
  max-width: 0;
  visibility: hidden;
  overflow: hidden;
}
.nsm-dialog.nsm-dialog-open {
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 90%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 4px;
  right: 0;
  left: 0;
  top: 10%;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  pointer-events: auto;
  overflow: visible;
}
#editRFQAndBid .nsm-dialog.nsm-dialog-open,
#addRFQAndBid .nsm-dialog.nsm-dialog-open {
  max-height: 80vh;
  overflow: auto;
}
#cardModal .nsm-dialog.nsm-dialog-open,
#makePayment .nsm-dialog.nsm-dialog-open {
  max-width: 800px;
}

/* #addRFQAndBid .nsm-dialog.nsm-dialog-open,
#editRFQAndBid .nsm-dialog.nsm-dialog-open{
  max-width: 90rem;
} */

//.mat-mdc-dialog-container {
//  background: #fff;
//  color: #333;
//  border-radius: 4px !important;
//  padding: 0 !important;
//  max-width: 800px;
//  width: 800px !important;
//  margin: 50px 0;
//  min-height: 200px !important;
//  max-height: 90vh !important;
//  overflow: auto !important;
//}

mat-dialog-container .custom-modal-wrapper {
  padding: 30px;
}
.custom-modal-wrapper .modal-title-custom {
  font-size: 26px !important;
  font-weight: 700 !important;
  border-bottom: 1px solid #d0dae4;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
  line-height: 24px;
}

.custom-modal-wrapper .modal-body-custom {
  margin: 30px 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  mat-form-field {
    margin-top: 10px;
    mat-label {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.mdc-notched-outline__notch {
  border-right: 0 !important;
}

select.modal-select {
  border-radius: 4px;
  padding: 8px;
  border: none;
  background: #f0f0f0;
  color: #708295;
}
.custom-modal-wrapper .modal-footer-custom button {
  min-width: 130px;
  margin-left: 20px !important;
}
.custom-modal-wrapper .modal-footer-custom {
  display: flex;
  justify-content: flex-end !important;
  padding-top: 15px;
  border-top: 1px solid #d0dae4;
}
.custom-modal-wrapper .row:last-child .form-control {
  margin-bottom: 0;
}
.select-input-row .customiz-select {
  width: 135px;
  float: left;
  margin-right: 10px;
}
.select-input-row input {
  width: calc(100% - 145px);
}
.modal-txt {
  margin-bottom: 15px;
}
.custom-modal-wrapper .small-box,
.custom-modal-wrapper .white-box-wrapper {
  background: transparent;
  box-shadow: none;
}

.nsm-dialog.nsm-dialog-close {
  opacity: 0;
}

/* *************************
  * Animations
  * *************************/
.nsm-dialog-animation-fade {
  transition: opacity 100ms;
}

.nsm-dialog-animation-ltr {
  left: -50%;
  transition: left 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-ltr.nsm-dialog-open {
  left: 0;
}
.nsm-dialog-animation-ltr.nsm-dialog-close {
  left: -50%;
}

.nsm-dialog-animation-rtl {
  right: -50%;
  transition: right 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-rtl.nsm-dialog-open {
  right: 0;
}
.nsm-dialog-animation-rtl.nsm-dialog-close {
  right: -50%;
}

.nsm-dialog-animation-ttb {
  top: -50%;
  transition: top 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-ttb.nsm-dialog-open {
  top: 5%;
}
.nsm-dialog-animation-ttb.nsm-dialog-close {
  top: -50%;
}

.nsm-dialog-animation-btt {
  top: 100%;
  transition: top 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-btt.nsm-dialog-open {
  top: 5%;
}
.nsm-dialog-animation-btt.nsm-dialog-close {
  top: 100%;
}

.nsm-dialog[class*="nsm-dialog-animation-"] {
  transition: opacity 0.3s, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s;
  transition: transform 0.3s ease-in-out, opacity 0.3s,
    -webkit-transform 0.3s ease-in-out;
}
.custom-modal-body {
  display: inline-block;
  width: 100%;
  margin: 30px 0;
}
.modal-body-enum p {
  margin-bottom: 10px;
}
.modal-body-enum p span {
  font-weight: 600;
  margin-right: 5px;
  display: block;
}
.modal-body-custom .details-body-wrapper,
.modal-body-custom .portal-2c,
.modal-body-custom .white-box-wrapper {
  padding: 0;
}
.modal-body-custom .small-box-header-img {
  padding-top: 0;
  padding-left: 0;
}
.modal-body-custom .small-box-details {
  padding-left: 0;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.form-group-title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0 15px 0;
}
.modal-other-info-list {
  margin-bottom: 15px;
  white-space: pre-line;
}
.modal-other-info-list p {
  margin-bottom: 5px;
}
/* end modal */
/* custom upload box */
.custom-upload-box file-upload {
  overflow: hidden;
  display: block;
  background: #f0f0f0;
  padding: 15px;
  min-height: 140px;
  border: 1px dashed #708295;
  position: relative;
  border-radius: 4px;
  outline: none;
  outline-offset: 0;
}
.custom-upload-box file-upload .upload-input {
  color: #708295;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: 0;
}
.custom-upload-box .upload-input span {
  display: block;
  text-align: center;
}

.custom-upload-box .upload-input span i {
  font-size: 18px;
}
.custom-upload-box file-upload .upload-input:hover {
  color: #708295 !important;
}
.custom-upload-box file-upload .upload-input:hover .orange {
  text-decoration: underline;
}
.custom-upload-box file-upload.dragover .upload-input {
  color: #f96332 !important;
}
.custom-upload-box file-upload.dragover {
  border: 1px dashed #f96332 !important;
}
.custom-upload-box file-upload.list-visible .upload-input {
  display: none;
}
.custom-upload-box file-upload .file-info {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.custom-upload-box file-upload .file-info span {
  display: block;
  text-align: center;
  margin: 5px 0;
}
.custom-upload-box file-upload .file-info .remove-file {
  cursor: pointer;
  color: #f96332;
}
/* end custom upload box */
/* alert-notific */
.notification-wrapper {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 35px;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -ms-transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  z-index: 9999;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  /* -webkit-box-shadow: 1px 1px 12px 0 rgba(0,0,0,0.2);
    box-shadow: 1px 1px 12px 0 rgba(0,0,0,0.2); */
}
.notification-open {
  -ms-transform: translate(-50.1%, 0);
  -webkit-transform: translate(-50.1%, 0);
  transform: translate(-50.1%, 0);
}
.success-notification {
  background: #89b51a;
  color: #fff;
}
.alert-wrapper {
  z-index: 1000;
  padding: 10px 30px;
  font-weight: 500;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  top: 0;
  text-align: left;
  background: #fcfcff;
  -ms-transition: all 0.25s ease-in-out;
  -ms-transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 9999;
}
.notification-error-wrapper {
  z-index: 1000;
  padding: 10px 30px;
  font-weight: 500;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  top: 0;
  text-align: left;
  background: #fcfcff;
  -ms-transition: all 0.25s ease-in-out;
  -ms-transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 9999;
}
.alert-wrapper.open-errors,
.notification-wrapper.notificatio-open,
.notification-error-wrapper.open-errors {
  -webkit-box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2);
}
.open-errors {
  -ms-transform: translate(-50.1%, 0);
  -webkit-transform: translate(-50.1%, 0);
  transform: translate(-50.1%, 0);
}
.error-description {
  padding: 3px 5px;
  position: relative;
}
.notification-error-wrapper .error-description:before {
  content: "\E854";
  color: #f96332;
  font-family: icons;
  position: absolute;
  left: -15px;
  top: 2px;
  font-size: 20px;
}
.notification-error-wrapper .error-description {
  padding: 3px 5px 3px 15px;
  position: relative;
}
.error-description:before {
  content: "\E84D";
  color: #f96332;
  font-family: icons;
  position: absolute;
  left: -15px;
  top: 2px;
}
.alert-wrapper button,
.notification-error-wrapper button {
  margin-top: 5px;
}
/* end alert-notific */

/* pagination */
.paginator {
  text-align: center;
  padding: 15px 0 20px;
}
.paginator .ngx-pagination a,
.ngx-pagination button {
  color: #e1e1e1;
  font-weight: 500;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.paginator .ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: transparent;
}
.paginator .ngx-pagination a:hover,
.ngx-pagination button:hover {
  color: #f96332;
}
.paginator .ngx-pagination .current {
  color: #f96332;
  background: transparent;
  font-weight: 500;
}

.paginator .ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\E80B" !important;
  display: inline-block;
  font-family: "icons";
  font-size: 16px;
  position: relative;
  top: 0;
}
.paginator .ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\E80C" !important;
  display: inline-block;
  font-family: "icons";
  font-size: 16px;
  position: relative;
  top: 0;
}
.paginator .ngx-pagination .disabled {
  color: #e1e1e1;
  font-weight: 500;
}
.paginator ul.ngx-pagination {
  font-size: 16px;
  margin-bottom: 0 !important;
  padding: 0;
}

.rfq-paginator ul.ngx-pagination {
  font-size: 20px !important;
}

.paginator .ngx-pagination {
  margin: 0 !important;
}
/* end pagination */

/* bulk action */
.bulk-action-wrapper {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 15px 30px;
  margin-bottom: 5px;
  position: relative;
  transition: all 0.3s ease-out;
}
.bulk-action-body,
.bulk-action-btn,
.bulk-action-btn div {
  display: inline-block;
}
.bulk-action-close {
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  font-size: 16px;
  color: #708295;
  transform: translate(0, -50%);
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.bulk-action-close:hover {
  color: #f96332;
}
.bulk-action-body {
  color: #708295;
  font-weight: 500;
  margin-right: 15px;
}
.bulk-action-btn button {
  margin-right: 10px;
}
/* end bulk action */

/* status*/

.status-label-rejected {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #f2545b;
  text-transform: capitalize;
}
.status-label-Offline {
  background: #d0dae4;
  color: #333333 !important;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-cancelled {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #708295;
  text-transform: capitalize;
}

.status-label-ended {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #708295;
  text-transform: capitalize;
}

.status-label-done {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-in-progress {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-in_progress {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-1,
.status-label-approved {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-2 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-pending {
  background: #d0dae4;
  color: #333;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-draft {
  background: #d0dae4;
  color: #333;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-3 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-4 {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #f2545b;
  text-transform: capitalize;
}
.status-label-5 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-6 {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #00639f;
  text-transform: capitalize;
}

.status-label-7 {
  background: #517820;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-8 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-9 {
  background: #d0dae4;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-10 {
  background: #d0dae4;
  color: #333;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-11 {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #708295;
  text-transform: capitalize;
}
.status-label-17 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-50 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-Inactive {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 8px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-Active {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-active {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-invited {
  background: #f96332;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-suspended {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-deleted {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-Suspended {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.entity_status-label-3 {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-2 {
  background: #d0dae4;
  color: #333;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.entity_status-label-11 {
  background: #f96332;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-4 {
  background: #00639f;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-5 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-6 {
  background: #5f7999;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-7 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-8 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-9 {
  background: #d0dae4;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-10 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-17 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-50 {
  background: #ab1a00;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-61 {
  background: #b98472;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-200 {
  background: #c55065;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-201 {
  background: #733733;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-1 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-2 {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-3 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-4 {
  background: #d0dae4;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-5 {
  background: #960000;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-6 {
  background: #517820;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-7 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-8 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-1 {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-2 {
  background: #517820;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-3 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-4 {
  background: #d0dae4;
  color: #333;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-5 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-6 {
  background: #00639f;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-7 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-uploaded {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-updated {
  background: #00639f;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
div[class^="status-label-"] a,
div[class^="services-status-label-"] a,
div[class^="entity_status-label-"] a,
div[class^="rfq-status-label-"] a {
  color: #fff;
}
/* end status */

/* mat checkbox modif */
.mat-checkbox-frame {
  border: 1px solid #708295 !important;
  border-radius: 3px !important;
}
span.mat-checkbox-label .star {
  margin-right: 3px !important;
  margin-top: 0 !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #f96332;
}
.mat-checkbox-inner-container {
  margin: 0 !important;
}
.filter-content-row .mat-checkbox-inner-container {
  margin-right: 7px !important;
  width: 16px !important;
  height: 16px !important;
}
.mat-checkbox-layout {
  align-items: center !important;
}
.mat-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
}
/* end mat checkbox modif */

/* chat */
.chat-page-left {
  float: left;
  width: 65%;
}
.chat-page-right {
  float: left;
  width: calc(35% - 130px);
  position: absolute;
  overflow: auto;
  height: 528px;
  right: 45px;
}
.chat-page-wrapper {
  display: inline-block;
  width: 100%;
}
.selected-msg {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.selected-msg td {
  background: #f4f5f6;
}
.msg-conv-wrapper {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.msg-header-wrapper {
  background: #fff;
  width: 100%;
  position: absolute;
  right: 0;
  height: 72px;
  line-height: 72px;
  border-bottom: 1px solid #efefef;
  padding: 0 20px;
}
.msg-header {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  width: calc(100% - 28px);
}
.msg-header-action {
  float: left;
  width: 28px;
}
.msg-header-action i:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #708295;
  font-size: 20px;
  cursor: pointer;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.toggle-is-open.msg-header-action i:before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.msg-header-action i:hover::before {
  color: #f96332;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.chat-body {
  padding: 85px 20px 0 20px;
  font-size: 13px;
  height: 528px;
  overflow-y: auto;
  border-bottom: 70px solid transparent;
}
/* .message-wraper span {
  display: block;
} */
.msg-user-name {
  color: #f96332;
  margin-bottom: 5px;
  display: block;
}
.msg-user-text {
  background: #f0f0f0;
  display: inline-block !important;
  padding: 5px 10px;
  border-radius: 4px;
  color: #708295;
  word-break: break-word;
}
.msg-date {
  color: #708295;
  font-size: 10px;
  margin: 5px 0;
  display: block;
}
.message-wraper {
  margin-bottom: 15px;
  clear: both;
  width: 90%;
}
.your-msg .msg-user-text {
  background: #f96332;
  color: #fff;
  text-align: left;
}
.your-msg {
  float: right;
  text-align: right;
}
.msg-header-action-option ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.msg-header-action-option ul li {
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  cursor: pointer;
  text-align: left;
  line-height: initial;
}
.msg-header-action-option {
  position: absolute;
  top: 50px;
  right: 25px;
  border: 1px solid #efefef;
  padding: 15px;
  background: #fff;
  display: none;
  z-index: 1;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.toggle-is-open .msg-header-action-option {
  display: block;
}
.msg-header-action-option ul li:last-child {
  margin-bottom: 0;
}
.msg-header-action-option ul li:hover {
  color: #f96332;
  text-decoration: underline;
}
.new-msg-label {
  background: #f96332;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  font-size: 12px;
  margin: 0 15px;
}
/* end chat */

/* icons */

@font-face {
  font-family: "icons";
  src: url("/assets/fonts/icons.eot");
  src: url("/assets/fonts/icons.eot?72945082#iefix") format("embedded-opentype"),
    url("/assets/fonts/icons.woff2") format("woff2"),
    url("/assets/fonts/icons.woff") format("woff"),
    url("/assets/fonts/icons.ttf") format("truetype"),
    url("/assets/fonts/icons.svg?72945082#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?72945082#fontello') format('svg');
    }
  }
  */

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.dialog-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}
.icon-bell:before {
  content: "\e800";
} /* '' */
.icon-mail:before {
  content: "\e801";
} /* '' */
.icon-ok-circled2:before {
  content: "\e802";
} /* '' */
.icon-cancel-circled2:before {
  content: "\e803";
} /* '' */
.icon-cancel-circled:before {
  content: "\e804";
} /* '' */
.icon-down-open:before {
  content: "\e805";
} /* '' */
.icon-left-open:before {
  content: "\e806";
} /* '' */
.icon-right-open:before {
  content: "\e807";
} /* '' */
.icon-up-open:before {
  content: "\e808";
} /* '' */
.icon-down-dir:before {
  content: "\e809";
} /* '' */
.icon-up-dir:before {
  content: "\e80a";
} /* '' */
.icon-left-dir:before {
  content: "\e80b";
} /* '' */
.icon-right-dir:before {
  content: "\e80c";
} /* '' */
.icon-ok:before {
  content: "\e80d";
} /* '' */
.icon-cancel:before {
  content: "\e80e";
} /* '' */
.icon-dot-3:before {
  content: "\e80f";
} /* '' */
.icon-user-add-outline:before {
  content: "\e810";
} /* '' */
.icon-cog-outline:before {
  content: "\e811";
} /* '' */
.icon-cog-1:before {
  content: "\e812";
} /* '' */
.icon-search:before {
  content: "\e813";
} /* '' */
.icon-search-1:before {
  content: "\e814";
} /* '' */
.icon-plus:before {
  content: "\e815";
} /* '' */
.icon-minus:before {
  content: "\e816";
} /* '' */
.icon-plus-1:before {
  content: "\e817";
} /* '' */
.icon-cog:before {
  content: "\e818";
} /* '' */
.icon-minus-1:before {
  content: "\e819";
} /* '' */
.icon-arrow-combo:before {
  content: "\e81a";
} /* '' */
.icon-rfqs:before {
  content: "\e81b";
} /* '' */
.icon-cancel-squared:before {
  content: "\e81c";
} /* '' */
.icon-calendar:before {
  content: "\e81d";
} /* '' */
.icon-list-add:before {
  content: "\e81e";
} /* '' */
.icon-heart:before {
  content: "\e81f";
} /* '' */
.icon-heart-empty:before {
  content: "\e820";
} /* '' */
.icon-basket:before {
  content: "\e821";
} /* '' */
.icon-location:before {
  content: "\e822";
} /* '' */
.icon-location-1:before {
  content: "\e823";
} /* '' */
.icon-cancel-1:before {
  content: "\e824";
} /* '' */
.icon-clients_comp:before {
  content: "\e825";
} /* '' */
.icon-cms_comp:before {
  content: "\e826";
} /* '' */
.icon-commissions_comp:before {
  content: "\e827";
} /* '' */
.icon-dashboard_comp:before {
  content: "\e828";
} /* '' */
.icon-financial_comp:before {
  content: "\e829";
} /* '' */
.icon-help_comp:before {
  content: "\e82a";
} /* '' */
.icon-messaging_comp:before {
  content: "\e82b";
} /* '' */
.icon-orders_comp:before {
  content: "\e82c";
} /* '' */
.icon-providers_compound:before {
  content: "\e82d";
} /* '' */
.icon-calendar-1:before {
  content: "\e82e";
} /* '' */
.icon-services-comp:before {
  content: "\e82f";
} /* '' */
.icon-double-arrow:before {
  content: "\e830";
} /* '' */
.icon-calendar-2:before {
  content: "\e831";
} /* '' */
.icon-calendar-empty-1:before {
  content: "\e832";
} /* '' */
.icon-cart:before {
  content: "\e833";
} /* '' */
.icon-bell-1:before {
  content: "\e834";
} /* '' */
.icon-phone:before {
  content: "\e835";
} /* '' */
.icon-star:before {
  content: "\e836";
} /* '' */
.icon-star-empty:before {
  content: "\e837";
} /* '' */
.icon-star-half:before {
  content: "\e838";
} /* '' */
.icon-trash-empty:before {
  content: "\e839";
} /* '' */
.icon-trash:before {
  content: "\e83a";
} /* '' */
.icon-block:before {
  content: "\e83b";
} /* '' */
.icon-block-1:before {
  content: "\e83c";
} /* '' */
.icon-login:before {
  content: "\e83d";
} /* '' */
.icon-cog-outline-1:before {
  content: "\e83e";
} /* '' */
.icon-cog-2:before {
  content: "\e83f";
} /* '' */
.icon-edit:before {
  content: "\e840";
} /* '' */
.icon-pencil:before {
  content: "\e841";
} /* '' */
.icon-pencil-1:before {
  content: "\e842";
} /* '' */
.icon-floppy:before {
  content: "\e843";
} /* '' */
.icon-floppy-1:before {
  content: "\e844";
} /* '' */
.icon-th-large:before {
  content: "\e845";
} /* '' */
.icon-th-large-outline:before {
  content: "\e846";
} /* '' */
.icon-th:before {
  content: "\e847";
} /* '' */
.icon-upload:before {
  content: "\e848";
} /* '' */
.icon-eye:before {
  content: "\e849";
} /* '' */
.icon-eye-1:before {
  content: "\e84a";
} /* '' */
.icon-download:before {
  content: "\e84b";
} /* '' */
.icon-cancel-2:before {
  content: "\e84c";
} /* '' */
.icon-cancel-3:before {
  content: "\e84d";
} /* '' */
.icon-lock-reset:before {
  content: "\e84e";
} /* '' */
.icon-cloud-outline:before {
  content: "\e84f";
} /* '' */
.icon-credit-card:before {
  content: "\e850";
} /* '' */
.icon-record:before {
  content: "\e851";
} /* '' */
.icon-record-outline:before {
  content: "\e852";
} /* '' */
.icon-menu-1:before {
  content: "\e853";
} /* '' */
.icon-attention:before {
  content: "\e854";
} /* '' */
.icon-attention-1:before {
  content: "\e855";
} /* '' */
.icon-attention-circled:before {
  content: "\e856";
} /* '' */
.icon-check:before {
  content: "\e857";
} /* '' */
.icon-ok-1:before {
  content: "\e858";
} /* '' */
.icon-area-orientation:before {
  content: "\e866";
} /* '' */
.icon-departure-assistance:before {
  content: "\e867";
} /* '' */
.icon-eu-citizen:before {
  content: "\e868";
} /* '' */
.icon-eu-forms:before {
  content: "\e869";
} /* '' */
.icon-house-search:before {
  content: "\e86a";
} /* '' */
.icon-immigration:before {
  content: "\e86b";
} /* '' */
.icon-inventory:before {
  content: "\e86c";
} /* '' */
.icon-moving:before {
  content: "\e86d";
} /* '' */
.icon-moving-services:before {
  content: "\e86e";
} /* '' */
.icon-non-eu-citizens:before {
  content: "\e86f";
} /* '' */
.icon-other-immigration:before {
  content: "\e870";
} /* '' */
.icon-relocation:before {
  content: "\e871";
} /* '' */
.icon-school-search:before {
  content: "\e872";
} /* '' */
.icon-settling-in:before {
  content: "\e873";
} /* '' */
.icon-storage:before {
  content: "\e874";
} /* '' */
.icon-taxation-arrival:before {
  content: "\e875";
} /* '' */
.icon-taxation-departure:before {
  content: "\e876";
} /* '' */
.icon-taxation-stay:before {
  content: "\e877";
} /* '' */
.icon-tax-services:before {
  content: "\e878";
} /* '' */
.icon-training:before {
  content: "\e879";
} /* '' */
.icon-visa-support:before {
  content: "\e87a";
} /* '' */
.icon-view-more:before {
  content: "\e890";
} /* '' */

.icon-lease_contract_termination:before {
  content: "\e883";
} /* '' */
.icon-uitility_contracts:before {
  content: "\e884";
} /* '' */
.icon-airport_transfer:before {
  content: "\e885";
} /* '' */
.icon-car_registrations:before {
  content: "\e886";
} /* '' */
.icon-banking_de_registration:before {
  content: "\e887";
} /* '' */
.icon-contract_termination:before {
  content: "\e888";
} /* '' */
.icon-departure_asssitance:before {
  content: "\e889";
} /* '' */
.icon-transportation_assistance_leaving:before {
  content: "\e88a";
} /* '' */
.icon-check_in_assistance:before {
  content: "\e88b";
} /* '' */
.icon-house_hunt:before {
  content: "\e88c";
} /* '' */
.icon-lease_negotiation:before {
  content: "\e88d";
} /* '' */
.icon-lease_renewal:before {
  content: "\e88e";
} /* '' */
.icon-temporary_housing:before {
  content: "\e88f";
} /* '' */
.icon-furniture_rental:before {
  content: "\e890";
} /* '' */
.icon-other:before {
  content: "\e891";
} /* '' */
.icon-tenancy:before {
  content: "\e892";
} /* '' */
.icon-utilities:before {
  content: "\e893";
} /* '' */
.icon-check_in_assistance-1:before {
  content: "\e894";
} /* '' */
.icon-domestic_support:before {
  content: "\e895";
} /* '' */
.icon-medical_assistance:before {
  content: "\e896";
} /* '' */
.icon-opening_bank_account:before {
  content: "\e897";
} /* '' */
.icon-spouse_support:before {
  content: "\e898";
} /* '' */
.icon-transportation_assistance:before {
  content: "\e899";
} /* '' */
.icon-intercultural_trainings:before {
  content: "\e89a";
} /* '' */
.icon-language_trainings:before {
  content: "\e89b";
} /* '' */
.icon-area_orientation:before {
  content: "\e89c";
} /* '' */
.icon-airport_transfer-1:before {
  content: "\e89d";
} /* '' */
.icon-car_registrations-1:before {
  content: "\e89e";
} /* '' */
.icon-car_rental:before {
  content: "\e89f";
} /* '' */
.icon-de_registration:before {
  content: "\e8a1";
} /* '' */
.icon-renewal:before {
  content: "\e8a2";
} /* '' */
.icon-residence_change:before {
  content: "\e8a3";
} /* '' */
.icon-other-1:before {
  content: "\e8a4";
} /* '' */
.icon-permanent_residence:before {
  content: "\e8a5";
} /* '' */
.icon-residence_cancel:before {
  content: "\e8a6";
} /* '' */
.icon-residence_change-1:before {
  content: "\e8a7";
} /* '' */
.icon-residence_permit:before {
  content: "\e8a8";
} /* '' */
.icon-residence_renewal:before {
  content: "\e8a9";
} /* '' */
.icon-work_authorization-copy:before {
  content: "\e8aa";
} /* '' */
.icon-work_renewal:before {
  content: "\e8ab";
} /* '' */
.icon-approval_family_members:before {
  content: "\e8ac";
} /* '' */
.icon-residence_permit_non_eu:before {
  content: "\e8ad";
} /* '' */
.icon-work_authorization:before {
  content: "\e8ae";
} /* '' */
.icon-apostille_process:before {
  content: "\e8af";
} /* '' */
.icon-child_support:before {
  content: "\e8b0";
} /* '' */
.icon-company_documents:before {
  content: "\e8b1";
} /* '' */
.icon-notary_assistance:before {
  content: "\e8b2";
} /* '' */
.icon-police_clerance:before {
  content: "\e8b3";
} /* '' */
.icon-study_recognition:before {
  content: "\e8b4";
} /* '' */
.icon-super_authentication:before {
  content: "\e8b5";
} /* '' */
.icon-translation_and_legalisation:before {
  content: "\e8b6";
} /* '' */
.icon-other-copy:before {
  content: "\e8b7";
} /* '' */
.icon-visa_family:before {
  content: "\e8b8";
} /* '' */
.icon-visa_renewal:before {
  content: "\e8b9";
} /* '' */
.icon-visa_secondament:before {
  content: "\e8ba";
} /* '' */
.icon-work:before {
  content: "\e8bb";
} /* '' */
.icon-invitation_letter_support:before {
  content: "\e8bc";
} /* '' */
.icon-visa_business:before {
  content: "\e8bd";
} /* '' */
.icon-visa_pleasure:before {
  content: "\e8be";
} /* '' */
.icon-long_term_visa:before {
  content: "\e8bf";
} /* '' */
.icon-short_term_visa:before {
  content: "\e8c0";
} /* '' */
.icon-surveys:before {
  content: "\e8c1";
} /* '' */
.icon-long-temr:before {
  content: "\e8c2";
} /* '' */
.icon-short_term:before {
  content: "\e8c3";
} /* '' */
.icon-art:before {
  content: "\e8c4";
} /* '' */
.icon-furniture:before {
  content: "\e8c5";
} /* '' */
.icon-pet:before {
  content: "\e8c6";
} /* '' */
.icon-vehicles:before {
  content: "\e8c7";
} /* '' */
.icon-a1_form:before {
  content: "\e8c8";
} /* '' */
.icon-health_card:before {
  content: "\e8c9";
} /* '' */
.icon-other_form:before {
  content: "\e8ca";
} /* '' */
.icon-s1_form:before {
  content: "\e8cb";
} /* '' */
.icon-exit_certificate:before {
  content: "\e8cc";
} /* '' */
.icon-exit_tax:before {
  content: "\e8cd";
} /* '' */
.icon-labour_notification-copy-2:before {
  content: "\e8ce";
} /* '' */
.icon-retirement_contributions-copy:before {
  content: "\e8cf";
} /* '' */
.icon-tax_interview-copy:before {
  content: "\e8d0";
} /* '' */
.icon-tax_residency-copy:before {
  content: "\e8d1";
} /* '' */
.icon-anual_tax_declaration:before {
  content: "\e8d2";
} /* '' */
.icon-labour_notification-copy:before {
  content: "\e8d3";
} /* '' */
.icon-retirement_contributions:before {
  content: "\e8d4";
} /* '' */
.icon-tax_calculator:before {
  content: "\e8d5";
} /* '' */
.icon-tax_certificate:before {
  content: "\e8d6";
} /* '' */
.icon-labour_notification:before {
  content: "\e8d7";
} /* '' */
.icon-social_security:before {
  content: "\e8d8";
} /* '' */
.icon-tax_interview:before {
  content: "\e8d9";
} /* '' */
.icon-tax_number:before {
  content: "\e8da";
} /* '' */
.icon-tax_registration:before {
  content: "\e8db";
} /* '' */
.icon-tax_residency:before {
  content: "\e8dc";
} /* '' */
.icon-heart-broken:before {
  content: "\f028";
} /* '' */
.icon-twitter:before {
  content: "\f099";
} /* '' */
.icon-facebook:before {
  content: "\f09a";
} /* '' */
.icon-filter:before {
  content: "\f0b0";
} /* '' */
.icon-menu:before {
  content: "\f0c9";
} /* '' */
.icon-list-numbered:before {
  content: "\f0cb";
} /* '' */
.icon-gplus:before {
  content: "\f0d5";
} /* '' */
.icon-linkedin:before {
  content: "\f0e1";
} /* '' */
.icon-download-cloud:before {
  content: "\f0ed";
} /* '' */
.icon-upload-cloud:before {
  content: "\f0ee";
} /* '' */
.icon-plus-squared:before {
  content: "\f0fe";
} /* '' */
.icon-angle-left:before {
  content: "\f104";
} /* '' */
.icon-angle-right:before {
  content: "\f105";
} /* '' */
.icon-angle-up:before {
  content: "\f106";
} /* '' */
.icon-angle-down:before {
  content: "\f107";
} /* '' */
.icon-star-half-alt:before {
  content: "\f123";
} /* '' */
.icon-attention-alt:before {
  content: "\f12a";
} /* '' */
.icon-calendar-empty:before {
  content: "\f133";
} /* '' */
.icon-minus-squared:before {
  content: "\f146";
} /* '' */
.icon-minus-squared-alt:before {
  content: "\f147";
} /* '' */
.icon-down:before {
  content: "\f175";
} /* '' */
.icon-up:before {
  content: "\f176";
} /* '' */
.icon-left:before {
  content: "\f177";
} /* '' */
.icon-right:before {
  content: "\f178";
} /* '' */
.icon-plus-squared-alt:before {
  content: "\f196";
} /* '' */
.icon-newspaper:before {
  content: "\f1ea";
} /* '' */
.icon-file-pdf:before {
  content: "\f1c1";
} /* '' */
.icon-copyright:before {
  content: "\f1f9";
} /* '' */
.icon-linkedin-squared:before {
  content: "\f30c";
} /* '' */
.icon-gplus-1:before {
  content: "\f30f";
} /* '' */
/* '' */
.icon-chart-gantt-solid:before {
  content: "\f30d";
}
.icon-cost-projection-up-arrow:before {
  content: "\E802";
} /* '' */
.icon-cost-projection-down-arrow:before {
  content: "\E800";
} /* '' */
.icon-cost-projection:before {
  content: "\eb3f";
} /* '' */
.brcmb {
  display: inline-block;
  margin-left: 40px;
  cursor: pointer;
}

.error {
  color: #f44336;
  font-size: 12px;
}

/* css for drupal */
.category-description-wrapper {
  background: #fff;
}
.category-description-title {
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0;
  border: 1px solid #f0f0f0;
}
.category-page-description {
  color: #6d6c6a;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}
.category-page-img img {
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
  height: 350px;
}
.category-page-img {
  margin: 0 0 25px 0;
}

.homepage.show-scrolled.lock-scroll {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.homepage.lock-scroll {
  /* position: fixed;
  width: 100%;
  overflow-y: scroll; */
  overflow: auto;
}
.homepage.show-scrolled .hp-slider-wrapper {
  visibility: hidden;
}
.homepage.show-scrolled .service-categ.btn {
  display: block;
}
.homepage .header-bottom-action {
  width: 100%;
}
.homepage.show-scrolled .header-bottom-action {
  width: calc(100% - 182px);
}
.homepage .header-bottom-action-item.search-box-header-wrapper {
  margin-left: 0;
}
.homepage .search-box-header-wrapper {
  width: calc(100% - 205px);
}
.homepage .main-navigation-wrapper {
  position: relative;
  top: 0;
  word-break: normal;
}
.homepage.show-scrolled .main-navigation-wrapper {
  position: absolute;
  top: 60px;
  z-index: 100;
}

.homepage-wrapper .top-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.homepage-wrapper .top-navigation {
  /* box-shadow: 1px 1px 12px 0 rgba(0,0,0,0.09);
  background: #fff;
  border-radius: 0 0 0 6px; */
  float: left;
  width: 290px;
}
.homepage-wrapper .top-navigation ul li {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  padding: 30px 25px 30px 15px;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;
  position: relative;
}
.homepage-wrapper .top-navigation ul li i:before {
  position: absolute;
  width: 30px;
  font-size: 24px;
  line-height: 18px;
  margin: 0;
  top: 50%;
  -webkit-transform: translate(0, -60%);
  transform: translate(0, -60%);
}
.homepage-wrapper .top-navigation ul li i.icon-immigration:before {
  font-size: 20px;
}
.homepage-wrapper .top-navigation ul li i.icon-moving-services:before {
  font-size: 23px;
}
.homepage-wrapper .top-navigation .top-nav-label {
  padding-left: 35px;
}
.homepage-wrapper .top-navigation ul li:hover {
  color: #f96332;
  transition: unset;
}
.top-banner-right {
  float: left;
  width: calc(100% - 290px);
  height: 440px;
  position: relative;
}
.top-banner-right img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-bottom-right-radius: 6px;
}
.top-banner-right:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  /* background: linear-gradient(224.53deg, rgba(216,210,210,0) 0%, rgba(86,32,14,0.59) 100%); */
  border-bottom-right-radius: 6px;
}
.banner-title {
  font-size: 30px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  line-height: 38px;
  margin-bottom: 15px;
}
.banner-description {
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin-bottom: 30px;
}
.global-mobility-title {
  font-size: 30px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  line-height: 38px;
  margin-bottom: 15px;
}
.global-mobility-description {
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin-bottom: 30px;
}
.banner-description-absolute-left {
  position: absolute;
  z-index: 1;
  left: 60px;
  top: 50%;
  transform: translate(0, -50%);
  width: 50%;
  text-align: left;
}
.banner-description-absolute-right {
  position: absolute;
  z-index: 1;
  right: 60px;
  top: 50%;
  transform: translate(0, -50%);
  width: 50%;
  text-align: right;
}
.banner-description-absolute-center {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
}
.banner-img img {
  width: 350px;
  max-height: 350px;
  margin-left: 150px;
}
@media only screen and (max-width: 1024px) {
  .banner-img {
    display: none;
  }
}
.how-works-banner-wrapper {
  transform: translate(-50%, -65%);
}
.homepage-wrapper .btn {
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
}
.homepage-wrapper .orange-bg {
  background: #f96332;
  color: #fff;
  font-weight: 500;
}
.homepage-wrapper .orange-bg:hover {
  background: #db5527;
}
.homepage-wrapper .center-helper {
  text-align: center;
}
.homepage-wrapper section {
  margin-bottom: 75px;
  clear: both;
  display: inline-block;
  width: 100%;
}
.hp-big-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 40px;
}
.p-height-list {
  margin-bottom: 15px;
}
.img-action-bottom-wrapper img {
  /* width: 100%; */
  /* border-radius: 6px;
  background-size: contain;
  background-repeat: no-repeat;*/
  /* background-position: center;  */
  float: initial;
}
.img-action-bottom-wrapper {
  position: relative;
}

.expats-text {
  padding-top: 10%;
}
.img-action-bottom-content {
  position: absolute;
  bottom: 25px;
  left: 50px;
  right: 50px;
  z-index: 1;
}
.img-action-bottom-text {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  text-align: right;
  z-index: 2;
  position: absolute;
  top: 42%;
  right: 41px;
}
.img-action-bottom-btn {
  float: center;
  margin-top: 20px;
}
.img-action-bottom-btn .btn {
  margin-left: 20px;
}
.img-action-bottom-wrapper:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  /* background: linear-gradient(
    224.53deg,
    rgba(216, 210, 210, 0) 0%,
    rgba(86, 32, 14, 0.59) 100%
  ); */
  border-radius: 6px;
}
.welcome-image img {
  float: right;
  width: 500px;
  height: 450px;
  object-fit: contain;
}
@media only screen and (max-width: 600px) {
  .welcome-image img {
    display: flex;
    justify-content: center;
    max-width: 300px;
  }
}
.homepage-wrapper .list-style {
  list-style: none;
  padding: 0;
  margin: 0;
}
.homepage-wrapper .list-style li {
  position: relative;
  padding-left: 32px;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 30px;
}
.homepage-wrapper .list-style li:last-child {
  margin-bottom: 0;
}
.homepage-wrapper .list-style li:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #f96332;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 0;
}
.homepage-wrapper .list-style span {
  font-weight: 600;
}

.homepage-wrapper .w-30 {
  width: 30%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.homepage-wrapper .w-20 {
  width: 20%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.homepage-wrapper .w-50 {
  width: 50%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.homepage-wrapper .w-40 {
  width: 40%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.homepage-wrapper .w-60 {
  width: 60%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.homepage-wrapper .w-100 {
  width: 100%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.homepage-wrapper .row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  /* display: inline-block;
  width: 100%; */
}
.hp-box {
  border: 2px solid #eaeaea;
  border-radius: 6px;
  padding: 35px;
}
.hp-box-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hp-box-title:hover {
  color: #f96332;
  cursor: pointer;
}
.hp-box-title p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hp-box-description {
  font-size: 16px;
  margin-bottom: 30px;
}
.homepage-wrapper .push-left {
  margin-left: 115px;
}
.homepage-wrapper .push-right {
  margin-right: 115px;
}
.clients-logo img {
  width: 220px;
  margin: 0 50px;
}
#section-download-app {
  position: relative;
  height: 450px;
}
.section-download-app img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.download-btn img {
  width: 130px;
  height: 42px;
  border-radius: 6px;
  margin-left: 15px;
  cursor: pointer;
}
.homepage-wrapper {
  font-size: 16px;
  margin-top: -50px;
}
.p-height-list p {
  font-size: 18px;
  margin-bottom: 20px;
}
.p-height-list p:last-child {
  margin-bottom: 0;
}
.single-img-box {
  text-align: center;
}
.single-img-box img {
  width: 90%;
}
.homepage-wrapper .border-btn {
  color: #fff;
  border: 1px solid #fff;
  padding: 9px 19px;
}
.homepage-wrapper .global-mobility-border-btn {
  color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  padding: 9px 19px;
}
.homepage-wrapper .border-btn:hover {
  background: #f96332;
  border: 1px solid transparent;
}
.banner-description-absolute-left .btn {
  margin-right: 20px;
}
.banner-description-absolute-right .btn {
  margin-left: 20px;
}
.hp-footer-banner .footer-top {
  /* background: url('assets/images/expats.png'); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 380px;
}
.hp-footer-banner-right .footer-top-right {
  background: url("assets/images/expats.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 380px;
}

.hp-box-date {
  font-size: 14px;
}
.news-section .hp-box {
  margin-top: 30px;
}
.news-section .row:last-child .hp-box {
  margin-bottom: 0;
}

.how-work-banner {
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 400px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.how-work-banner {
  position: relative;
}
.how-work-banner img {
  width: 450px;
}
.how-works-banner-title {
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 30px;
}
.how-works-banner-description {
  font-size: 16px;
  color: black;
  margin-bottom: 25px;
}
.how-work-banner:after {
  /* background: linear-gradient(213.46deg, #51342a 0%, #272221 150%); */
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.how-works-banner-tab ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.how-works-banner-tab ul li {
  display: inline;
  font-size: 22px;
  color: rgb(0, 0, 0);
  font-weight: 700;
  z-index: 999;
  cursor: pointer;
  margin: 0 30px;
  line-height: 22px;
  position: relative;
}
.how-works-banner-tab ul li:hover {
  color: #f96332;
}
.how-works-banner-tab {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
}
.how-works-banner-tab ul .banner-tab-active:before {
  content: "";
  height: 5px;
  width: 100%;
  position: absolute;
  background: #f96332;
  bottom: -20px;
  border-radius: 4px;
}
.how-works-wrapper section {
  margin-bottom: 75px;
  clear: both;
  display: inline-block;
  width: 100%;
}
.how-work-img img {
  width: 100%;
}

.how-works-wrapper .list-style {
  list-style: none;
  padding: 0;
  margin: 0;
}
.how-works-wrapper .list-style li {
  position: relative;
  padding-left: 32px;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 30px;
}
.how-works-wrapper .list-style li:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #f96332;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 0;
}
.how-work-img img {
  width: 500px;
}
@media only screen and (max-width: 600px) {
  .how-work-img img {
    display: flex;
    justify-content: center;
    width: 350px;
  }
}
.multi-img-m-b {
  margin-bottom: 20px;
}
.how-work-img-multi img {
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  height: 100%;
}
.how-work-img-mul img {
  max-width: 600px;
}
.ve-al-txt {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.read-more-btn {
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  background: #f96332;
  color: #fff;
}
.read-more-btn:hover {
  background: #db5527;
}
.hp-box-description-news {
  overflow: hidden;
  position: relative;
  max-height: 42px;
  text-align: justify;
  margin-right: -15px;
  padding-right: 30px;
  font-size: 16px;
  margin-bottom: 30px;
}
.hp-box-description-news:before {
  content: "...";
  position: absolute;
  right: 20px;
  bottom: -2px;
}
.how-works-wrapper {
  margin-top: -50px;
}
.custom-tab-content {
  overflow-x: hidden;
}
.industries-box {
  font-weight: 500;
  border: 2px solid #f96332;
  padding: 10px;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  margin: 5px 10px;
}
/* .industries-box:hover {
  background: #f96332;
  color: #fff;
} */
/* carousel */
.industries {
  background-color: #fff;
  padding: 5%;
}
.carousel {
  z-index: 1;
  border-bottom-right-radius: 6px;
}
.carousel .content {
  display: flex;
}
.carousel .content .custom-item {
  width: 100%;
  display: block;
  opacity: 0;
  position: relative;
  background-color: #fff;
}
.carousel .content .custom-item.fade_animation {
  transition: opacity 0.295s linear 0.2s;
}
.carousel .content .custom-item.fade_animation_0 {
  transition: opacity 0.295s linear;
}
.carousel .content .custom-item.visible {
  opacity: 1;
}
.carousel .content .custom-item:last-child,
.carousel .content .custom-item:first-child {
  opacity: 0;
}
.carousel .content .custom-item.visible_important {
  opacity: 1;
}
.carousel .content .custom-item .img {
  width: 100%;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 440px;
}
.carousel .ball {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f96332;
  border: 2px solid #f0f0f0;
  opacity: 0.5;
}
.carousel .ball.visible {
  opacity: 1;
}
.carousel .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #ff5252;
}
.carousel .click-area {
  width: 50px;
  text-align: center;
}
.carousel .click-area i {
  font-size: 3em;
}
.custom-transition {
  transition: all 0.2s ease-in-out;
}
.carousel .content .custom-item .img:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  /* background: linear-gradient(
    224.53deg,
    rgba(216, 210, 210, 0) 0%,
    rgba(86, 32, 14, 0.59) 100%
  ); */
  border-bottom-right-radius: 6px;
}
.loader-mask:before {
  content: "";
  background: #f4f5f6;
  width: 100%;
  height: 101%;
  z-index: 998;
  position: absolute;
}
.hp-slider-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.related-services-slider-wrapper .owl-item {
  max-width: 357px !important;
}
.related-services-slider-wrapper .owl-item .card-wrapper {
  width: 100%;
  /* max-width: 325px; */
}
.related-services-slider-wrapper .owl-item .card-box {
  margin-bottom: 0;
}
.related-services-slider-wrapper .owl-nav {
  margin: 0;
}
.related-services-slider-wrapper .owl-nav [class*="owl-"] {
  background: #fff;
  margin: 0;
  padding: 0;
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #f96332;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2);
}
.related-services-slider-wrapper .owl-nav [class*="owl-"]:hover {
  background: #fff;
  color: #708295;
}
.related-services-slider-wrapper .owl-next {
  position: absolute;
  right: -20px;
  top: 40%;
}
.related-services-slider-wrapper .owl-prev {
  position: absolute;
  top: 40%;
  left: -20px;
}
.related-services-slider-wrapper .owl-dots {
  margin: 10px 0;
}
.related-services-slider-wrapper .owl-dots .owl-dot span {
  background: #708295;
}
.related-services-slider-wrapper .owl-dots .owl-dot.active span,
.related-services-slider-wrapper .owl-dots .owl-dot:hover span {
  background: #f96332;
}
.related-services-slider-wrapper .owl-item .card-img img {
  cursor: grab;
}
/* end carousel */
/* end css for drupal */

/* media query */

@media (max-width: 1700px) {
  .w-m-40 {
    width: 40% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .w-m-50 {
    width: 50% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .w-m-60 {
    width: 60% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .w-m-100 {
    width: 100% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .sts-w-m-m-b {
    margin-bottom: 30px;
  }
  .row-m {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 2001px) {
  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 1900px;
  }

  .card-title {
    height: 42px;
    overflow: hidden;
  }
  .main-navigation-wrapper {
    width: 1870px;
  }
}

@media (max-width: 2000px) {
  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 1460px;
  }
  .main-navigation-wrapper {
    width: 1430px;
  }

  .card-title {
    height: 42px;
    overflow: hidden;
  }

  .card-title {
    height: 42px;
    overflow: hidden;
  }
  .main-navigation-lvl3 {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 10px;
  }
  .main-navigation-lvl2 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .main-navigation-group {
    margin-bottom: 40px;
    padding: 0 35px;
  }
  .main-navigation-group {
    margin-bottom: 35px;
    padding: 0 35px;
  }
  span.lvl1-label {
    font-size: 18px;
  }
  .main-navigation-lvl1 .menu-main-categ-icon:before {
    width: 26px;
    font-size: 26px;
    line-height: 26px;
  }
  .main-navigation-lvl1 i.icon-immigration:before {
    font-size: 22px;
  }
  .main-navigation-lvl1 i.icon-moving-services:before {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 1330px;
  }

  .card-wrapper,
  .tab-card-list-wrapper .card-wrapper,
  .fake-card-wrapper {
    width: 33.33%;
  }

  .card-title {
    height: 42px;
    overflow: hidden;
  }

  .card-box {
    margin: 10px 0;
  }

  .right-side {
    padding-left: 30px;
  }

  .main-navigation-wrapper {
    width: 1300px;
  }

  .main-navigation-left {
    width: 230px;
  }
  span.lvl1-label {
    font-size: 18px;
    padding: 0 65px;
  }
  .main-navigation-lvl1 .menu-main-categ-icon:before {
    width: 24px;
    font-size: 24px;
    line-height: 24px;
    left: 20px;
  }
  .main-navigation-lvl1 i.icon-moving-services:before {
    font-size: 24px;
  }
  .main-navigation-lvl1 i.icon-immigration:before {
    font-size: 21px;
  }
  .homepage-wrapper .top-navigation {
    float: left;
    width: 230px;
  }
  .top-banner-right {
    width: calc(100% - 230px);
  }
  .main-navigation-right {
    width: calc(100% - 230px);
    padding: 25px 0;
  }
  .main-navigation-group {
    margin-bottom: 35px;
    padding: 0 25px;
  }
  .main-navigation-lvl3 {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .main-navigation-lvl2 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .activeElem::before {
    font-size: 18px;
  }

  .table-wrapper table thead th {
    font-size: 15px;
  }
  /* td.ta-r,
  th.ta-r {
    text-align: left !important;
  } */
  .mat-sort-header-arrow {
    -webkit-transform: translateY(-4px) !important;
    transform: translateY(-4px) !important;
  }
  .iframe-category-wrapper .subcategory {
    width: calc(33.33% - 50px);
  }
}

@media (max-width: 1440px) {
  .iframe-category-wrapper .subcategory {
    width: calc(33.33% - 30px);
    margin: 15px;
  }

  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 1200px;
  }

  .right-side {
    padding-left: 35px;
  }

  .card-wrapper,
  .tab-card-list-wrapper .card-wrapper,
  .fake-card-wrapper {
    width: 33.33%;
  }

  .card-title {
    height: 42px;
    overflow: hidden;
  }

  .stars-bar-middle {
    width: 60%;
  }

  .main-navigation-wrapper {
    width: 1170px;
  }
  .auth-form-wrapper {
    padding: 0 50px;
  }

  .auth-left-inner .logo {
    margin: 50px 50px 0 50px;
  }
  .login-footer {
    margin: 0 50px 25px 50px !important;
  }

  .table-wrapper table thead th,
  .table-wrapper table tbody tr td {
    font-size: 13px;
  }
  .mat-sort-header-arrow {
    -webkit-transform: translateY(-2px) !important;
    transform: translateY(-2px) !important;
  }
  table .mat-checkbox-inner-container {
    width: 16px !important;
    height: 16px !important;
  }
  body {
    font-size: 13px;
  }
  .dashboard-body {
    padding: 30px 30px;
  }
  .header-wrapper-no-bg .header-top {
    padding: 15px 30px;
  }
  .small-fixed-header-portal .header-top {
    padding: 0 30px;
  }
  .left-navigation-wrapper {
    width: 230px;
  }

  .dashboard-wrapper {
    padding: 0 0 0 230px;
  }
  .btn-small {
    font-size: 13px;
  }
  .table-wrapper table tbody tr td:first-child,
  .table-wrapper table thead tr th:first-child {
    padding-left: 10px;
  }
  #header-wrapper-portal {
    width: calc(100% - 230px);
  }
  .header-top {
    font-size: 13px;
  }
  .small-dashboard-body-top,
  .small-dashboard-body-action,
  .small-table-bulk,
  .small-app-nav {
    width: calc(100% - 290px) !important;
  }
  .statistics-tab-wrapper {
    font-size: 13px;
  }
  .big-title {
    font-size: 22px;
  }
  .dashboard-statistics-box-title {
    font-size: 14px;
  }
  .fs-20 {
    font-size: 18px !important;
  }
  .fs-16 {
    font-size: 14px !important;
  }
  .statistics-table table thead tr th {
    font-size: 14px;
  }
  .medium-title {
    font-size: 16px;
  }
  .white-box-body,
  .white-box-header-action,
  .action-link-gray,
  .action-link-orange,
  .category-tree-row,
  .order-details-box-wrapper,
  .order-details-box-link ul li {
    font-size: 13px;
  }

  .modal-title,
  .custom-modal-wrapper .modal-title-custom {
    font-size: 22px;
  }

  .w-10,
  .w-20,
  .w-30,
  .w-40,
  .w-50,
  .w-60,
  .w-70,
  .w-80,
  .w-90,
  .w-100,
  .w-33 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .details-navigation .mat-tab-link {
    margin-left: 10px;
    font-size: 13px;
  }
  .modal-step-wrapper ul li,
  .modal-step-wrapper ul li.active-step {
    font-size: 14px;
  }
  .bulk-action-wrapper {
    padding: 10px 15px;
  }
  .eu-funds-logo img {
    width: 130px;
  }
  .hp-slider-section,
  .eu-funds-logo,
  .eu-funds-disclaimer {
    margin-bottom: 45px !important;
  }

  .logo img {
    width: 225px;
  }
  .eu-funds-img img {
    width: 57px;
  }
}

@media (max-width: 1300px) {
  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 1100px;
  }

  .main-navigation-wrapper {
    width: 1070px;
  }

  .content-left-side .card-wrapper {
    width: 330px;
  }

  .card-wrapper,
  .fake-card-wrapper {
    width: 50%;
  }
  .card-title {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 18px;
    height: 36px;
    overflow: hidden;
  }
  .main-title {
    font-size: 26px;
    line-height: 26px;
  }
  .tab-card-list-wrapper .card-wrapper {
    width: 50%;
  }
  .main-navigation-left {
    width: 200px;
  }
  span.lvl1-label {
    font-size: 16px;
    padding: 0 65px;
  }
  .main-navigation-lvl1 .menu-main-categ-icon:before {
    width: 24px;
    font-size: 24px;
    line-height: 24px;
    left: 20px;
  }
  .main-navigation-lvl1 i.icon-moving-services:before {
    font-size: 24px;
  }
  .main-navigation-lvl1 i.icon-immigration:before {
    font-size: 21px;
  }
  .homepage-wrapper .top-navigation {
    float: left;
    width: 200px;
  }
  .top-banner-right {
    width: calc(100% - 200px);
  }
  .main-navigation-right {
    width: calc(100% - 200px);
    padding: 20px 0;
  }
  .main-navigation-group {
    margin-bottom: 35px;
    padding: 0 20px;
  }
  .main-navigation-lvl3 {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .main-navigation-lvl2 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .activeElem::before {
    font-size: 18px;
  }
  .footer-bottom .w-20 {
    width: 50%;
  }
  .footer-bottom .w-30 {
    width: 50%;
  }
  .footer-bottom .row div[class*="w-"] {
    margin-bottom: 25px;
  }
  .eu-funds-logo img {
    width: 125px;
  }
  .hp-slider-section,
  .eu-funds-logo,
  .eu-funds-disclaimer {
    margin-bottom: 40px !important;
  }
}

@media (max-width: 1200px) {
  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 1000px;
  }
  .right-side {
    padding-left: 25px;
    width: calc(100% - 240px);
  }
  .left-side {
    width: 240px;
  }
  .auth-footer {
    margin: 0 50px -120px 50px;
    font-size: 12px;
    padding-bottom: 20px;
  }

  .login-footer {
    margin: 0 40px 20px 40px !important;
  }
  .auth-form-wrapper {
    padding: 0 40px;
  }
  .auth-left-inner .logo {
    margin: 40px 40px 0 40px;
  }
  .main-navigation-wrapper {
    width: 970px;
  }

  .left-navigation-wrapper {
    width: 210px;
  }

  .left-navigation-wrapper .logo {
    width: 180px;
  }
  .dashboard-wrapper {
    padding: 0 0 0 210px;
  }
  #header-wrapper-portal,
  #header-wrapper-admin {
    width: calc(100% - 210px);
  }
  .small-dashboard-body-top,
  .small-dashboard-body-action,
  .small-table-bulk,
  .small-app-nav {
    width: calc(100% - 270px) !important;
  }

  .iframe-category-wrapper .subcategory {
    width: calc(50% - 50px);
    margin: 25px;
  }
  .how-works-banner-tab ul li {
    font-size: 18px;
    margin: 0 15px;
    line-height: 22px;
  }
  .details-navigation .mat-tab-link {
    margin-left: 0;
  }
  .logo img {
    width: 200px;
  }
  .eu-funds-img img {
    width: 51px;
    margin: 0 7px;
  }
  .small-fixed-header-market .eu-funds-img img {
    margin: 0 7px;
  }
}

@media (max-width: 1100px) {
  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 900px;
  }
  .main-navigation-wrapper {
    width: 870px;
  }
}
@media (min-width: 993px) and (max-width: 1100px) {
  .logo img {
    width: 110px;
  }
  .eu-funds-img img {
    width: 46px;
  }
  .eu-logo {
    width: 165px !important;
  }

  .header-top-user-info {
    margin-left: 15px;
  }
  .notification-item {
    padding: 0 18px;
  }
  .notification-count {
    right: 13px;
  }
}
@media (max-width: 992px) {
  .related-services-slider-wrapper .owl-dots {
    display: none;
  }
  .related-services-slider-wrapper .owl-prev {
    left: 0;
  }
  .related-services-slider-wrapper .owl-next {
    right: 0;
  }
  .material-modal-dropdown-fix .customiz-select.ng-select-opened {
    position: relative;
    min-width: auto;
  }
  .material-modal-dropdown-fix .form-group {
    height: auto;
  }
  .small-box-details span {
    display: block;
  }
  .details-navigation .mat-ink-bar {
    display: none;
  }

  .details-navigation {
    height: auto;
  }
  .dashboard-body-action {
    height: auto;
  }
  .admin-notif {
    flex-direction: row;
    height: 50px;
  }

  .admin-return {
    margin-top: 0px;
  }

  .admin-return a {
    padding: 4px 13px;
    font-size: 13px;
    margin-left: 9px;
  }

  .search-box input {
    margin-bottom: 0;
  }
  .container-fix {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .logo img {
    width: 110px;
  }
  .eu-logo {
    width: 130px !important;
  }
  .mobile .header-top-user-info,
  .mobile .header-top-user-img {
    display: none;
  }

  .header-top-user-img-responsive {
  }
  .header-top-user-img-responsive img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all 0.3s ease-out;
  }
  .header-top-user-details-responsive {
    padding: 15px;
    border-bottom: 1px solid #efefef;
    font-weight: 500;
    position: relative;
  }
  .header-top-user-info-responsive {
    margin-left: 35px;
  }
  .carousel .content .custom-item .img,
  .top-banner-right {
    height: 380px;
  }
  .top-banner-right {
    float: none;
    width: 100%;
  }
  .banner-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  }
  .banner-description {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
  }
  .banner-description-absolute-left {
    position: absolute;
    z-index: 1;
    top: 50%;
    -webkit-transform: translate(0, -55%);
    transform: translate(0, -55%);
    width: 100%;
    padding: 15px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .footer-top .banner-description-absolute-left {
    position: relative;
    z-index: 1;
    top: 0;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 100%;
    padding: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    height: 300px;
  }
  .img-action-bottom-text-mobile {
    font-size: 20px;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -90%);
    transform: translate(0, -90%);
    text-align: center;
    padding: 15px;
  }
  .img-action-bottom-content-mobile {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    position: absolute;
    padding: 15px;
  }
  .mobility-buttons {
    padding-top: 2%;
  }
  .homepage.mobile .service-categ.btn {
    display: block;
  }
  .homepage-wrapper .top-navigation {
    display: none;
  }
  .open-child-menu {
    position: absolute;
    width: 28px;
    font-size: 28px;
    line-height: 28px;
    margin: 0;
    top: 50%;
    right: 25px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .mobile .main-navigation-left {
    width: 100%;
    float: none;
    border-radius: unset;
    box-shadow: none;
  }
  .mobile .main-navigation-wrapper {
    top: 0 !important;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    position: fixed !important;
    z-index: 9999;
  }
  .main-menu-mobile-close {
    font-size: 22px;
    float: right;
    padding: 15px;
  }
  .mobile.homepage.lock-scroll {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .sub-is-open ~ .toggle-tree {
    display: block;
  }
  .toggle-tree {
    display: none;
  }
  .main-navigation-mobile-wrapper .toggle-row-action.sub-is-open i:before {
    content: "\e819" !important;
    color: #f96332;
  }
  .main-navigation-mobile-wrapper {
    position: fixed;
    background: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999;
    overflow-x: auto;
    margin-left: -100%;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .main-navigation-mobile-wrapper.open-xs-menu {
    margin-left: 0;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .main-navigation-mobile-wrapper .main-title {
    padding: 15px;
    color: #f96332;
    margin-bottom: 0;
  }

  .homepage-wrapper section {
    margin-bottom: 45px;
  }
  .hp-big-title {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 25px;
  }
  .img-action-bottom-text {
    font-size: 20px;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -90%);
    transform: translate(0, -90%);
    text-align: center;
    padding: 15px;
  }
  .img-action-bottom-wrapper:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    background: linear-gradient(
      0.53deg,
      rgba(216, 210, 210, 0) 0%,
      rgba(86, 32, 14, 0.4) 50%
    );
    border-radius: 6px;
  }
  .homepage-wrapper .border-btn {
    padding: 8px 18px;
  }
  .img-action-bottom-btn .btn {
    margin-left: 0;
  }
  .img-action-bottom-content {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    position: absolute;
    padding: 15px;
  }
  .img-action-bottom-inner {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
    padding: 15px;
  }
  .img-action-bottom-btn {
    float: none;
    text-align: center;
  }
  .homepage-wrapper .btn {
    width: 45%;
  }
  .img-action-bottom-btn .btn {
    margin: 0 10px 10px;
  }
  .img-action-bottom-wrapper img {
    height: 310px;
  }
  .homepage-wrapper .list-style li {
    padding-left: 25px;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 28px;
  }
  .img-action-bottom-wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  .clients-logo img {
    width: 160px;
    margin: 0 15px 15px 0;
  }
  .clients-logo img:last-child {
    margin-bottom: 0;
  }
  .section-download-app {
    height: 260px;
  }
  .section-download-app img {
    border-radius: 6px;
  }
  .banner-description-absolute-right {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
    padding: 15px;
  }
  .section-download-app:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    background: linear-gradient(
      0.53deg,
      rgba(216, 210, 210, 0) 0%,
      rgba(86, 32, 14, 0.2) 50%
    );
    border-radius: 6px;
  }
  .single-img-box {
    width: 50%;
    margin: 0 auto 15px;
  }
  .footer-top:after {
    border-radius: 6px;
  }
  .hp-footer-banner .footer-top {
    height: 300px;
    border-radius: 6px;
  }
  .banner-description-absolute-left .btn {
    margin: 0 10px 10px;
  }
  .download-btn img {
    width: 110px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .p-height-list p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .news-section .hp-box {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .news-section .row:last-child .hp-box {
    margin-bottom: 15px;
  }
  .hp-box {
    border-radius: 6px;
    padding: 15px;
  }
  .hp-box-title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .hp-box-description-news {
    max-height: 40px;
    padding-right: 25px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .hp-box-date {
    font-size: 12px;
  }
  .read-more-btn {
    padding: 7px 14px;
  }
  .homepage-wrapper {
    font-size: 14px;
    margin-top: 0;
  }
  .wrapper-fix {
    padding-top: 15px;
    padding-bottom: 0;
  }
  .footer-bottom {
    padding: 15px;
  }
  .footer-bottom-title {
    margin-bottom: 15px;
  }
  .footer-bottom .row div[class*="w-"] {
    margin-bottom: 25px;
  }
  .footer-bottom ul li {
    margin-bottom: 15px;
  }

  .main-navigation-mobile-wrapper .category-tree-group {
    position: relative;
    padding: 0 0 0 15px;
    margin-left: 0;
  }
  .main-navigation-mobile-wrapper .category-tree-row {
    border-bottom: 1px solid #f0f0f0;
    min-height: 50px;
    margin: 0 0 0 -15px;
    padding: 15px 0;
    height: auto;
    line-height: normal;
  }
  .main-navigation-mobile-wrapper .category-tree-row span {
    margin-left: 45px;
    margin-right: 15px;
    display: block;
  }
  .main-navigation-mobile-wrapper .category-tree-group .toggle-row-action {
    position: absolute;
    right: 20px;
    top: 10px;
    left: 10px;
    border: 1px solid #708295;
    height: 28px;
    line-height: 28px;
    width: 28px;
    text-align: center;
    border-radius: 6px;
    color: #708295;
  }
  .active-nav-mobile {
    color: #f96332;
  }
  .main-navigation-mobile-wrapper .category-tree-group .toggle-row-action i {
    font-size: 22px;
  }
  .main-navigation-mobile-wrapper .menu-main-categ-icon:before {
    font-size: 20px;
    width: 22px;
  }
  .main-navigation-mobile-wrapper
    .menu-main-categ-icon.icon-immigration:before {
    font-size: 16px;
    width: 22px;
  }
  .mobile-nav-first-lvl {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 500;
  }

  .service-categ.btn {
    color: #fff;
    font-size: 14px;
    line-height: initial;
    padding: 0;
    width: auto;
    margin: 0;
    height: 35px;
    line-height: 35px;
    padding: 0 7px;
    background: #f96332;
  }
  .header-bottom-action-item {
    margin-left: 0;
  }
  ng-select.location-select {
    width: 100%;
  }
  .homepage .search-box-header-wrapper {
    width: 100%;
  }
  .header-bottom {
    padding: 15px 0;
    background: #fff;
  }
  .header-bottom .row {
    margin-bottom: 15px;
  }
  .header-bottom .row:last-child {
    margin-bottom: 0;
  }
  /* .search-box:before {
  top: 8px;
} */
  .mobile .header-bottom-action {
    width: 100%;
  }
  .wrapper-fix {
    margin-top: 174px;
  }
  .other-items-mobile-wrapper {
    background: #fff;
    display: inline-block;
    position: fixed;
    top: 0;
    z-index: 9999;
    right: 0;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 100%;
    margin-right: -280px;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    width: 240px;
    padding: 15px;
  }
  .auth-header-xs-wrapper {
    background: #fff;
    display: inline-block;
    position: fixed;
    top: 0;
    z-index: 9999;
    right: 0;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 100%;
    margin-right: -280px;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    width: 240px;
    padding: 15px;
  }
  .filter-box-wrapper {
    background: #fff;
    display: inline-block;
    position: fixed;
    top: 0;
    z-index: 9999;
    right: 0;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 100%;
    margin-right: -100%;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    width: 88%;
  }
  .filter-box-wrapper .left-side {
    width: 100%;
    overflow-x: hidden;
  }
  .open-filter,
  .open-close-mobile {
    margin-right: 0 !important;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .search-box-header-wrapper {
    width: 100%;
  }
  .close-mobile-fiter {
    position: fixed;
    top: 40px;
    right: 88%;
    z-index: 10001;
    background: #fff;
    padding: 5px;
    margin-left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    margin-right: -100%;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .close-mobile-fiter i:before {
    font-size: 22px;
  }
  .filter-box-backdrop.open-backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
  }
  .filter-box-backdrop-tools.open-backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
  }
  .filter-box-backdrop-filter.open-backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
  }
  .filter-box-backdrop-auth.open-backdrop {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
  }
  .right-side {
    padding-left: 0;
    width: 100%;
  }
  #scrollToTop {
    display: none !important;
  }
  .main-title {
    font-size: 22px;
    line-height: 22px;
  }

  .open-other {
    margin-right: 0;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .close-mobile-other,
  .close-mobile-tools {
    position: fixed;
    top: 40px;
    right: 240px;
    z-index: 10001;
    background: #fff;
    padding: 5px;
    margin-left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    margin-right: -280px;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .close-mobile-other i:before,
  .close-mobile-tools i:before {
    font-size: 22px;
  }
  .other-items-mobile-wrapper .select-language-wrapper ng-select.lang-select {
    width: 100%;
  }
  .other-items-mobile-wrapper .form-group {
    margin-bottom: 15px;
  }
  .open-other-items {
    color: #fff;
    font-size: 16px;
    line-height: initial;
    padding: 0;
    width: auto;
    margin: 0;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    background: #f96332;
    border-radius: 4px;
  }
  .open-mobile-fiter {
    float: right;
    margin: 15px 0;
    position: relative;
  }
  .mobile-filter-label {
    position: absolute;
    top: -6px;
    right: 0;
    background: #f96332;
    color: #fff;
    padding: 3px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 12px;
    text-align: center;
    font-size: 12px;
  }
  ng-select.no-clearable-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input,
  ng-select.no-clearable-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    padding: 0 20px 0 15px !important;
    height: 44px;
    line-height: 44px;
  }
  .card-title {
    font-size: 16px;
    line-height: 16px;
    height: auto;
  }
  .card-cart i {
    font-size: 22px;
  }
  .banner-description-absolute-left .btn {
    width: 45%;
  }
  .footer-top {
    height: 300px;
  }
  .footer-top-content {
    height: 300px;
  }
  ng-select.location-select:before {
    position: absolute;
    top: 12px;
    left: 8px;
  }
  .country-banner-selector ng-select.location-select:before {
    top: 11px;
  }
  .how-works-banner-title {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .how-works-banner-description {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .banner-description-absolute-center {
    width: 100%;
    padding: 15px;
  }
  .how-works-banner-tab ul li {
    font-size: 16px;
    margin: 0px 15px;
    line-height: 16px;
    white-space: nowrap;
  }
  .how-works-banner-wrapper {
    -webkit-transform: translate(-50%, -55%);
    transform: translate(-50%, -55%);
  }
  .how-work-banner img {
    height: 380px;
    border-radius: 6px;
  }
  .how-works-wrapper section {
    margin-bottom: 45px;
  }
  .how-works-wrapper .list-style li {
    padding-left: 25px;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 28px;
  }
  .iframe-category-wrapper .subcategory {
    width: calc(50% - 50px);
  }
  .notification-item i {
    font-size: 20px;
  }
  .logo,
  .header-top-action-wrapper,
  .header-top-user,
  .header-top-action-wrapper-no-logged,
  .eu-funds-img {
    height: 40px;
  }
  .header-wrapper:before {
    top: 56px;
  }
  .header-overlay-top {
    font-size: 14px;
    padding: 10px;
  }
  .header-overlay-item {
    padding: 10px;
  }
  .header-overlay-bigtxt {
    font-size: 14px;
  }
  .header-overlay-img img {
    width: 50px;
    height: 50px;
  }
  .header-overlay-item-body {
    margin-left: 10px;
    width: calc(100% - 60px);
  }
  .header-overlay-wrapper {
    width: 100vw !important;
    max-width: 100vw !important;
  }
  .header-overlay-item-body .cart-item-details-range-wrapper {
    margin-bottom: 0;
  }
  .cart-item-details-range button i {
    font-size: 18px;
  }
  .cart-item-details-range button:first-child,
  .cart-item-details-range button:last-child {
    margin: 0 5px;
  }
  .header-overlay-total-cart-price {
    font-size: 16px;
    line-height: 16px;
  }
  .header-overlay-bottom {
    padding: 10px;
  }
  .header-overlay-checkout-cart {
    padding: 7px 14px;
    margin-top: 15px;
  }
  .notification-empty {
    padding: 10px;
  }
  .breadcrumbs-separator {
    padding-right: 5px;
  }
  .breadcrumbs-item {
    padding-right: 5px;
  }

  .custom-tab-wrapper {
    padding: 15px;
  }
  .custom-tab-wrapper .mat-tab-label {
    min-width: 120px !important;
    height: auto !important;
    padding: 0 10px 10px !important;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    height: 3px;
  }
  .custom-tab-wrapper .mat-tab-label-content {
    font-size: 15px;
    font-weight: 600;
  }
  .tab-details,
  .tab-review-overall,
  .tab-card-list-wrapper {
    padding: 20px 0;
  }
  .no-padding {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .stars-bar-middle {
    width: 70%;
    margin: 3px 5px 7px 5px;
  }
  .custom-tab-wrapper .star {
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }
  .review-box-wrapper {
    padding: 20px 0;
  }
  .review-box-left {
    width: 100%;
    float: none;
    margin-bottom: 15px;
  }
  .review-box-left img {
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .review-box-right {
    width: 100%;
    float: none;
  }
  .custom-tab-wrapper app-rating {
    margin-bottom: 10px;
  }
  .review-body {
    margin-bottom: 10px;
  }
  .mobile .container-fix {
    overflow-x: hidden;
  }
  .header-top {
    padding: 8px 0;
  }
  .eu-funds-img-header-xs {
    padding: 8px 0;
  }
  .auth-left-inner .eu-funds-img img {
    margin: 0 15px 0 0;
  }
  .eu-funds-img img {
    width: 45px;
    margin: 0 10px;
  }
  .carousel .content .custom-item .img:after,
  .carousel .content .custom-item .img,
  .top-banner-right {
    border-radius: 6px;
  }
  .how-works-wrapper {
    margin-top: 0;
  }
  .how-work-banner:after {
    border-radius: 6px;
  }
  .how-works-banner-tab ul .banner-tab-active:before {
    bottom: -15px;
  }
  .how-works-banner-tab {
    bottom: 14px;
  }
  .how-works-wrapper .list-style li:last-child {
    margin-bottom: 0;
  }
  .how-work-img {
    margin: 15px 0;
  }
  .multi-img-wrapper {
    display: none;
  }
  .auth-left-wrapper {
    width: 100%;
    float: none;
    height: auto;
  }
  .auth-right-wrapper {
    background: none !important;
    position: relative !important;
    width: 100%;
  }
  .auth-right-inner {
    position: relative !important;
    background: #fff;
    transform: none;
    left: 0;
    top: 0;
    width: 100%;
    padding: 25px;
    border-top: 1px solid #f6f6f6;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.03);
  }
  .auth-right-title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
  }
  .auth-right-line {
    width: 45px;
    height: 3px;
    background: #f96332;
    border-radius: 1px;
    margin: 10px 0;
  }
  .auth-right-desc {
    font-size: 14px;
    color: #333;
  }
  .auth-right-inner ul li {
    margin-bottom: 15px;
  }
  /* .auth-left-inner .logo {
  width: 180px;
} */
  .auth-form-wrapper {
    padding: 0 25px;
    margin: 20px 0;
  }
  .auth-form-title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .login-footer {
    margin: 0 25px 10px 25px !important;
  }
  .auth-left-inner .logo {
    margin: 50px 25px 0 25px;
  }
  .form-control {
    height: 45px;
    line-height: 45px;
  }
  .open-auth-header {
    float: right;
    height: 40px;
    line-height: 40px;
  }
  .open-auth-header i:before {
    font-size: 20px;
  }
  .auth-header-xs-wrapper.open-filter .header-top-action-wrapper-no-logged {
    height: 100%;
    float: none;
  }
  .auth-header-xs-wrapper.open-filter .header-top-action-no-logged {
    transform: none;
    top: 0;
  }
  .auth-header-xs-wrapper.open-filter .header-top-action-no-logged a {
    display: block;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .select-coutry-banner {
    padding: 15px;
    font-size: 14px;
  }
  .alert-wrapper {
    width: 90%;
  }
  // .mat-dialog-container {
  //   padding: 15px !important;
  // }
  .custom-modal-wrapper .modal-title-custom {
    font-size: 20px;
    line-height: 20px;
  }
  .custom-modal-wrapper .modal-body-custom {
    margin: 20px 0;
  }
  .cart-item-details-title {
    font-size: 16px;
    line-height: 16px;
    min-height: auto;
    margin-bottom: 5px;
  }
  .cart-item-details-left {
    margin-left: 10px;
    width: calc(100% - 225px);
  }
  .cart-left-side {
    width: 100%;
    float: none;
  }
  .cart-item-title {
    font-size: 16px;
    padding: 15px;
    line-height: 16px;
  }
  .cart-item-body p {
    font-size: 14px;
  }
  .cart-item-body {
    padding: 15px;
  }
  .cart-item-footer {
    padding: 15px;
  }
  .cart-item-footer p span:last-child {
    font-size: 18px;
  }
  .cart-item-footer p {
    line-height: 18px;
  }
  .cart-right-side {
    width: 100%;
    float: none;
    margin: 15px 0;
  }
  .cart-steps-header {
    padding: 15px;
  }
  .cart-steps-header ul li a {
    margin-right: 30px;
  }
  .cart-steps-header ul li a.active:before {
    height: 3px;
    bottom: -14px;
  }
  .cart-item-details.ng-star-inserted {
    padding: 15px;
  }
  .cart-item-details-foote-bottom {
    padding: 15px;
  }
  .cart-item-details-foote-bottom span:first-child {
    font-size: 20px;
    line-height: 37px;
  }
  .footer-bottom-txt {
    font-size: 16px !important;
    line-height: 37px !important;
    margin-right: 10px;
  }
  .cart-step-box-wrapper {
    padding: 15px;
  }
  .cart-box-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cart-box-title-action {
    font-size: 14px;
    margin-top: 5px;
    float: left;
    display: block;
    width: 100%;
  }
  .cart-box-address-add {
    margin: 15px 0;
  }
  .mat-radio-inner-circle {
    height: 15px !important;
    width: 15px !important;
  }
  .mat-radio-outer-circle {
    height: 15px !important;
    width: 15px !important;
  }
  .mat-radio-container {
    width: 15px !important;
    height: 15px !important;
  }
  .cart-box-address {
    padding: 15px 0;
  }
  app-cart-billing .cart-step-box-wrapper:first-child {
    padding: 0;
  }
  .cart-step-box {
    padding: 15px;
    margin-bottom: 0;
    border: 1px solid #eaeaea;
  }
  .cart-box-right-total {
    font-size: 20px;
    line-height: 20px;
    float: left;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  .cart-box-item-wrapper {
    padding: 0;
    border-bottom: 1px solid #eaeaea;
  }
  .cart-box-item-title {
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0;
  }
  .cart-box-item-qty {
    float: right;
    line-height: 35px;
    margin-bottom: 0;
  }
  .cart-box-item-mobile {
    float: none;
  }
  .cart-steps-price {
    float: left;
  }
  .cart-step-price-new {
    font-size: 16px;
    font-weight: 700;
    height: 35px;
    line-height: 35px;
    margin-right: 15px;
  }
  .content-top-wrapper {
    margin-bottom: 10px;
  }
  .card-wrapper,
  .fake-card-wrapper {
    width: 50%;
  }

  .card-title {
    font-size: 16px;
    line-height: 16px;
    height: 32px;
    overflow: hidden;
  }

  .tab-card-list-wrapper .card-wrapper {
    width: 50%;
  }
  .notification-item {
    padding: 0 15px;
  }
  .notification-count {
    position: absolute;
    top: -5px;
    right: 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }
  .small-fixed-header-portal .notification-count,
  .small-fixed-header-market .notification-count {
    width: 18px;
    height: 18px;
    -ms-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -webkit-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
  }
  .content-left-side .card-wrapper .card-title {
    height: auto;
  }
  .center-helper-mobile {
    text-align: center;
  }
  .center-helper-mobile .btn {
    float: none;
  }
  .ve-al-txt .btn {
    width: 45%;
  }
  .how-works-banner-wrapper .btn {
    width: 45%;
  }
  .category-tree-row {
    margin: 0;
  }
  .btn {
    padding: 8px 16px;
  }
  .w-10,
  .w-20,
  .w-30,
  .w-40,
  .w-60,
  .w-70,
  .w-80,
  .w-90,
  .w-100,
  .w-33,
  .w-25,
  .w-m-50 {
    width: 100% !important;
  }
  .mobile-header-middle-wrapper .w-30 {
    width: 30% !important;
  }
  .mobile-header-middle-wrapper .w-40 {
    width: 40% !important;
  }
  .header-breadcrumbs {
    display: none;
  }
  .dashboard-wrapper {
    padding: 0;
  }
  #header-wrapper-portal {
    width: 100%;
  }
  .header-wrapper-no-bg .header-top {
    padding: 5px 15px;
  }
  .mobile #header-wrapper-portal {
    background: #fff;
  }
  .mobile-header-middle-wrapper {
    position: fixed;
    background: #fff;
    top: 51px;
    width: 100%;
    padding: 7px 0;
    z-index: 99;
    border-bottom: 1px solid #efefef;
  }
  .mobile-menu-btn {
    background: #f0f0f0;
    color: #708295;
    padding: 8px 10px;
  }
  .mobile-header-middle-wrapper .header-top-lang {
    top: 0;
    transform: none;
    margin-left: 0;
    float: right;
  }
  .mobile-middle-header-btn {
    padding: 8px 5px;
  }
  .mobile-header-middle-wrapper .select-language-wrapper {
    height: 35px;
  }
  .mobile-navigation-wrapper {
    position: fixed;
    background: #fff;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 9999;
    overflow-x: auto;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .close-mobile-menu-btn {
    float: right;
    font-size: 22px;
    padding: 5px 10px;
  }
  .mobile-navigation-items {
    clear: both;
    position: relative;
  }
  .mobile-navigation-items ul {
    padding: 0;
    margin: 0;
  }
  .mobile-navigation-items ul li {
    border-bottom: 1px solid #f0f0f0;
    min-height: 50px;
    padding: 15px 0 15px 15px;
    height: auto;
    line-height: normal;
  }
  .mobile-navigation-items ul li {
    border-bottom: 1px solid #f0f0f0;
    min-height: 50px;
    padding: 15px;
    height: auto;
    line-height: normal;
    font-size: 15px;
    font-weight: 500;
    position: relative;
  }
  .mobile-navigation-items ul li a i {
    margin-right: 7px;
  }
  .mobile-navigation-items .has-sub-nav a:after {
    content: "\F105";
    font-family: icons;
    position: absolute;
    right: 15px;
    top: 50%;
    font-weight: 700;
    font-size: 16px;
    -moz-transition: all 0.15s ease-in-out;
    -webkit-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    transform: translate(0, -50%);
  }
  .mobile-navigation-items .sub-is-open a:after {
    content: "\F107";
  }
  .mobile-navigation-wrapper.open-mobile-nav {
    margin-left: 0;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .mobile-navigation-wrapper {
    margin-left: -100%;
  }
  .dashboard-body {
    padding: 30px 15px;
    word-break: break-all;
    margin-top: 100px;
  }
  .big-title {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: unset;
  }

  /* responsive table */
  .resposive-table-wrapper {
    font-size: 0;
    border-radius: 4px;
    margin-bottom: 10px;
    background: #fff;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .mobile .table-wrapper {
    background: transparent;
    box-shadow: none;
  }
  .responsive-table-row:nth-child(even) {
    background: #f4f5f6;
  }
  .responsive-table-row {
    clear: both;
    display: inline-block;
    width: 100%;
    font-size: 13px;
  }
  .responsive-table-check {
    width: 40%;
    float: left;
    clear: both;
    padding: 10px 5px;
    height: 55px;
    line-height: 35px;
  }
  .responsive-table-action {
    display: inline-block;
    width: 100%;
    clear: both;
    padding: 10px 5px 0;
    border-top: 1px solid #efefef;
  }
  .responsive-table-header-item {
    width: 40%;
    float: left;
    padding: 10px 5px;
    word-break: break-word;
  }
  .responsive-table-data-item {
    width: 60%;
    float: left;
    padding: 10px 5px;
    word-break: break-word;
  }
  .responsive-table-action span {
    float: left;
    display: block;
    font-size: 13px;
    margin-right: 10px;
    background: #fff;
    color: #333 !important;
    border-radius: 4px;
    padding: 6px 8px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
    font-weight: 500;
  }
  .resposive-table-all-check {
    padding: 10px 5px;
  }
  .resposive-table-all-check .mat-checkbox-inner-container {
    margin-right: 7px !important;
  }
  .bulk-action-wrapper {
    padding: 5px 15px 5px;
    margin-bottom: 0;
    border-bottom: 1px solid #efefef;
    border-radius: 0;
  }
  .bulk-action-btn {
    margin-top: 5px;
  }
  .bulk-action-btn button {
    margin-bottom: 5px;
  }
  /* end responsive table */
  .dashboard-body-top {
    min-height: auto;
    height: auto;
  }
  .advanced-search-wrapper {
    float: none;
    margin-bottom: 15px;
  }
  .advanced-search-input input {
    width: 100%;
    padding: 5px 10px 5px 35px;
    height: 35px;
    line-height: 35px;
  }
  .icon-inside-input i {
    top: 7px;
    font-size: 16px;
  }
  .advance-search-row-blog .icon-inside-input .icon-search-blog {
    height: 35px;
    line-height: 35px;
  }
  .total-items-fiter {
    float: left;
    margin: 10px 0;
  }
  .icon-inside-input {
    width: 100%;
  }
  .advance-search-row {
    display: block;
    margin: 0;
    min-width: 100%;
    width: 100%;
    padding: 10px;
  }
  .select-group-block label {
    margin-bottom: 5px;
  }

  .elem-page-wrapper {
    margin-left: 10px;
    width: 145px;
  }
  .clear-btn {
    width: 126px;
  }
  .responsive-table-details {
    float: left;
    width: 60%;
    text-align: right;
    padding: 10px 5px;
  }
  .responsive-table-data-item span {
    padding: 1px 3px !important;
  }
  .paginator ul.ngx-pagination {
    padding: 0;
  }
  .resposive-table-rating span {
    padding: 0;
  }

  .small-box-header-img {
    padding: 10px 10px 0;
  }
  .small-box-details {
    padding: 15px;
  }
  .small-box-list ul li {
    padding: 15px;
  }

  .medium-title {
    font-size: 16px;
    line-height: 16px;
  }
  .white-box-wrapper {
    padding: 15px;
  }
  .white-box-header {
    padding: 0;
  }
  .white-box-header .medium-title,
  .white-box-header .white-box-header-action {
    margin-bottom: 15px;
  }
  .white-box-body {
    padding: 15px 0;
  }
  // .mat-dialog-container .custom-modal-wrapper {
  //   padding: 0;
  //   overflow-x: hidden;
  // }
  .portal-details-wrapper .mat-tab-link {
    margin-left: 10px;
    padding: 10px 5px;
  }
  .order-details-box-left {
    float: none;
  }
  .order-details-box-left img {
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .order-details-box-top .fl-l,
  .order-details-box-top .fl-r {
    float: none !important;
  }
  .order-details-box-left-header {
    margin-bottom: 10px;
  }
  .order-details-box-right-wrapper {
    float: none;
    margin-top: 5px;
    clear: both;
  }
  .order-details-box-right {
    text-align: left;
  }
  .nsm-dialog.nsm-dialog-open {
    padding: 15px;
  }
  .update-qty {
    float: none;
    display: block;
    clear: both;
    line-height: 13px !important;
    margin: 15px 0;
  }
  .modal-footer {
    float: none;
    display: block;
  }
  .modal-footer button {
    min-width: 130px;
    margin: 0 0 10px 0 !important;
    width: 100%;
    float: none;
  }
  .modal-title {
    font-size: 22px;
  }
  .resposive-user-chat {
    display: block;
  }
  .chat-page-right {
    float: none;
    width: 100%;
    position: relative;
    overflow: auto;
    height: 100%;
    right: 0;
  }
  .msg-input-wrapper {
    padding: 10px 15px;
    position: fixed;
    bottom: 62px;
    width: 100%;
    left: 0;
    width: 100%;
    box-shadow: 1px 0px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .chat-body {
    height: auto;
    max-height: 392px;
    border-bottom: 60px solid transparent;
  }
  .custom-modal-wrapper .modal-footer-custom {
    float: none;
  }
  .custom-modal-wrapper .modal-footer-custom button {
    min-width: 130px;
    margin: 0 0 10px 0 !important;
    width: 100%;
    float: none;
  }
  .mat-tab-links {
    display: block !important;
    text-align: right;
  }
  /* mat-ink-bar.mat-ink-bar {
  display: none;
} */
  #bulk-action-fixed {
    position: fixed;
    width: 100%;
    top: 100px;
    left: 0;
    z-index: 97;
  }
  .details-navigation .mat-tab-link {
    margin-left: 10px;
    padding: 5px 0;
  }
  .custom-mat-menu-wrapper {
    right: 0 !important;
    position: absolute;
  }
  .mobile .search-suggestions-wrapper {
    position: fixed;
    top: 155px;
    left: 15px;
    width: calc(100% - 30px);
  }
  .mobile .advance-search-row-blog .clear-search {
    right: 55px;
    top: 50%;
  }
  .mobile .clear-search {
    right: 2px;
    top: 18px;
  }
  .form-login {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
  .dashboard-body-top .btn {
    margin-bottom: 10px;
    float: right;
    margin-top: 25px;
  }
  .dashboard-body-top {
    margin-bottom: 5px;
  }
  .erc-wrapper {
    margin-top: 0;
  }
  .erc-banner-title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .banner-section-erc-provider {
    height: 340px;
  }
  .banner-section-erc-client {
    height: 340px;
  }
  .erc-wrapper section {
    margin-bottom: 40px;
  }
  .erc-inner-img {
    margin-bottom: 25px;
  }
  .erc-wrapper .list-style li {
    position: relative;
    padding-left: 32px;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
  }
  .erc-wrapper .list-style li {
    padding-left: 24px;
    margin-bottom: 15px;
    line-height: 24px;
  }
  .erc-wrapper .list-style li:after {
    width: 10px;
    height: 10px;
    top: 6px;
  }
  .erc-banner-description-absolute-left .img-action-bottom-text-mobile {
    position: unset;
    transform: unset;
    padding: 0;
  }
  .erc-banner-description-absolute-left .img-action-bottom-content-mobile {
    position: unset;
    transform: unset;
    padding: 0;
  }
  .erc-banner-description-absolute-left {
    width: 100%;
    text-align: center;
  }
  .erc-cta-banner {
    height: 270px;
  }
  .erc-cta-banner-content {
    height: 270px;
  }
  .ve-al-txt {
    margin-bottom: 25px;
    transform: unset;
    position: unset;
  }
  .erc-wrapper form label {
    font-size: 14px;
  }
  .sts-title-m-b {
    margin-bottom: 5px;
  }
  .tab-l-t-r .tab-item {
    margin-right: 5px;
  }
  .dashboard-statistics-box-body {
    padding: 0 15px;
    font-size: 0;
  }
  .dashboard-statistics-box-header {
    position: relative;
  }
  .dashboard-statistics-box,
  .dashboard-statistics-box-body,
  .statistics-table,
  .sts-box-h-50 {
    height: auto;
    overflow: unset;
  }
  .sts-section,
  .sts-content-row-m-b,
  .sts-w-m-m-b {
    margin-bottom: 0;
  }
  .dashboard-statistics-box {
    margin-bottom: 30px !important;
  }
  .eu-funds-logo img {
    margin-bottom: 15px;
  }
  section.eu-funds-logo .w-25 {
    width: 50% !important;
  }
}

@media (max-width: 600px) {
  .how-works-banner-wrapper .btn {
    width: 100%;
  }
  .ve-al-txt .btn {
    width: 100%;
  }
  .banner-title {
    font-size: 18px;
  }
  .banner-description-absolute-left .btn {
    margin: 0 0 10px;
  }
  .img-action-bottom-btn .btn {
    margin: 0 0 10px;
  }
  .homepage-wrapper .btn {
    width: 100%;
  }
  .banner-description-absolute-left .btn {
    width: 100%;
  }
  .content-top-filters .select-wrapper {
    width: 100%;
    float: none;
    margin-bottom: 15px;
    margin-left: 0;
  }
  .content-top-filters {
    float: none;
    clear: both;
  }
  .how-works-banner-tab ul li {
    font-size: 13px;
    font-weight: 700;
    margin: 0 7px;
    line-height: 13px;
    white-space: nowrap;
  }
  .iframe-category-wrapper .subcategory {
    width: 100%;
  }
  .cart-item-details-img img {
    width: 100%;
    height: 130px;
  }
  .cart-item-details-img {
    float: none;
    margin-bottom: 15px;
  }
  .cart-item-details-left {
    float: none;
    margin-left: 0;
    width: 100%;
  }
  .cart-item-details-right {
    float: left;
  }
  .cart-items-to-add {
    float: right;
    min-width: 62px;
  }
  .cart-items-to-add ng-select.customiz-select {
    margin-bottom: 0;
  }

  .cart-steps-price div {
    margin-bottom: 0;
  }

  .custom-modal-wrapper .modal-footer-custom {
    float: none;
  }
  .cart-steps-header ul li a {
    font-size: 14px;
    margin-right: 20px;
    line-height: 14px;
  }
  .cart-item-details-foote-bottom p {
    text-align: center;
  }
  .cart-item-details-foote-bottom p span {
    float: none;
    display: block;
  }
  .card-wrapper,
  .fake-card-wrapper {
    width: 100%;
  }
  .card-title {
    font-size: 16px;
    line-height: 16px;
    height: auto;
  }
  .content-left-side .card-wrapper {
    width: 100%;
  }
  .tab-card-list-wrapper .card-wrapper {
    width: 100%;
  }
  .erc-header .logo {
    margin-bottom: 10px;
  }
}
@media (max-width: 400px) {
  .notification-item {
    padding: 0 8px;
  }
  .notification-count {
    right: 5px;
  }
  /*
.eu-logo {
  width: 110px !important;
  margin-left: 10px;
}
.logo img {
  width: 125px;
} */
  .hp-big-title {
    font-size: 18px;
  }
  .erc-banner-title {
    font-size: 26px;
  }
}

/* @media (min-width: 1101px) and (max-width: 1300px) {

} */

/* end media query */

#custom-right-click {
  display: none;
  position: fixed;
  height: 150px;
  width: 150px;
  background: #4a90e2;
  top: 0;
  left: 0;
  z-index: 999;
}

.cdk-global-scrollblock {
  top: initial !important;
}

.tags .tag.overdue {
  background: #f2545b;
}

.not-active-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  font-weight: 600;
  color: #333;
  height: 100px;
  font-size: 18px;
}

.ng-select-container {
  min-height: 40px !important;
  border: 1px solid #d0dae4 !important;
  border-radius: 4px !important;
}

.border-0 {
  .ng-select-container {
    border: none !important;
  }
}

.cc-window {
  border: 1px solid #f96332;

  .cc-compliance {
    display: flex;
    justify-content: center;

    .cc-deny {
      display: none;
    }

    .cc-allow {
      width: 140px;
      flex: none !important;
    }
  }
}

// .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: #d0dae4 !important;
// }

.max-height-150 {
  max-height: 150px;
}

.min-height-250 {
  min-height: 250px;
}

.min-height-500 {
  min-height: 500px;
}

.button-icon {
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px;
  overflow: visible !important;
  align-self: baseline;
}

.report_mat_select {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mat-mdc-form-field-infix {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}

.report_mat_select {
  .mat-mdc-select-placeholder {
    padding: 5px 0;
    display: inline-block;
  }
}

.mat-form-date-range {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
      }
    }
  }
}

.hasNoOutline {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        display: none !important;
      }
    }
  }
}

.hasNoOutline {
  .mdc-evolution-chip-set {
    .mdc-evolution-chip {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

.milestone {
  .mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper,
    .start mat-form-field-appearance-outline {
      .mat-mdc-text-field-wrapper {
        transform: translateY(0%) !important;
      }
    }
  }
}

.info_dropdown {
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15) !important;
  min-width: 312px !important;
}

.text-decoration-underline {
  font-size: 14px !important;
  line-height: 17px;
  font-weight: 600 !important;
}

.border-right {
  border-right: 1px solid rgba(112, 119, 125, 0.1);
}

.show-estimate {
  .mat-mdc-slide-toggle {
    .mdc-switch {
      width: auto !important;
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-switch__track {
      width: 52px !important;
      height: 32px !important;
      border-radius: 100px !important;
      background: rgba(244, 245, 246, 1) !important;
      border: 1px solid rgba(112, 119, 125, 1) !important;
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-switch--checked {
      .mdc-switch__track {
        border: 1px solid #f44336 !important;
      }
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-switch__handle {
      width: 16px !important;
      height: 16px !important;
      background: rgba(112, 119, 125, 1) !important;
      left: 5px;
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-switch__handle svg {
      display: none !important;
    }
  }

  .mdc-switch:enabled {
    .mdc-switch__track::after {
      background-color: #f44336 !important;
    }
  }

  .mdc-switch.mdc-switch--selected:enabled {
    .mdc-switch__handle::after,
    .mdc-switch .mdc-switch__handle::before {
      content: none !important;
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-switch--checked {
      .mdc-switch__handle {
        width: 24px !important;
        height: 24px !important;
        background: #fff !important;
        top: 16px;
        left: -8px;
        border-radius: 50% !important;
      }
    }
  }

  .mat-mdc-slide-toggle {
    ng-reflect-message {
      background: rgba(244, 245, 246, 1) !important;
    }
  }
}

@media only screen and (max-width: 1320px) {
  .portal-details-navigation {
    height: auto;
  }
}

.notification_row {
  border-radius: 4px;

  .notification_icon {
    margin-right: 15px !important;
    width: 22px !important;
  }

  .notification_icon mat-icon {
    overflow: visible !important;
  }

  .notification_text {
    color: #333333;
    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
  }
}

.bg_light_blue {
  background-color: rgba(72, 145, 255, 0.1);
  box-shadow: inset 4px 0 0 0 rgba(72, 145, 255, 0.5);
}

.bg_light_yellow {
  background-color: rgba(250, 176, 17, 0.2);
  box-shadow: inset 4px 0 0 0 rgba(250, 176, 17, 0.5);
}
.bg_light_green {
  background-color: rgba(137, 181, 26, 0.2);
  box-shadow: inset 4px 0 0 0 rgba(137, 181, 26, 0.5);
}
.document-status-label-1 {
  background: #89b51a;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.document-status-label-2 {
  background: #d3d3d3;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.document-status-label-3 {
  background: yellow;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.document-status-label-4 {
  background: #ffb545;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.document-status-label-5 {
  background: #ffb545;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.document-status-label-6 {
  background: #a9a9a9;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.document-status-label-7 {
  background: #d3d3d3;
  color: #fff;
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.secondary-status-active {
  color: #89b51a;
  font-weight: 600;
}
.secondary-status-inactive {
  color: #708295;
  font-weight: 600;
}
.text-right {
  text-align: right !important;
  justify-content: flex-end;

  .mat-sort-header-container,
  .mat-sort-header-content {
    text-align: right;
    justify-content: flex-end;
  }
}

.mat-mdc-menu-item-text {
  @apply text-sm #{!important};
}
